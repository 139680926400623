import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { pageTitle } from '../../helper'
import Accordion from '../Accordion'
import Button from '../Button'
import Cta from '../Cta'
import IconBox from '../IconBox'
import PageHeading from '../PageHeading'
import Div from '../Div'
import SectionHeading from '../SectionHeading'
import TestimonialSlider from '../Slider/TestimonialSlider'
import Spacing from '../Spacing'

export default function ProductService() {
    pageTitle('CORPORATIONS & INSTITUTIONS');
    const params = useParams();
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <PageHeading
                title={'CORPORATIONS & INSTITUTIONS'}
                bgSrc='/images/service_hero_bg.jpeg'
                pageLinkText={"CORPORATIONS & INSTITUTIONS"}
            />
            <Spacing lg='145' md='80' />
            <Div className="container">

            </Div>


            <Div className="container">
                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="  SMALL AND MEDIUM-SIZED ENTERPRISES (SME)"
                            subtitle=""
                        >

                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                We not only offer you customized solutions , but we also provide you with expert advice on all strategic and operational issues relating to your finances to help you make the right decisions.
                            </p>


                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                Our simple and competitive funding is designed to fit around the needs of your business, helping customers to buy or lease business assets. With an expert team and award winning services, we can provide access to the finance solution you need to help your business develop and grow.
                            </p>

                            <Spacing lg="30" md="20" />
                            <h5>ASSET FINANCE</h5>


                            <Spacing lg="30" md="20" />
                            <ul>
                                <li>Invest in assets or technology that futureproof your business.</li>
                                <li>Make regular payments over an agreed period instead of paying cash up-front.</li>
                                <li>There are several asset finance products available to choose from based on your business requirements.</li>
                            </ul>

                            <Spacing lg="30" md="20" />
                            <h5>PROJECT FINANCE</h5>


                            <Spacing lg="30" md="20" />
                            <ul>
                                <li>A flexible approach to accommodate your needs, lending today for tomorrows business model.</li>
                                <li>Supporting all stages of the sustainable project finance lifecycle, from development to operational phases.</li>
                                <li>Work alongside our specialist team who have 30 years' industry experience.</li>
                            </ul>



                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/corporations-institutions1.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />
                    </Div>

                </Div>




                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="BUSINESS FINANCE"
                            subtitle=""
                        >
                            <Spacing lg="30" md="20" />
                            <h5>B2B FINANCE</h5>

                            <Spacing lg="30" md="20" />

                            <ul>
                                <li>Increase your sales by providing alternative payment options for your business customers.</li>
                                <li>Get instant cash for your business as the B2B finance provider pays you directly.</li>
                                <li>Submit proposals using an advanced online application system and receive quick decisions.</li>

                            </ul>

                            <Spacing lg="30" md="20" />
                            <h5>FUNDING FOR FRANCHISES</h5>

                            <Spacing lg="30" md="20" />

                            <ul>
                                <li>Grow your franchise network with funding from £5,000.</li>
                                <li>Finance new equipment or upgrade assets for your franchise business.</li>
                                <li>With 40 years' experience, work with our highly experienced team.</li>

                            </ul>

                            <Spacing lg="30" md="20" />
                            <h5>STOCK FINANCE</h5>

                            <Spacing lg="30" md="20" />

                            <ul>
                                <li>Stock financing allows both manufacturers and dealerships to raise working capital against the stock owned by your business without tying up vast sums of Money.</li>
                                <li>Release value from existing stock to improve your cash flow.</li>
                                <li>Enables you to grow your business or start new opportunities.</li>

                            </ul>


                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                            </p>

                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/corporations-institutions6.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />
                    </Div>

                </Div>


                <Spacing lg="25" md="40" />


                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="TRADE & EXPORT  FINANCE"
                            subtitle=""
                        >
                            <Spacing lg="30" md="20" />
                            <h5>DOCUMENTARY CREDITS</h5>

                            <Spacing lg="30" md="20" />
                            <p>
                                Documentary credits usually require the presentation of certain documents, which must be complied with before payment can take place. You must be aware that banks examine the documents only with respect to the documentary credit and do not look at contracts, agreements or the condition of the goods. We have compiled a set of checklists to help you make sure that every document is filled in correctly.

                            </p>



                            <Spacing lg="30" md="20" />
                            <h5>OVERVIEW OF DOCUMENTARY CREDITS</h5>

                            <Spacing lg="30" md="20" />
                            <p>
                                Documentary Credits for Importers <br />

                                For an exporter, a documentary credit from the importer's bank removes the risk that the buyer will not pay.
                                For an importer, a documentary credit requires the importer to comply with the agreed conditions for payment is made.
                                <Spacing lg="30" md="20" />

                                Bank Guarantees <br />

                                Bank guarantee is an effective way of securing performance and payment in an increasingly challenging business environment.
                                <Spacing lg="30" md="20" />

                                Export Finance  <br />

                                As a solution provider to world-wide  exporters, SAKA FINANCE offers you individual financing solutions that optimally support the demands of your business.




                            </p>



                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                            </p>

                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/corporations-institutions3.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />
                    </Div>

                </Div>


                <Spacing lg="25" md="40" />


                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="TRADE & EXPORT  FINANCE"
                            subtitle=""
                        >
                            <Spacing lg="30" md="20" />
                            <h5>EXPORT</h5>

                            <Spacing lg="30" md="20" />
                            <p>
                                Using documentary credits when you export goods or services means that the importer's bank commits itself to paying you when the conditions of the credit have been met. This offers a number of advantages:

                            </p>

                            <Spacing lg="30" md="20" />
                            <ul>
                                <li>Payment guaranteed by the importer's bank prior to shipment</li>
                                <li>Enhanced security of payment at the agreed time</li>
                                <li>Enhanced security that the order received will not be cancelled or changed without your agreement</li>
                                <li>Improved liquidity as payment often can be made shortly after shipment</li>
                                <li>Possibility of extending security to subcontractors through the documentary credit</li>
                                <li>The possibility of financing</li>

                            </ul>

                            <Spacing lg="30" md="20" />

                            <p>It is important to note that the importer's bank will make payment only after you have complied with all its requirements and conditions. You should therefore examine the documentary credit carefully when you receive it.</p>




                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                            </p>

                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/corporations-institutions4.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />
                    </Div>

                </Div>


                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="D/C FOR EXPORT "
                            subtitle=""
                        >
                            <Spacing lg="30" md="20" />
                            <h5>Documentary credit checklist</h5>

                            <Spacing lg="30" md="20" />
                            <ul>
                                <li>Is the text clear and unambiguous?</li>
                                <li>Are the terms and conditions the same as those you agreed on with your customer? </li>
                                <li>Is the documentary credit issued by an acceptable bank? </li>
                                <li>Is the documentary credit subject to the international rules for documentary credits - UCP 600?</li>
                                <li>Can you comply fully with ALL the requirements?</li>
                                <li>Are all the expressions in the documentary credit clear and unambiguous?</li>
                                <li>Does the documentary credit contain contradictory requirements?</li>
                                <li>Can the time limits for shipment and presentation of documents be met?</li>
                                <li>Are the insurance requirements, if any, acceptable?</li>
                                <li>Are the requirements for transport and transport documents acceptable?</li>
                                <li>Is partial delivery or transshipment allowed, if needed?</li>
                                <li>Are your name and address shown exactly as they appear in your documents?</li>
                                <li>Are the buyer's name and address shown correctly?</li>
                                <li>Is the description of goods the same as in all the other documents? </li>

                            </ul>







                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                            </p>

                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/corporations-institutions5.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />
                    </Div>

                </Div>


                <Spacing lg="25" md="40" />


                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="RISK & FINANCING
                            "
                            subtitle=""
                        >
                            <Spacing lg="30" md="20" />
                            <h5>Confirmation</h5>

                            <Spacing lg="30" md="20" />
                            <p>
                                The Bank will endeavor to confirm the documentary credit at the request of the issuing bank. This ensures that you will receive payment when you comply with the requirements of the documentary credit irrespective of the issuing bank's willingness or ability to pay. Nor will you be affected if the country stops payments or other unforeseen problems arise.

                            </p>

                            <Spacing lg="30" md="20" />
                            <h5>Silent confirmation</h5>

                            <Spacing lg="30" md="20" />
                            <p>
                                Even if the issuing bank does not request the Bank to confirm the documentary credit, the Bank can usually offer you a silent confirmation. This puts you in exactly the same position as if the documentary credit had been confirmed. The only difference is that the issuing bank has not requested confirmation of the documentary credit.

                            </p>


                            <Spacing lg="30" md="20" />
                            <h5>Financing</h5>

                            <Spacing lg="30" md="20" />
                            <p>
                                If you have granted your buyer extended credit (deferred payment) but you would like payment immediately, the Bank will endeavor to discount the draft under the documentary credit at a competitive rate.

                            </p>

                            <Spacing lg="30" md="20" />
                            <p>
                                With documentary credits the SAKA FINANCE  is, in most cases, able to help you reduce the risks of international trade in additional ways - regardless of the issuing bank’s ability to pay or political restrictions.
                            </p>








                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                            </p>

                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/corporations-institutions6.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />
                    </Div>

                </Div>


                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="SECURITY "
                            subtitle=""
                        >
                            <Spacing lg="30" md="20" />
                            <h5>Irrevocable payment order </h5>

                            <Spacing lg="30" md="20" />
                            <p>
                                An irrevocable payment order assures third parties that money will not be paid to you before they received the amount due to them. Be aware that payment orders are not subject to statutory regulations on debt. This means that, should you go bankrupt or suspend payments, an amount will be payable only after a court decision or arbitration.

                            </p>


                            <Spacing lg="30" md="20" />
                            <h5>Assignment </h5>

                            <Spacing lg="30" md="20" />
                            <p>
                                An assignment also ensures that third parties will be paid ahead of you. Unlike an irrevocable payment order, an assignment is subject to statutory regulations on debt. This means that, in case of your bankruptcy or suspension of payments, an estate must respect the assignment if it is legally recorded, and the debtor is notified.

                            </p>


                            <Spacing lg="30" md="20" />
                            <h5>Transferable documentary credit </h5>

                            <Spacing lg="30" md="20" />
                            <p>
                                If the documentary credit is issued as a transferable documentary credit, you can have it transferred, completely or in part, to a third party. When you transfer a documentary credit, the following conditions can be changed:
                                the amount and any unit price can be reduced
                                validity, shipment and presentation limits can be shortened
                                any insurance percentage rate can be raised

                            </p>



                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                            </p>

                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/corporations-institutions7.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />
                    </Div>

                </Div>


                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="EXPORT D/C CONDITIONS
                            "
                            subtitle=""
                        >


                            <Spacing lg="30" md="20" />

                            <ul>
                                <li>In our favor</li>
                                <li>With Prime Bank's confirmation</li>
                                <li>For the amount of</li>
                                <li>Available for payment at sight or for payment ... days after shipment</li>
                                <li>Following documents</li>
                                <li>Covering</li>
                                <li>Evidencing shipment from ... to ... </li>
                                <li>Terms of delivery</li>
                                <li>Partial delivery (Allowed/not allowed)</li>
                                <li>Transshipment (Allowed/not allowed)</li>
                                <li>Latest date of shipment</li>
                                <li>The Credit to be valid until</li>
                                <li>The documents to be presented not later than</li>
                                <li>Charges outside country of issuance</li>
                                <li>The documentary credit must include the issuing bank's debit or reimbursement authorization and must state that the credit is subject to Uniform Customs and Practice for Documentary Credits, ICC Publication No. 600.</li>

                            </ul>

                            <Spacing lg="30" md="20" />
                            <p>
                                If you wish to conduct trade using documentary credits, we recommend that you specify clearly in your offer material which documentary credit conditions you can accept and comply with. Here is guide to the key terms to specify.
                            </p>




                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                            </p>

                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/corporations-institutions8.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />
                    </Div>

                </Div>


                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="PROJECT FINANCE"
                            subtitle=""
                        >


                            <Spacing lg="30" md="20" />

                            <p>
                                SAKA FINANCE  combine strong legal skills with deep industry knowledge.  We are regularly engaged in projects in diverse industries, including the following:
                            </p>

                            <Spacing lg="30" md="20" />

                            <ul>
                                <li>Oil and Gas – including exploration, production, storage, refining, gasification and production platforms</li>
                                <li>Petrochemicals</li>
                                <li>Power and Energy – including thermal, nuclear and renewable, as well as power transmission and distribution</li>
                                <li>Infrastructure and Water – including pipelines and water desalination and treatment</li>
                                <li>Telecommunications – including cellular, cable, fixed and mobile</li>
                                <li>Space and Satellite – including direct broadcast, broadband and satellite imaging systems</li>
                                <li>Waste disposal and recycling</li>
                                <li>Mining and Metals</li>
                                <li>Natural resources</li>
                                <li>Pulp and paper</li>
                                <li>Transportation – including airports, rail, roads and shipping</li>

                            </ul>

                            <Spacing lg="30" md="20" />
                            <p>
                                Project finance is a long-term method of financing large infrastructure and industrial projects based on the projected cash flow of the finished project rather than the investors' own finances.
                                Project finance structures usually involve a number of equity investors as well as a syndicate of banks who will provide loans to the project.
                                Schematic contractual structure for a project financing by SAKA FINANCE :
                            </p>




                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                            </p>

                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/corporations-institutions9.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />
                    </Div>

                </Div>

                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="CASH MANAGEMENT"
                            subtitle=""
                        >


                            <Spacing lg="30" md="20" />

                            <p>
                                SAKA FINANCE knows treasuries. Working with some of the very best treasuries in the world has given us a solid knowledge and understanding of advanced treasury solutions. This knowledge we want to share with you.
                            </p>

                            <Spacing lg="30" md="20" />
                            <p>
                                SAKA FINANCE offers full-scale cash management services and tailored solutions to match our clients' complex requirements. Our “One bank, one system” concept, a single technology banking platform, allows you to view and manage financial positions – across international borders, in real time.

                            </p>

                            <Spacing lg="30" md="20" />

                            <p>
                                At SAKA FINANCE  , we work with a client centric approach and engage in long-term strategic partnerships with clients. We use an advisory methodology to run through the different processes of your treasury organization. We work with you to establish the key aspects of your financial value chain, evaluating how they work today and looking at how they will work in the future. As a result, our advisory concept provides you with the implications and return on investments of strategic initiatives. We support you in making the right decisions and enable you to benchmark your financial processes against the best-in-class.
                            </p>

                            <Spacing lg="30" md="20" />

                            <p>
                                You want to optimize your company's cash management in Europa , Asia & America  and know your precise current cash positions at SAKA FINANCE Correspondences  and your third-party banks. A customized cash management and payment transactions solution allows you to do this.
                                SAKA FINANCE offers flexible and transparent total solutions to support your business activities in throughout the world.
                            </p>


                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                            </p>

                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/corporations-institutions10.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />
                    </Div>

                </Div>

                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="LIQUIDITY MANAGEMENT"
                            subtitle=""
                        >


                            <Spacing lg="30" md="20" />

                            <p>
                                SAKA FINANCE  liquidity management systems provide you with the following benefits:
                            </p>

                            <Spacing lg="30" md="20" />

                            <p>
                                Real-time visibility, access and control of group liquidity through our Business Systems
                                Cross country, cross company and cross currency solutions, all in real-time
                                Overlay and pooling structures create a truly automated and centralized set-up, making it easy to optimize and control your liquidity, all in real-time
                                Support of intra-company limits and interest makes it easy to outsource internal risk and interest management
                                Overlay and pooling structures to be built on top of current account structure, all in real-time
                                Optimized interest income and cost reduction
                                Flexible range of products which can be combined and tailored to your needs.

                            </p>

                            <Spacing lg="30" md="20" />
                            <p>
                                SAKA FINANCE offers several flexible solutions to help you make your liquidity management more transparent. Our unique IT platform makes it possible to deliver real-time information to our clients, taking treasury to the next level by maximizing decision-making ability and capital efficiency.
                                Our solutions are accurate and reliable and provide you with increased visibility and control of your funds.
                            </p>


                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                            </p>

                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/corporations-institutions11.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />
                    </Div>

                </Div>


                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="INTEGRATION  INFRASTRUCTURE"
                            subtitle=""
                        >


                            <Spacing lg="30" md="20" />

                            <p>
                                SAKA FINANCE help clients become the best in their industry by leveraging our technology to provide cost efficient and high-quality solutions to solve a problem or realize business opportunities.
                            </p>

                            <Spacing lg="30" md="20" />

                            <p>
                                At SAKA FINANCE , clients shape the future of technology developments, and our single IT platform enables us to be flexible and rapidly respond to our clients’ new identified needs. We fully support the on-going industry move towards standardization.
                                Our integration and infrastructure solutions provide you with the following benefits:


                            </p>

                            <Spacing lg="30" md="20" />
                            <p>
                                A single platform for all online banking activities
                                Multiple integration possibilities with clients’ systems
                                Faster, secure and more efficient transaction processing
                                Communication channels and file format options to fit your specific need and setup.

                            </p>

                            <Spacing lg="30" md="20" />
                            <p>
                                The importance of high-performing electronic systems is well known by our clients. A growing number of corporates exchange data electronically when they order goods and services, confirm orders, send invoices or make payment transfers.
                                Consequently, the requirements towards straight through processing, security, standardization and communication have escalated and become crucial factors for corporates all over the world.

                            </p>


                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                            </p>

                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/corporations-institutions12.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />
                    </Div>

                </Div>

                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="CORPORATE REPORTING"
                            subtitle=""
                        >


                            <Spacing lg="30" md="20" />

                            <p>
                                SAKA FINANCE provides reports in a large variety of formats and channels to help you get transparency of your transactions, volumes and fees.                  								           Our solutions will, among other things, provide you with the below benefits:
                            </p>

                            <Spacing lg="30" md="20" />

                            <p>
                                Fast and easy access to a transparent overview of transactions, volumes, fees and other banking charges
                                Receive reports from the bank or create them yourself
                                Simple to export information to Excel through our CRM
                                Customization - different types and multiple different options for tailoring reports to fit your needs.


                            </p>

                            <Spacing lg="30" md="20" />
                            <p>
                                Offering a strong portfolio of Corporate Reporting services, SAKA FINANCE ensures to strengthen and improve your daily overview of your business.
                                Reports can be tailored to your specific needs and thus provide you with precise information helping you to become better.                                Faster, secure and more efficient transaction processing

                            </p>




                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                            </p>

                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/corporations-institutions13.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />
                    </Div>

                </Div>


                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="MULTINATIONALS"
                            subtitle=""
                        >


                            <Spacing lg="30" md="20" />

                            <p>
                                SAKA FINANCE offers major corporations and multinationals an integrated advisory service and individual, tailor-made product solutions.
                            </p>

                            <Spacing lg="30" md="20" />
                            <h5>SYNDICATED FINANCING</h5>

                            <Spacing lg="30" md="20" />

                            <p>
                                SAKA FINANCE  Capital Markets’ syndication teams, each led by a senior banker, coordinate with our industry experts to deliver rapid decision-making; we understand that our clients need answers to respond to opportunities. When we commit, we execute. provides reports in a large variety of formats and channels to help you get transparency of your transactions, volumes and fees.

                            </p>

                            <Spacing lg="30" md="20" />
                            <p>
                                We offer you personalized solutions – tailored to your needs and the requirements of the banking market. In so doing, we support you in the following areas:

                            </p>

                            <Spacing lg="30" md="20" />
                            <ul>
                                <li>Syndicated Financing</li>
                                <li>Acquisition financing</li>
                                <li>Management and Leveraged Buyouts</li>
                                <li>Structured Trade Finance</li>

                            </ul>




                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                            </p>

                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/corporations-institutions14.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />
                    </Div>

                </Div>

                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="ACQUISITION FINANCING"
                            subtitle=""
                        >


                            <Spacing lg="30" md="20" />

                            <p>
                                SAKA FINANCE's Leveraged and Acquisition Finance team (LAF) develops solutions in all areas of debt financing, including the origination, arrangement, underwriting and syndication of buyouts and institutionally-led corporate acquisitions. 														             LAF provides and coordinates event-driven, large-scale financing solutions across the credit spectrum in the form of:
                            </p>



                            <Spacing lg="30" md="20" />
                            <ul>
                                <li>Corporate acquisition financing</li>
                                <li>Leveraged buyouts</li>
                                <li>Infrastructure financing</li>
                                <li>Emerging market financing</li>
                                <li>Project finance</li>
                                <li>Management buyouts and buy-ins</li>

                            </ul>

                            <Spacing lg="30" md="20" />
                            <p>
                                We offer you personalized solutions – tailored to your needs and the requirements of the banking market. In so doing, we support you in the following areas:
                            </p>


                            <Spacing lg="30" md="20" />
                            <ul>
                                <li>Syndicated Financing</li>
                                <li>Acquisition financing</li>
                                <li>Management and Leveraged Buyouts</li>
                                <li>Structured Trade Finance</li>

                            </ul>


                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/corporations-institutions15.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />
                    </Div>

                </Div>

                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="MANAGEMENT & LEVERAGE BUYOUTS"
                            subtitle=""
                        >


                            <Spacing lg="30" md="20" />

                            <p>
                                SAKA FINANCE a supports management buyouts management buy ins and leveraged buyouts in Europe and Asia  in connection, for example, with succession planning or the sale of a company.
                                SAKA FINANCE  uses  its wealth of experience and proven track record of advising on mergers and acquisitions (M&A) that use leveraged finance and management buy-outs to assist customers in raising funds.
                                Structure of a Typical MBO While the management and/or financial investors bear the entrepreneurial risk in the form of equity capital, SAKA FINANCE may arrange the debt capital required.
                            </p>






                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/corporations-institutions16.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />
                    </Div>

                </Div>


                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="MANAGEMENT & LEVERAGE BUYOUTS"
                            subtitle=""
                        >


                            <Spacing lg="30" md="20" />

                            <p>
                                Structured Trade Finance (STF) is a specialized activity dedicated to the financing of high value commodity flows. STF transactions are structured around the supply chain and commercial terms of customers, usually involving large bilateral strategic relationships.
                            </p>




                            <Spacing lg="30" md="20" />
                            <p>
                                SAKA FINANCE `S STF team focuses primarily on “upstream” financing of cross-border commodity flows and limited recourse trade finance in order to:
                            </p>


                            <Spacing lg="30" md="20" />
                            <ul>
                                <li>enhance the credit rating of the facility beyond that of the borrower</li>
                                <li>mitigate the cross border and country risk</li>
                                <li>produce a transaction where the whole proposition is more resilient than the sum of its parts.</li>

                            </ul>

                            <Spacing lg="30" md="20" />
                            <p>
                                Benefits for the customer include:
                            </p>


                            <Spacing lg="30" md="20" />
                            <ul>
                                <li>securing strategic procurement</li>
                                <li>diversification of funding</li>
                                <li>greater access to finance for clients</li>
                                <li>enhanced management of transport costs and/or delivery timeframes.</li>

                            </ul>


                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/corporations-institutions17.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />
                    </Div>

                </Div>


            </Div>
        </>
    )
}
