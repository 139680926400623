import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { pageTitle } from '../../helper'
import Accordion from '../Accordion'
import Button from '../Button'
import Cta from '../Cta'
import IconBox from '../IconBox'
import PageHeading from '../PageHeading'
import Div from '../Div'
import SectionHeading from '../SectionHeading'
import TestimonialSlider from '../Slider/TestimonialSlider'
import Spacing from '../Spacing'

export default function ProductService() {
    pageTitle('G &G GLOBAL');
    const params = useParams();
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <PageHeading
                title={'G &G GLOBAL'}
                bgSrc='/images/service_hero_bg.jpeg'
                pageLinkText={"G &G GLOBAL"}
            />
            <Spacing lg='145' md='80' />
            <Div className="container">

            </Div>

            <Div className="container">
                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="ABOUT US"
                            subtitle=""
                        >
                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                G & G Global Group is a multinational investment and trading company established by Amb. Abayomi Nurain MUMUNI LORD in 2003 under Nigerian law. It is active in the trade of agriculture and agricultural products, maritime transport, logistics, trading of petroleum and petroleum products, construction, technology, financial transactions and banking, and also holds a license to install communications terminals or other equipment under Nigerian communications law.
                            </p>
                            <Spacing lg="30" md="20" />
                            <h5>AMB. ABAYOMI NURAIN MUMUNI LORD</h5>

                            <p className="cs-m0">
                                Actively involved in local government and political initiatives for numerous years, with a solid understanding of security, political and economic climate, issues and needs impacting Nigeria
                                Results-driven leader, with extensive background in senior-level roles across political/government and corporate sectors
                                Dynamic communicator, persuasive negotiator and results-driven motivator committed to optimizing security of Nigeria and its people; credentialed Mediator and Conciliator
                                Extensive academic background including multiple Masters Degrees, Post-Graduate Studies and Advanced Certificates/Diplomas in areas including Public Administration, Criminal Justice and Conflict Management, Security/Security Management, Intelligence, Counter Terrorism/Anti-Terrorism, among others
                                Numerous awards in recognition of ongoing contributions to the welfare of Nigeria

                            </p>
                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                Solid understanding of security issues ranging from Terrorism/Counter-Terrorism History, Activities and Trends, Socio- Economic Impact of Terrorism, Government Role in Security, Cross-Functional Agencies Supporting Security, Prevention and Risk Reduction Strategies for Securing Infrastructures, and Policing Strategies to Prevent and Respond to Security Issues


                            </p>

                            <Spacing lg="30" md="20" />
                            <h5>Vast credentials supporting Homeland Security</h5>

                            <ul>
                                <li>Master Degree in Security Management</li>
                                <li>Postgraduate Certificate in Intelligence and Terrorism Profiling </li>
                                <li>Diploma in Intelligence & National Security / Post Graduate
                                    Diploma National Security</li>
                                <li>Certified Counter Terrorism Practitioner (CCTP)</li>
                                <li>Certified Anti-Terrorism Specialist-Search Advisor Practitioner (CATS-SAP)</li>
                                <li>Certification in Global Terrorism, Domestic, International & Transnational</li>
                                <li>Academic thesis on Global Terrorism & the Impacts on Humanity</li>
                                <li>Academic Dissertation on Security Management</li>
                            </ul>

                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/new/gandg1.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />
                    </Div>

                </Div>

                <Spacing lg="25" md="40" />


                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="AMB. ABAYOMI NURAIN MUMUNI LORD"
                            subtitle=""
                        >
                            <Spacing lg="30" md="20" />
                            <ul>
                                <li>Chartered Mediator and Conciliator; Current</li>
                                <li>Special Consultant Planning & Research, Federal College of Complementary & Alternative Medicine of Nigeria; 2007-2011</li>
                                <li>Governorship Candidate, Congress for Progress Change (CPC) Lagos State; 2011</li>
                                <li>Senatorial Candidate, All Nigeria People’s Party, Lagos Central Senatorial District; 2007</li>
                                <li>Honorable Special Adviser on Economic & Foreign Matters to the Executive Governor of Taraba State; 1999-2003 and 2003- 2005</li>
                                <li>Senatorial Candidate, All Nigeria People’s Party, Lagos Central Senatorial District; 2003</li>
                                <li>Presidential Aspirant, United Nigeria
                                    People’s Party (UNPP) Presidential
                                    Aspirant; 2003</li>

                            </ul>

                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                I congratulate the President-elect, Asiwaju Bola Ahmed Tinubu on his hard-fought victory. It has been a long, tortuous journey for him. His battle to the presidency could be likened to the memorable 1974 fight between Mohammed Ali and Joe Frazier. Very tough and intriguing.
                            </p>




                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/new/gandg2.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />
                        <Spacing lg="30" md="20" />
                    </Div>

                </Div>

                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="OUR VISION"
                            subtitle=""
                        >
                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                Our vision is to contribute to the sustainable development of Nigeria economy by building market businesses through our focused approach. Professional integrity in all our financial and business matters constitutes one of our core strengths.
                            </p>


                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                We believe in developing strong partnerships with local businesses. Our commitment to our partners is a core component of our success, and we continually focus on nurturing them with trust and disciplined business practices.
                                Our vision is to contribute to the sustainable development of Nigeria and Africa economies by building market-leading businesses through our focused approach, and continuous enhancement of our shareholders’ value.
                            </p>

                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/new/gandg3.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />
                    </Div>

                </Div>

                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="OUR MISSION"
                            subtitle=""
                        >
                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                Guided by relentless focus on our core principles, we will constantly strive to implement the critical initiatives required to achieve our vision as well to maximize the value of the Company and aim for sustainable growth by sharing the Company’s strategies with each company, moving forward together in the same direction.

                            </p>
                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                Our mission is to carry out investment's projects in the domestic and international markets in accordance with the highest global quality standards, guided by the principles of professional ethics. We draw on our strong team experience and the network of our principal investors to generate superior returns for our stakeholders.
                                G&G GLOBAL aims to invest in and build a portfolio of sustainable investments, driven by established goals to build shareholder value, mindful of its social, economic and environmental responsibilities, as it remains to be committed to integrity, honesty and fairness in all dealings with its stakeholders.
                            </p>
                            <Spacing lg="30" md="20" />


                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/new/gandg4.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="30" md="20" />
                    </Div>

                </Div>

                <Spacing lg="25" md="40" />


                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="OUR VALUES"
                            subtitle=""
                        >
                            <Spacing lg="30" md="20" />
                            <h5> Integrity and Transparency.</h5>

                            <p className="cs-m0">
                                We will conduct all business transactions to the highest ethical standards, always living up to commitments.
                            </p>

                            <Spacing lg="30" md="20" />
                            <h5>Humility and Perseverance.</h5>

                            <p className="cs-m0">
                                We will always live up to the core values of humility and perseverance. Success will not lead to arrogance or overconfidence and setbacks will be met with perseverance and determination.
                            </p>

                            <Spacing lg="30" md="20" />
                            <h5>Communication.</h5>

                            <p className="cs-m0">
                                We hold ourselves accountable to our stakeholders and sincerely accept their opinions, reflecting them in our corporate management.
                            </p>

                            <Spacing lg="30" md="20" />
                            <h5>Financial Discipline.</h5>

                            <p className="cs-m0">
                                We will continue to live up to our principles of financial discipline. This will be achieved through a solid commitment to keeping the Group’s entities well capitalized, maintaining a conservative financial leverage strategy and making sure all commitments are met on time.

                            </p>

                            <Spacing lg="30" md="20" />


                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/new/gandg5.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="30" md="20" />
                    </Div>

                </Div>


                <Spacing lg="25" md="40" />


                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="OIL & GAS TRADE"
                            subtitle=""
                        >
                            <Spacing lg="30" md="20" />
                            <h5> OIL AND PETROLEUM PRODUCTS</h5>

                            <p className="cs-m0">
                                Oil and petroleum products such as gasoline, jet fuel, diesel and fuel oil are
                                an essential part of the global energy mix today, used for transportation and by a wide range of industries. They will continue to play an important role in supporting growing global energy demand during the transition currently underway to a low-carbon economy.
                                As one of the world’s largest suppliers of energy, our teams have significant expertise in moving oil and petroleum products to customers around the world - safely, reliably and efficiently.
                                At the heart of global supply, G &G Global connects producers and consumers of SAKA HOLDING commodities - efficiently, reliably and responsibly. Across our global network, we deploy infrastructure, logistics and our deep understanding of markets to supply the resources needed to power and build the world.
                                G & G Global specialises in the complex task of supplying critical resources where and when they are needed. We are present every step of the way, from the point of production to the point of consumption, helping to make global supply chains more efficient, secure and sustainable.
                            </p>



                            <Spacing lg="30" md="20" />


                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/new/gandg6.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="30" md="20" />
                    </Div>

                </Div>

                <Spacing lg="25" md="40" />


                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title=""
                            subtitle=""
                        >
                            <Spacing lg="30" md="20" />
                            <h5> INTERNATIONAL FOREIGN TRADE</h5>

                            <p className="cs-m0">
                                International trade, economic transactions that are made between countries. Among the items commonly traded are consumer goods, such as television sets and clothing; capital goods, such as machinery; and raw materials and food. Other transactions involve services, such as travel services and payments for foreign patents. International trade transactions are faciliated by international financial payments, in which the private banking system and the central banks of the trading nations play important roles.
                                International trade and the accompanying financial transactions are generally conducted for the purpose of providing a nation with commodities it lacks in exchange for those that it produces in abundance; such transactions, functioning with other economic policies, tend to improve a nation’s standard of living. Much of the modern history of international relations concerns efforts to promote freer trade between nations. This article provides a historical overview of the structure of international trade and of the leading institutions that were developed to promote such trade.
                            </p>

                            <Spacing lg="30" md="20" />
                            <h5> ENGINEERING, PROCUREMENT, MANAGEMENT AND LOGISTICS</h5>


                            <p className="cs-m0">
                                G&G Global provides design, construction, procurement, logistics, and management (EPC) in process industries,with also mechanical installation, equipment erection for mechanical piping systems, high purity pipelines, steel structures, site tanks, fire protection systems, and engineering services to customers in a variety of markets in the field of Industrial process plants: desalination, watertreatment & sewage, semi-conductors, power stations, food & dairies, pharmaceutical, refineries, petrochemical, and fire protection systems with efficient piping design services solutions that meet international codes and standards.
                                G&G Global can provide Inspection and regulation services for different project specifications.
                                G6G Global is a professional manufacturer and engineering company for steel structure projects with full capability of design, fabrication and construction.
                                G&G Global Engineering & Projects provides construction, piping design and engineering services to customers in a variety of markets – refinery, petrochemical, water, and industrial projects with efficient piping design services solutions that meet international codes and standards.
                                
                            </p>



                            <Spacing lg="30" md="20" />


                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/new/gandg7.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="30" md="20" />
                    </Div>

                </Div>


                <Spacing lg="25" md="40" />


            </Div>
        </>
    )
}
