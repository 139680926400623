import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { pageTitle } from '../../helper'
import Accordion from '../Accordion'
import Button from '../Button'
import Cta from '../Cta'
import IconBox from '../IconBox'
import PageHeading from '../PageHeading'
import Div from '../Div'
import SectionHeading from '../SectionHeading'
import TestimonialSlider from '../Slider/TestimonialSlider'
import Spacing from '../Spacing'

export default function ProductService() {
    pageTitle('TRUSFINCO')
    const params = useParams();
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <PageHeading
                title={'TRUSFINCO'}
                bgSrc='/images/service_hero_bg.jpeg'
                pageLinkText={"TRUSFINCO"}
            />
            <Spacing lg='145' md='80' />
            <Div className="container">

            </Div>

            <Div className="container">
                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="-As Unique As You Are-"
                            subtitle=""
                        >
                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                This can be seen immediately in the striking TRUSFINCO circle, which  upon closer inspection is not geometrically round: because we attach  great importance to the human, the individual and the distinctive.
                            </p>
                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                The following pages outline the good reasons why your wealth will be in the best possible hands with us. As an independent banking group boasting outstanding financial strength and an advisory service of the very highest quality, we are ideally placed to satisfy your demanding requirements.
                                You want to make sure you are moving safely ahead? If so, we very much look forward to exchanging ideas with you in person.

                            </p>

                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/trusfinco1.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />
                    </Div>

                </Div>

                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title=""
                            subtitle=""
                        >
                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                Today  TRUSFINCO  has a worldwide presence with offices in Stockholm  (head office), Istanbul , Zurich, Florida and Madrid.
                                Our core competencies lie in tailor-made asset management, investment advisory and wealth planning for sophisticated private clients.
                                We are also an established partner for financial intermediaries who particularly appreciate our long-standing experience and modern infrastructure.

                            </p>


                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/trusfinco2.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />
                    </Div>

                </Div>

                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="SERVICE PROFESSIONALISM"
                            subtitle=""
                        >
                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                For all your financial affairs we provide you with your own experienced personal contact person, who in turn can draw on the expertise of carefully selected specialists.
                                Our global presence gives us a wide range of competencies that can be combined to produce customised solutions. In this way we can always take proper account of country-specific operating conditions whenever necessary.

                            </p>

                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                Our innovative TRUSFINCO e-banking allows you to decide when and where you carry out your banking transactions – independently, quickly and securely.
                                You are always in the know: our publications and face-to-face meetings keep you informed as often as you prefer.

                            </p>


                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/trusfinco3.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />
                    </Div>

                </Div>

                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="TOP-NOTCH SOLUTIONS"
                            subtitle=""
                        >
                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                Our open architecture approach means you are guaranteed to receive independent advice from us. Working with our partners around the globe, werecommend the investment method best suited to your needs or come up with innovative solutions.
                                With the innovative financial technology product, we create a valid reason for your international fund transfers and offer bullion banking service for gold.
                                With the Paymaster service, which is a subsidiary of TRUSFINCO, we offer a secure solution partnership where you can use fund storage solutions and ordered payment methods.

                            </p>

                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/trusfinco4.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />
                    </Div>

                </Div>

                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="OUR INVESTMENT EXPERTS"
                            subtitle=""
                        >
                            <Spacing lg="30" md="20" />
                            <h5>  PERSONAL CLIENT ADVISORS </h5>

                            <Spacing lg="30" md="20" />
                            <p>
                                You have a central contact person at your side, reliably supporting you in all your investment decisions. Your client advisor possesses wide-ranging expertise and years of experience and can take advantage of TRUSFINCO’s international network at any time.
                            </p>

                            <Spacing lg="30" md="20" />
                            <h5>   COMPREHENSIVE INVESTMENT CONSULTING </h5>

                            <Spacing lg="30" md="20" />

                            <p>
                                Our investment consultants analyse your portfolio in direct contact with your personal client advisor – in a solution-oriented and responsible manner. We find first class solutions appropriate to your investment strategy. For complex portfolio solutions, we coordinate select specialists within our extensive network. As part of our premium advisory, our investment consultants are continually monitoring your portfolio in its overall context.

                            </p>

                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/trusfinco5.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />
                    </Div>

                </Div>

                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="OUR INVESTMENT EXPERTS"
                            subtitle=""
                        >
                            <Spacing lg="30" md="20" />
                            <h5>  PROACTIVE ACTIVE ADVISORY </h5>

                            <Spacing lg="30" md="20" />
                            <p>
                                Our active advisory specialist team have their fingers on the pulse of the financial markets. As a professional investor, you will appreciate the direct contact with our investment specialists as they proactively provide you with short- and medium-term investment recommendations as well as investment themes with a long-term focus. You will receive tailored solutions, covering all asset classes, on an ongoing basis.

                            </p>

                            <Spacing lg="30" md="20" />
                            <h5>
                                EFFICIENT DIRECT EXECUTION SERVICE
                            </h5>

                            <Spacing lg="30" md="20" />
                            <p>
                                Thanks to our Direct Execution Service, you have direct, professional access to the trading of TRUSFINCO. As a trading-oriented client, you can quickly and easily execute your investment decisions regarding any exchange-traded and over-the counter investment methods. With our expertise and many years of trading experience, we make sure your transactions are executed without a hitch.

                            </p>


                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/trusfinco6.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />
                    </Div>

                </Div>

                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="ASSET MANAGEMENT"
                            subtitle=""
                        >
                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                TRUSFINCO has the capacity to keep in custody and manage assets like : Bonds, Equity, Commodities, Precious Metals, Real State.
                            </p>

                            <Spacing lg="30" md="30" />
                            <h5>
                                INTEREST INCOME

                            </h5>

                            <Spacing lg="30" md="30" />

                            <p>
                                The chief characteristics are regular income and the ready availability of your assets. The investment horizon is short-term. The investment risk is low, and the investment style is defensive.
                            </p>

                            <Spacing lg="25" md="40" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/trusfinco7.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />
                    </Div>

                </Div>

                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="TRADING EXECUTION"
                            subtitle=""
                        >
                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                TRUSFINCO uses BLOOMBERG, refinitive trading desk and tools, clearing and settlement is done in DTCC ,CREST / EUROCLEAR.
                            </p>

                         
                            <Spacing lg="25" md="40" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/trusfinco8.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />
                    </Div>

                </Div>

                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="INVESTMENTS"
                            subtitle=""
                        >
                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                            TRUSFINCO invest in A, AA and AA rated money market and fixed income securities and follows strictly each Investor's guides according to their risk profile.

                            </p>

                        
                            <Spacing lg="25" md="40" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/trusfinco9.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />
                    </Div>

                </Div>

                <Spacing lg="25" md="40" />

               



            </Div>
        </>
    )
}
