import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { pageTitle } from '../../helper'
import Accordion from '../Accordion'
import Button from '../Button'
import Cta from '../Cta'
import IconBox from '../IconBox'
import PageHeading from '../PageHeading'
import Div from '../Div'
import SectionHeading from '../SectionHeading'
import TestimonialSlider from '../Slider/TestimonialSlider'
import Spacing from '../Spacing'

export default function ProductService() {
    pageTitle('Axex');
    const params = useParams();
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <PageHeading
                title={'Axex'}
                bgSrc='/images/service_hero_bg.jpeg'
                pageLinkText={"Axex"}
            />
            <Spacing lg='145' md='80' />
            <Div className="container">

            </Div>

            <Div className="container">
                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="Company Presentation"
                            subtitle=""
                        >
                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                Since 2012, AXEX has committed to our customers and partners. As an industrial chemical supplier, we pride ourselves on having a diverse inventory that meets the needs of customers, from specialty to commodity.
                                Diversity and a passion for getting every detail right allow AXEX to be a leader in the industry. Our customer relationships, quality of products and services, and integrity of our team are important to us. AXEX is dedicated to every customer’s success, and we continually strive to innovate and grow both our people and our product while assisting customers in solving their most challenging problems. Our goal is to deliver products that meet our high standards and exceed your expectations.

                            </p>

                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                AXEX all processes - including all sites - are managed systematically and efficiently with ISO 9001, ISO 14001, OHSAS 45001 and ISO 27001 . The time spent in developing and motivating HSE (Health, Safety, Environment) culture, which is one of the sine qua non of sustainable growth, will continue increasing day by day.

                            </p>

                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/new/axex1.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />
                    </Div>

                </Div>

                <Spacing lg="25" md="40" />


                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title=""
                            subtitle=""
                        >


                            <Spacing lg="30" md="20" />
                            <h5>Vision</h5>

                            <p className="cs-m0">
                                Being the most preferred company in our fields of activity by our customers, business partners and employees thanks to our best practice management systems, distinctive and value-added service philosophy.
                            </p>


                            <Spacing lg="30" md="20" />
                            <h5>Mission</h5>

                            <p className="cs-m0">
                                Our mission is to provide value-added engineering and turnkey project services (EPC) for technologically challenging projects requiring special sector-specific expertise in our fields of activity; such as refinery, petrochemical, oil and gas, energy, pipelines and industry.
                            </p>

                            <Spacing lg="30" md="20" />
                            <h5>HEALTH</h5>

                            <p className="cs-m0">
                                Health is Wealth.
                                We are aware of the importance of health in this pandemic process that the world is going through. The health of our workplaces and employees comes first for us.
                            </p>


                            <Spacing lg="30" md="20" />
                            <h5> ENVIRONMENT</h5>

                            <p className="cs-m0">
                                Only One Earth.
                                We are aware that we are all one and live in the same world, we have responsibilities to our environment and working partners

                            </p>

                            <Spacing lg="30" md="20" />
                            <h5>SAFETY</h5>

                            <p className="cs-m0">
                                Stop! Think! Then act!
                                We attach importance to these three words, we want to add to every area of our life
                            </p>




                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/new/axex2.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />
                        <Spacing lg="30" md="20" />

                    </Div>

                </Div>

                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="QUALITY POLICY"
                            subtitle=""
                        >
                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                Our goal is to exceed the quality expectations of our customers. This commitment starts with top management and extends through the entire organization. It is achieved through innovation, technical excellence and continuous improvement.
                            </p>


                            <Spacing lg="30" md="20" />
                            <h5>QUALITY CONTROL</h5>
                            <p className="cs-m0">
                                We know customer expectations, we evaluate their business like our own, with our expert and competent staff at the highest quality standards. We are aware that success is not a coincidence, but the quality is a way. And we are confident that we are walking this path in the right way with the right people.

                            </p>

                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/new/axex3.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />
                    </Div>

                </Div>

                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="SWOT Analysis for SUCCESS"
                            subtitle=""
                        >
                            <Spacing lg="30" md="20" />
                            <h5>STRENGTHS</h5>
                            <p className="cs-m0">
                                Characteristics of a business which give it advantages over its competitors
                            </p>

                            <Spacing lg="30" md="20" />
                            <h5>OPPORTUNITIES</h5>
                            <p className="cs-m0">
                                Elements in a company’s external environment that allow it to formulate and implement strategies to increase profitability
                            </p>

                            <Spacing lg="30" md="20" />
                            <h5>WEAKNESSES</h5>
                            <p className="cs-m0">
                                Characteristics of a business which make it disadvantageous relative to competitors
                            </p>


                            <Spacing lg="30" md="20" />
                            <h5>THREATS</h5>
                            <p className="cs-m0">
                                Elements in the external environment that could endanger the integrity and profitability of the business
                            </p>

                            <Spacing lg="30" md="20" />


                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/new/axex4.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="30" md="20" />
                    </Div>

                </Div>

                <Spacing lg="25" md="40" />


                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="Management Systems"
                            subtitle=""
                        >
                            <Spacing lg="30" md="20" />
                            <h5> MINING / GOLD</h5>

                            <ul>
                                <li>ISO 9001:2015 Quality Management Systems</li>
                                <li>ISO 45001:2018 Occupational Health and Safety
                                    Management Systems</li>
                                <li>ISO 14001:2015 Environmental Management Systems</li>
                                <li>ISO/IEC 27001:2013 Information Security Management Systems</li>
                            </ul>

                            <Spacing lg="30" md="20" />
                            <h5>Design and Manufacturing</h5>

                            <ul>
                                <li>ASME B31.3 Process Piping</li>
                                <li>ASME B31.1 Power Piping</li>
                                <li>ASME Section VIII Rules for Construction of Pressure Vessels</li>
                                <li>ASME Section V Nondestructive Examination</li>
                                <li>API 650 Welded Tanks for Oil Storage</li>
                                <li>EN ISO 9712 Non-destructive testing - Qualification and certification of NDT personnel</li>
                                <li>EN 13445-1 Unfired Pressure Vessels</li>
                                <li>2014/68/EU Pressure Equipment Directive</li>

                            </ul>

                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                Coming together is a beginning; keeping together is progress; working together is success.
                            </p>



                            <Spacing lg="30" md="20" />


                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/new/axex5.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="30" md="20" />
                    </Div>

                </Div>


                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="OUR SERVICES"
                            subtitle=""
                        >
                            <Spacing lg="30" md="20" />
                            <h5> MINING / GOLD</h5>

                            <ul>
                                <li>Engineering and Design</li>
                                <li>Digitalization</li>
                                <li>Procurement</li>
                                <li>Commissioning</li>
                                <li>Project Management</li>
                                <li>Consultancy</li>
                                <li>Process Piping</li>
                                <li>Industrial Storage Tanks</li>
                                <li>Equipment Erection and Installation</li>
                                <li>E&I Works</li>
                                <li>Civil Works</li>
                                <li>Pressure Vessels</li>

                            </ul>

                            <Spacing lg="30" md="20" />
                            <h5>Engineering & Design</h5>

                            <ul>
                                <li>Process Design Basis</li>
                                <li>Process Flow Diagram (PFD)</li>
                                <li>Piping and Instrumentation Diagram (P&ID)</li>
                                <li>Plant Layout</li>
                                <li>Equipment Layout</li>
                                <li>Plant 3D Model</li>
                                <li>Material Takeoff (MTO)</li>
                                <li>HAZOP Study</li>
                                <li>Functional Safety Level Study</li>
                                <li>Quantitate Risk Assessment</li>
                                <li>Cost Estimation</li>
                                <li>Project Execution Plan</li>
                            </ul>







                            <Spacing lg="30" md="20" />


                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/new/axex6.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="30" md="20" />
                    </Div>

                </Div>

                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title=""
                            subtitle=""
                        >

                            <Spacing lg="30" md="20" />
                            <h5>Engineering & Design</h5>

                            <ul>
                                <li>Plot Plan</li>
                                <li>Equipment Layout</li>
                                <li>Computer Aided Design (CAD)</li>
                                <li>3-D modeling of Equipment and Piping</li>
                                <li>Pipe Stress & Flexibility Analysis</li>
                                <li>Piping Detailed Drawings (Isometrics)</li>
                                <li>Pipe Support Design and Selection</li>
                                <li>Material Takeoff (MTO) for Piping</li>
                                <li>Valve List</li>
                                <li>Equipment Datasheets</li>
                                <li>Equipment RFPs</li>
                                <li>Instrumentation Design Basis</li>
                                <li>Instrument List</li>
                                <li>Instrument Datasheets</li>
                                <li>Instrument RFPs</li>
                                <li>Valve Sizing</li>
                                <li>Automation System Engineering</li>
                                <li>I/O List</li>
                                <li>Hook-up Diagrams</li>
                                <li>Control System Architecture</li>
                                <li>3D Modeling of Cable Trays and Instrument Panels</li>
                                <li>PLC Programming</li>



                            </ul>

                            <Spacing lg="30" md="20" />
                            <ul>
                                <li>Geotechnical Investigation Report</li>
                                <li>Civil, Structural Calculations</li>
                                <li>Foundation & Concrete Structure General Arrangement Drawings (GA’s)</li>
                                <li>Foundation Drawings</li>
                                <li>Underground Services Layout</li>
                                <li>Cable Trench Layouts</li>
                                <li>Final Grading and Paving Drawings</li>
                                <li>Structural Steel Drawings and Details for Pipe racks Structures and Pipe Supports</li>
                                <li>Buildings Architectural Drawings</li>
                                <li>Layout Drawings for All foundations, Concrete and Steel Structures</li>
                                <li>Civil & Structure & Architectural Shop Drawings</li>
                                <li>Civil & Structure & Architectural Specifications</li>
                                <li>Civil & Structure & Architectural Data Sheets</li>
                                <li>Civil & Structure & Architectural Material Take- Off</li>


                            </ul>

                            <Spacing lg="30" md="20" />
                            <h5>BP LNG TERMINAL DESIGN AND ENGINEERING WORKS</h5>

                            <ul>
                                <li>Engineering and Design,</li>
                                <li>Material Supply,</li>
                                <li>Installation and Supervision</li>
                                <li>Commissioning Works</li>

                            </ul>

                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                We provided and design and engineering team for all Duration: 2020-Ongoing
                            </p>



                            <Spacing lg="30" md="20" />


                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/new/axex7.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="30" md="20" />
                    </Div>

                </Div>


                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title=""
                            subtitle=""
                        >


                            <Spacing lg="30" md="20" />
                            <h5>BOTAS LNG SEND OUT CAPACITY INCREASE
                                PROJECT ELECTRICAL & INSTRUMENTATION
                                WORKS</h5>

                            <ul>
                                <li>Material Supply</li>
                                <li>Dismantle of Existing Systems</li>
                                <li>All Installation Works (LV, MV, Metering, Automation, Instrumentation, F&G, etc.)</li>
                                <li>Test and Commissioning</li>
                                <li>As-Built Drawings</li>

                            </ul>

                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                We constructed all electrical and instrumentation systems of new send out capacity unit in a safely manner.
                                Total Man – Hour: 234000
                                Duration: 2019 – Ongoing

                            </p>



                            <Spacing lg="30" md="20" />
                            <h5>TUPRAS IZMIR REFINERY U-7000 CDU REVAMP-2 PROJECT</h5>

                            <ul>
                                <li>Construction of main process piping and necessary pipe racks</li>
                                <li>Civil works , fire water installation</li>
                                <li>Drain water and water discharging system</li>
                                <li>Electrical connection systems</li>
                                <li>Pre-Commissioning works</li>
                                <li>Commissioning works</li>
                                <li>Rotating equipment erection and installation</li>
                                <li>Furnace installation</li>

                            </ul>

                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                We supplied our client all system as turnkey. Total Man – Hour: 704.500
                                Duration: 2018

                            </p>

                            <Spacing lg="30" md="20" />


                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/new/axex8.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="30" md="20" />
                    </Div>

                </Div>


                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title=""
                            subtitle=""
                        >


                            <Spacing lg="30" md="20" />
                            <h5>TUPRAS IZMIT REFINERY RUP TURNAROUND PROJECT MODERNIZATION</h5>

                            <ul>
                                <li>Construction of main process piping system</li>
                                <li>Scaffolding services</li>
                                <li>Drain water and water discharging system</li>
                                <li>Electrical connection systems</li>
                                <li>Pre-Commissioning works</li>
                                <li>Commissioning works</li>
                                <li>Rotating equipment erection and installation</li>
                                <li>Furnace installation</li>

                            </ul>

                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                We supplied our client all system as turnkey. Total Man – Hour: 240.000
                                Duration: 2018-2019

                            </p>



                            <Spacing lg="30" md="20" />
                            <h5>BOTAŞ KUZEY MARMARA UNDERGROUND GAS STORAGE FACILITY (PHASE III) PROJECT SITE ELECTRIFICATION</h5>

                            <ul>
                                <li>Permit Process Management</li>
                                <li>Engineering and Design</li>
                                <li>Material Supply</li>
                                <li>All Installation Works</li>
                                <li>Test and Commissioning</li>
                                <li>As-Built Drawings</li>


                            </ul>

                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                We executed all design, supply and construct all electrical systems of new site in a safely manner.
                                Total Man – Hour: 2100
                                Duration: 2018-2019

                            </p>

                            <Spacing lg="30" md="20" />


                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/new/axex9.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="30" md="20" />
                    </Div>

                </Div>


                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title=""
                            subtitle=""
                        >


                            <Spacing lg="30" md="20" />
                            <h5>ACWA POWER KIRIKKALE INDEPENDENT POWER PLANT (920 MW) EHT SYSTEM PROJECT</h5>

                            <ul>
                                <li>Engineering and Design,</li>
                                <li>Material Supply,</li>
                                <li>Installation and Supervision</li>
                                <li>Commissioning Works</li>


                            </ul>

                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                We provided and constructed all electrical heat tracing system including 32.000 m heat tracing cable and power system in a safely manner.
                                Total Man- Hour: 5000
                                Duration: 2016-2017

                            </p>



                            <Spacing lg="30" md="20" />
                            <h5>TUPRAS IZMIT REFİINERY 3-YEAR ENGINERING AND DESIGN WORKS CONTRACTOR AGREEMENT</h5>

                            <ul>
                                <li>Material Supply</li>
                                <li>Installation</li>
                                <li>Test</li>
                                <li>Commissioning Works</li>



                            </ul>

                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                We supply and install all electrical infrastructure relevant for new investment as a long-term plant service.
                                Total Man-Hour: 350000 Duration: 2018 - Ongoing

                            </p>

                            <Spacing lg="30" md="20" />


                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/new/axex10.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="30" md="20" />
                    </Div>

                </Div>

                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title=""
                            subtitle=""
                        >


                            <Spacing lg="30" md="20" />
                            <h5>TUPRAS IZMIT REFINERY PETROCOKE STORAGE CONVEYOR LINE</h5>

                            <ul>
                                <li>Procurement of all electrical and instrumentation equipment</li>
                                <li>Design and assembly of exproof electrical panel</li>
                                <li>Prepare data sheets</li>
                                <li>Material requisition forms</li>
                                <li>Cable routings and projects</li>
                                <li>Electrical panel single line diagram</li>
                                <li>Cable lists</li>
                                <li>Cable section calculations</li>
                                <li>All relevant technical drawings</li>
                                <li>Other all relevant works</li>


                            </ul>

                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                We supplied our client EIC system as turnkey. Total Man – Hour: 3500
                                Duration: 2018-2019

                            </p>



                            <Spacing lg="30" md="20" />
                            <h5>TUPRAS HORIZON 2020 PROJECT: RECOVERY OF WASTE HEAT SOURCES AT LOW TEMPERATURE WITH AHT SYSTEM</h5>

                            <ul>
                                <li>Engineering</li>
                                <li>Material Supply</li>
                                <li>Test</li>
                                <li>Installation</li>
                                <li>Commissioning Works</li>



                            </ul>

                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                We executed as the turnkey all this time-critical EU Horizon 2020 Project.
                                Total Man-Hour: 6000 Duration: 2018-2019
                            </p>

                            <Spacing lg="30" md="20" />


                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/new/axex11.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="30" md="20" />
                    </Div>

                </Div>

                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title=""
                            subtitle=""
                        >


                            <Spacing lg="30" md="20" />
                            <h5>TUPRAS IZMIR REFINERY U-7000 REVAMP PROJECT EHT SYSTEM</h5>

                            <ul>
                                <li>Development of EHT calculation list</li>
                                <li>Drawing of EHT lines on the isometrics</li>
                                <li>Control of P&IDs</li>
                                <li>Configuration of database for NGC system</li>
                                <li>List of power cables, Cable routings and projects</li>
                                <li>Block diagrams for power distribution, Junction boxes on layout</li>
                                <li>As-built drawings</li>
                                <li>Panel design, Panel FAT</li>
                                <li>Installation, Test and Commissioning</li>



                            </ul>

                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                We supplied our client EHT system as turnkey. Total Man – Hour: 3000
                                Duration: 2016- 2017

                            </p>



                            <Spacing lg="30" md="20" />
                            <h5>SHELL REFINERY VAGON FILLING STATION PROJECT</h5>

                            <ul>
                                <li>Engineering</li>
                                <li>Material Supply</li>
                                <li>Test</li>
                                <li>Installation</li>
                                <li>Commissioning Works</li>

                            </ul>

                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                We supplied our client Electrical system as turnkey. Total Man – Hour: 11000
                                Duration: 2019
                            </p>

                            <Spacing lg="30" md="20" />


                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/new/axex12.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="30" md="20" />
                    </Div>

                </Div>

                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title=""
                            subtitle=""
                        >


                            <Spacing lg="30" md="20" />
                            <h5>PETKIM IZMIR REFINERY
                                E3 PACKAGE ELECTRICAL WORKS</h5>

                            <ul>
                                <li>Panel design, Panel FAT</li>
                                <li>Procurement of all electrical and instrumentation equipment</li>
                                <li>Prepare data sheets</li>
                                <li>Material requisition forms</li>
                                <li>All relevant technical drawings</li>
                                <li>Installation</li>
                                <li>Other all relevant works</li>

                            </ul>

                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                We supplied our client Electrical system as turnkey. Total Man – Hour: 9000
                                Duration: 2021

                            </p>



                            <Spacing lg="30" md="20" />
                            <h5>TUPRAS IZMIT REFINERY PLANT-7 FCC REVAMP DESIGN AND CONSTRUCTION WORKS</h5>

                            <ul>
                                <li>Drawing of EHT lines on the isometrics</li>
                                <li>Configuration of database for NGC system</li>
                                <li>List of power cables, Cable routings and projects</li>
                                <li>Block diagrams for power distribution, Junction boxes on layout</li>
                                <li>As-built drawings</li>
                                <li>Installation</li>
                                <li>Test and Commissioning</li>

                            </ul>

                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                We supplied our client HT system as turnkey. Total Man – Hour: 20000
                                Duration: 2021- Ongoing
                            </p>

                            <Spacing lg="30" md="20" />


                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/new/axex13.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="30" md="20" />
                    </Div>

                </Div>


                <Spacing lg="25" md="40" />


            </Div>
        </>
    )
}
