import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { pageTitle } from '../../helper'
import Accordion from '../Accordion'
import Button from '../Button'
import Cta from '../Cta'
import IconBox from '../IconBox'
import PageHeading from '../PageHeading'
import Div from '../Div'
import SectionHeading from '../SectionHeading'
import TestimonialSlider from '../Slider/TestimonialSlider'
import Spacing from '../Spacing'

export default function ProductService() {
    pageTitle('Digital Country Program ');
    const params = useParams();
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <PageHeading
                title={'Digital Country Program'}
                bgSrc='/images/service_hero_bg.jpeg'
                pageLinkText={"Digital Country Program"}
            />
            <Spacing lg='145' md='80' />
            <Div className="container">

            </Div>


            <Div className="container">
                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="The Framework for The Proposal on The Digital Transformation of Worldwide"
                            subtitle=""
                        >
                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                Forming a project to transfer the Country economy to digital rails. To announce the project of creating the country's digital economy as a Priority National Project (the high welfare of citizens is democracy plus the digitization of the economy of the whole country).
                            </p>
                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                It is proposed to use the idea of ​​building the world's first digital country, as a national idea uniting the nation
                            </p>
                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                Establishment jointly with partner countries of the organizational and technological platform of industrial ecosystems of the Digital Economy as the basis for establishing effective economic ties and for integrating the economies of the countries of the Middle East in practice. That will create attractive conditions for the desire to join the Middle East to new members.
                            </p>



                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/digital-country1.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />
                    </Div>

                </Div>

                <Spacing lg="25" md="40" />


                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="PREMISIS "
                            subtitle=""
                        >
                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                The world has already created technological, organizational and marketing conditions for the transition economies of countries to digital rails. Many countries, associations and corporations have begun to take steps in this direction.

                            </p>
                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                In Russia and the Middle East, after several years of work, the main regulatory documents at the level of managers have been adopted for the possibility of organizing specific work in this area. There is a political decision in all countries of the Middle East to move towards building a digital economy.
                            </p>

                            <Spacing lg="30" md="20" />
                            <p>
                                At the same time, it is still not completely clear how to proceed to Digital Economy. Many documents are declarative in nature and often correspond with each other. This makes it possible to take the leading positions in the world.
                            </p>

                            <Spacing lg="30" md="20" />
                            <p>
                                Program Country needs an all-pervasive national idea to mobilize the entire nation to work on a common task. The work on the project will not only lead to a rise in the well-being of the population at an accelerated pace, but also to realize the accumulated potential of unrealized expectations of the possibility of making the nation more than it is now available.

                            </p>

                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/digital-country2.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />


                    </Div>

                </Div>



                <Spacing lg="25" md="40" />


                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title=""
                            subtitle=""
                        >
                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                The preserved traditional traditions of society, which have been brought up for centuries, allow us to expect the rapid spread of the announced idea of ​​transferring the country to the digital economy first in the world.
                            </p>

                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                The compact size of the country allows to receive non-competitive advantages:
                            </p>


                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                Large Western companies did not open sales offices (due to the small size of the market) but opened design offices (in view of the high intellectual and educational potential of the population). This led to the fact that not the old technology and solutions penetrated the country (they are promoted through sales offices), but prospective ones that are only being designed (participation in the perspective development of the design offices of global technology corporations in the country)
                            </p>

                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                The compact size of the country allows you to quickly respond to calls and dynamically adjust the management of the economy.
                            </p>

                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                High intellectual potential of the country. Above average level and tradition of education.
                            </p>

                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                There are necessary organizational (decisions of the Supreme Council of Heads of State of the Middle East) and financial instruments to start the project immediately.
                            </p>


                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/digital-country3.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />


                    </Div>

                </Div>


                <Spacing lg="25" md="40" />



                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="HOW TO DO IT?"
                            subtitle=""
                        >
                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                Development of a detailed action plan. Creation of plans: organizational, production-technological, marketing, social, resource
                            </p>

                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                Establishment of the State Commission for Digital Transformation of the Country Economy.
                            </p>

                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                Construction of the initial infrastructure of Digital Economy on the basis of Universities and scientific institutions.
                            </p>

                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                The choice for the primary digitization of several industries, which, firstly, are of a systemic nature (it will allow them to sell knowledge in the world in the future); secondly, their choice allows for a quick breakthrough,
                            </p>

                            <ul>
                                <li>Education</li>
                                <li>ICT / electronics (cloud, mobile and cognitive technologies)</li>
                                <li>Media</li>
                                <li>Agriculture</li>
                                <li>Medicine</li>
                                <li>Construction / Housing and communal services (utilities)</li>
                                <li>New automobile industry, aircraft construction (there can be a merged quickly)</li>


                            </ul>





                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/digital-country4.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />


                    </Div>

                </Div>


                <Spacing lg="25" md="40" />



                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="HOW TO DO IT?                            "
                            subtitle=""
                        >
                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                Comprehensive and intensive involvement of young people in the development and implementation of the project.
                            </p>

                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                Broad propaganda of the proposed solutions and wide discussion on a daily basis, so that there is a cross-cutting involvement of people and an understanding of the absence of "sociable"
                            </p>

                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                Approval of the country as a testing ground for the development of digital solutions, methods, tools, methods of building infrastructure and basic industries, digital governance of the state, and further scaling within Euro Asia and worldwide. This will allow to concentrate resources (intellectual, administrative, financial) from region.
                            </p>

                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                This will allow the country to quickly obtain results for implementation in its regions and the country as a whole, taking into account the findings and experience, both positive and negative. The Middle East quickly carry out a real pool of resources based on digital space, making it attractive for joining new members. Participants in the digital space will have the opportunity to raise the welfare of the population at an accelerated pace.
                            </p>


                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/digital-country5.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />


                    </Div>

                </Div>


                <Spacing lg="25" md="40" />



                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="KEY FEATURES OF THE MODEL OF DIGITAL SPACE MANAGEMENT IN THE NEW ECONOMIC CONDITIONS                            "
                            subtitle=""
                        >

                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                The basis of the model infrastructure consists of universities and research institutes, Collective Use Centers, test laboratories.
                            </p>


                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                Distinctive features of the model "Robotic economic management":
                            </p>

                            <Spacing lg="30" md="20" />
                            <p>
                                Transparency and objectivity at the expense of robotic processes and reliable data.

                            </p>

                            <Spacing lg="30" md="20" />
                            <p>
                                Acceleration of all processes.

                            </p>

                            <Spacing lg="30" md="20" />
                            <p>
                                Fair social model (reporting, informability).

                            </p>

                            <Spacing lg="30" md="20" />
                            <p>
                                Effective use of resources on the basis of the formulated principles of building a digital economy.

                            </p>

                            <Spacing lg="30" md="20" />
                            <p>
                                Advanced reference model of effective management of the region (exhibition-center of knowledge transfer).

                            </p>

                            <Spacing lg="30" md="20" />
                            <p>
                                World test laboratory of new technologies and economic models.

                            </p>

                            <Spacing lg="30" md="20" />
                            <p>
                                Competence center for digital economic transformation to ensure high efficiency of the economy in the new environment.
                            </p>

                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/digital-country6.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />


                    </Div>

                </Div>



                <Spacing lg="25" md="40" />



                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="Laws and principles of building Digital economy"
                            subtitle=""
                        >

                            <Spacing lg="30" md="20" />
                            <h5>Technologies</h5>


                            <Spacing lg="30" md="20" />
                            <ul>
                                <li>Cloud technologies</li>
                                <li>Open Source</li>
                                <li>3D print</li>
                                <li>Intellectual Robots</li>
                                <li>Lasers</li>
                                <li>New materials</li>
                                <li>Oblation – laser cutting</li>
                                <li>Big Data</li>
                                <li>"'Internet of Things"</li>
                                <li>Cognitive technologies</li>
                                <li>Distributed Registies</li>

                            </ul>


                            <Spacing lg="30" md="20" />
                            <h5>Princip</h5>

                            <Spacing lg="30" md="20" />
                            <ul>
                                <li>Global access to resources without intermediaries </li>
                                <li>Provision of resources for rent (on demand) </li>
                                <li>Creation of resources using the volunteer model </li>
                                <li>Creation of industry ecosystems by business entities </li>
                                <li>Sale of products and services through global ecosystems </li>
                                <li>"On-the-fly" Design</li>

                            </ul>


                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/digital-country7.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />


                    </Div>

                </Div>

                <Spacing lg="25" md="40" />



                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title=""
                            subtitle=""
                        >

                            <Spacing lg="30" md="20" />
                            <h5>Project's philosophy:</h5>

                            <ul>
                                <li>Formation of uniform approaches, unification and harmonization of processes of standardization (fuel and energy complex).</li>
                                <li>Standardization based on actual industry data, research and testing generated within the digital industry infrastructure.</li>
                                <li>Formation of a single information space with distributed computing power.</li>


                            </ul>


                            <Spacing lg="30" md="20" />
                            <h5>Centers of testing and certification carry out:</h5>


                            <ul>
                                <li>research, testing; </li>
                                <li>instrumental and methodological support;</li>
                                <li>certification and certification activities; </li>
                                <li>creation of experimental ranges </li>
                            </ul>



                            <Spacing lg="30" md="20" />
                            <h5>Standardization center develops:
                            </h5>


                            <ul>
                                <li>standards</li>
                                <li>methodology; </li>
                                <li>regulations;  </li>
                                <li>updating the regulatory framework of the industry; </li>
                                <li>recommendations and standards  </li>

                            </ul>







                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/digital-country8.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />


                    </Div>

                </Div>


                <Spacing lg="25" md="40" />



                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title=""
                            subtitle=""
                        >

                            <Spacing lg="30" md="20" />
                            <h5>Managing council</h5>


                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                formation and adjustment of strategic directions of industry development
                            </p>



                            <Spacing lg="30" md="20" />
                            <h5>Steering Committee</h5>


                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                decomposition of strategic directions to goals and objectives;
                                formalization of requirements to the created documents initiation of adjustment, strategic directions of development;
                                approval of the documents developed by the center
                            </p>


                            <Spacing lg="30" md="20" />
                            <h5>Steering Committee</h5>

                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                formation of research initiatives;
                                analysis of the results of development and development of recommendations for use;
                                evaluation of the effectiveness of the achieved results;
                                preparation of strategic directions for the development of the industry

                            </p>





                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/digital-country9.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />


                    </Div>

                </Div>

                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title=""
                            subtitle=""
                        >

                            <Spacing lg="30" md="20" />
                            <h5>Secretariate</h5>


                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                coordination of the competence center
                            </p>


                            <Spacing lg="30" md="20" />
                            <h5>Working groups in the following areas)</h5>


                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                development of strategies, concepts, scenarios, roadmaps of recommendations;
                                initiation of adjustment of strategic documents;

                            </p>


                            <Spacing lg="30" md="20" />
                            <h5>Project office</h5>


                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                coordination of competence, testing and standardization centers

                            </p>

                            <Spacing lg="30" md="20" />
                            <h5> Экспертное сообщество</h5>

                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                participation in the work of groups on relevant issues

                            </p>






                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/digital-country10.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />


                    </Div>

                </Div>



                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title=""
                            subtitle=""
                        >

                            <Spacing lg="30" md="20" />
                            <h5>The management of the center</h5>



                            <Spacing lg="30" md="20" />
                            <ul>
                                <li> distribution and organization of research, testing;</li>
                                <li> certification;</li>
                                <li> synthesis and preparation of reports about research, testing;</li>
                                <li> approval of documents developed by the center.</li>



                            </ul>

                            <Spacing lg="30" md="20" />
                            <h5>Scientific and technical Council</h5>

                            <Spacing lg="30" md="20" />
                            <ul>
                                <li> formation of research initiatives;</li>
                                <li> analysis of research results and development recommendations for their application; </li>
                                <li> synthesis and preparation of reports about research, testing;</li>
                                <li> evaluation of the effectiveness of the results achieved</li>

                            </ul>


                            <Spacing lg="30" md="20" />
                            <h5>Project office</h5>

                            <Spacing lg="30" md="20" />
                            <ul>
                                <li> coordination of competence, testing and standardization centers</li>
                            </ul>

                            <Spacing lg="30" md="20" />
                            <h5>Centres for collective usee</h5>

                            <Spacing lg="30" md="20" />
                            <ul>
                                <li>  conducting research, testing; certification; construction of experimental ranges</li>

                            </ul>




                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/digital-country11.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />


                    </Div>

                </Div>


                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="Architecture and implementation model of the standardization center"
                            subtitle=""
                        >
                            <Spacing lg="30" md="20" />
                            <h5>The management of the center</h5>

                            <Spacing lg="30" md="20" />
                            <ul>
                                <li> distribution and organization of standarts development, methodologies, regulations, etc.; </li>
                                <li> updating and maintenance of the regulatory framework of the industry; - harmonization of the regulatory framework with international standards; - approval of documents developed by the center</li>


                            </ul>


                            <Spacing lg="30" md="20" />
                            <h5>Project office</h5>

                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                coordination of competence, testing and standardization centers
                            </p>

                            <Spacing lg="30" md="20" />
                            <h5>Regional standardization centres</h5>

                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                development of standards, methods, regulations, recommendations;
                                updating of the regulatory framework of the industry;

                            </p>


                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/digital-country12.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />


                    </Div>

                </Div>


                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="Resource support for industry transformation "
                            subtitle=""
                        >

                            <Spacing lg="30" md="20" />
                            <h5>Finance</h5>

                            <Spacing lg="30" md="20" />
                            <ul>
                                <li>National project; </li>
                                <li>Departmental projects; </li>
                                <li>Business community; </li>
                                <li>Vendors</li>

                            </ul>


                            <Spacing lg="30" md="20" />
                            <h5>Human Resources</h5>

                            <Spacing lg="30" md="20" />
                            <ul>
                                <li>Consortium Digital country;  </li>
                                <li>Targeted training  in specialized universities </li>

                            </ul>


                            <Spacing lg="30" md="20" />
                            <h5>Equipment</h5>

                            <Spacing lg="30" md="20" />
                            <ul>
                                <li>Business community;  </li>
                                <li>National and regional programs</li>

                            </ul>






                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/digital-country13.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />


                    </Div>

                </Div>


                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="  Digital transformation roadmap for each project:  what is the result of each stage"
                            subtitle=""
                        >

                            <Spacing lg="30" md="20" />
                            <h5>1. Basic knowledge: how to work and earn in the digital world</h5>

                            <Spacing lg="30" md="20" />

                            <ul>
                                <li>Presentation of the project </li>
                                <li>Basic course of lectures</li>
                                <li>Basic training "Leader in the digital environment" </li>
                                <li>Strategic session to determine the digital agenda of the region, enterprise, organization</li>

                            </ul>



                            <Spacing lg="30" md="20" />
                            <h5>2. Consulting: "how exactly?"this is a project for a specific customer</h5>

                            <Spacing lg="30" md="20" />

                            <ul>
                                <li>Surveys of business, social and technological environment, public administration systems (report, models, databases)</li>
                                <li>Attraction of financial and other resources to Digital economy projects </li>
                                <li>Formation of the target model </li>
                                <li>Formation of the road map. </li>
                                <li>Related areas: </li>

                            </ul>

                            <Spacing lg="30" md="20" />
                            <h5>3. Phased implementation: transition from the current model to the target</h5>

                            <Spacing lg="30" md="20" />

                            <ul>
                                <li>Plans for a specific area of activity, industry, department, etc.</li>
                                <li>Methodology and training</li>
                                <li>The Formation of industry competencies in the territory of the regional management center</li>
                                <li>Online data collection and Analytics </li>
                                <li>Current layout of the platform in the real environment of activity  </li>
                                <li>Test sales of end services-hypothesis testing and training of sales methodology in the digital environment connection to the current platform                                </li>



                            </ul>




                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/digital-country14.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />


                    </Div>

                </Div>

                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title=""
                            subtitle=""
                        >


                            <Spacing lg="30" md="20" />
                            <h5>4. Pilot Zone</h5>

                            <Spacing lg="30" md="20" />
                            <ul>
                                <li>Operating pilot zone </li>
                                <li>Procedural methodology, regulatory model, regulations</li>
                                <li>Region Management Center: online data collection and Analytics + Robotic decision </li>
                                <li>Making Integration of all elements into the global Digital Economy platform </li>
                                <li>Regular sales system</li>
                                <li>Test ecosystems (industry and business piloting)</li>

                            </ul>


                            <Spacing lg="30" md="20" />
                            <h5>5. Scaling</h5>

                            <Spacing lg="30" md="20" />
                            <ul>
                                <li>Scaling  </li>
                                <li>Participation of enterprises, organizations (which later become operators of industry ecosystems) in the digital transformation of the University and research Institute </li>
                                <li>The definition of operator ecosystem</li>
                                <li>Check-in at hyperpathia Digital country</li>

                            </ul>

                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/digital-country15.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />


                    </Div>

                </Div>

                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="Digital transformation roadmap of the University "
                            subtitle=""
                        >

                            <Spacing lg="30" md="20" />
                            <h5>1. Preparatory stage</h5>

                            <Spacing lg="30" md="20" />
                            <p>
                                Creation of a special structure for digital transformation in the government, subordinated directly to the head of the region.
                                Involvement of 2-3 consultants (certified by ANO "Digital Country") to conduct digital transformation on an ongoing basis. Provide funding for these structures, so that the participants of the transformation of this service was free.
                                Identify 2-3 leaders from local specialists with high personal motivation to develop the project at the regional level. To indicate the possibility of their promotion to the Federal and global level.
                                Identify 2-3 regional commercial digital infrastructure operators (certified " Digital Country»).

                            </p>

                            <Spacing lg="30" md="20" />

                            <h5>2. Conception</h5>

                            <Spacing lg="30" md="20" />

                            <p>
                                Development of the concept of digital transformation of the region according to the presented templates, which should include:
                            </p>

                            <Spacing lg="30" md="20" />
                            <ul>
                                <li>Definition of reference universities as points of social and economic development of the region and / or industry</li>
                                <li>Definition of basic research and design organizations for scientific and methodological cooperation. Organization may not be necessary, but desirable from the region  </li>
                                <li>Definition of industries-locomotives of economy of the region  </li>
                                <li>Definition of benefits for Digital residents of the "Digital Country". Digital Residents have the opportunity to access all the information of the project "Digital Country"  </li>
                                <li>Allocation of territories around Universities for development of digital infrastructure (cloud-digital Technopark) and favorable environment (digital valley) </li>
                                <li>Creation of a regional management center based on the principles of digital economy</li>
                            </ul>




                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/digital-country16.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />


                    </Div>

                </Div>

                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title=" "
                            subtitle=""
                        >

                            <Spacing lg="30" md="20" />
                            <h5>3. Implementation </h5>


                            <Spacing lg="30" md="20" />
                            <p>
                                Participation in the digital transformation of Universities, research institutes and selected industries of other regions and countries, using their own unique experience.
                                Creation of Digital Technopark and its integration into Digital Technopark "Digital Country".
                            </p>

                            <Spacing lg="30" md="20" />
                            <h5>4. Business - pilots</h5>


                            <Spacing lg="30" md="20" />
                            <p>
                                Development of business models, as well as new models of public administration, including the regulatory aspect.
                                Formalization: regulations, legislation.
                            </p>

                            <Spacing lg="30" md="20" />
                            <h5>5. Scaling</h5>


                            <Spacing lg="30" md="20" />
                            <p>
                                Participation in the Digital Economy Forum and other events and initiatives of the Digital Country project. Initiation of their own activities and initiatives.
                                Participation in the creation, maintenance and development of a system of end-to-end online ratings of residents.
                                Participation in the receipt and initiation of subsidies and benefits is a priority for the participants of the digital transformation, who have transferred their activities to the digital online transformation and robotization of the economy.
                            </p>



                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/digital-country17.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />


                    </Div>

                </Div>

                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="REGULATION AND PROMOTING FINTECH FINANCIAL SERVICES "
                            subtitle=""
                        >

                            <Spacing lg="30" md="20" />
                            <h5>DIGITAL BANKING REGULATIONS</h5>

                            <Spacing lg="30" md="20" />
                            <p>
                                Since 2014, digital banking penetration has increased from 150% to 300% across emerging markets in Asia and the Pacific. The percentage of digitally active customers doubled during this period, and some 52% of urban bank customers in emerging Asian countries currently use digital banking services. Across Asia, more than 700 million consumers use digital banking regularly (Mondato 2019). The current coronavirus disease (COVID-19) pandemic has also spurred growth and interest in digital banking.
                            </p>

                            <Spacing lg="30" md="20" />
                            <p>
                                Many regulators and policy makers across Asia are developing regulations to support new forms of digital or virtual banks. These are seen as offering convenience, ease, and speed, especially for the new breed of digital consumers who prefer to bank via their mobile phones. Digital bank customers are able to bank anywhere at any time, needing no physical branches . In addition, since digital banks have lower overhead costs, they can offer higher interest rates on savings accounts and lower fees, and even faster loan processing.

                            </p>

                            <Spacing lg="30" md="20" />
                            <p>
                                Governments play a key role in supporting
                                inclusive fintech ecosystems. Not only do
                                they play a role as a key coordinator, but more
                                importantly government use of digital payments
                                is crucial for building trust and driving transaction volumes. These include not only making payments across the governmental agencies, but also to individuals and businesses as well as receiving payments (Better Than Cash Alliance 2017).
                            </p>

                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/digital-country18.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />


                    </Div>

                </Div>

                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="REGULATION AND PROMOTING FINTECH FINANCIAL SERVICES "
                            subtitle=""
                        >

                            <Spacing lg="30" md="20" />
                            <h5>OPEN BANKNG/FINANCE FRAMEWORKS</h5>

                            <Spacing lg="30" md="20" />
                            <p>
                                By using open banking and APIs, third parties can connect with banks and access customer data through standardized protocols, allowing fintech companies to provide new products and services and to create new business models. In doing so, they are also contributing to the collaborative effort of innovation, to creating new ecosystems, and to smarter data use. Since nonbank financial institutions play key roles, there is also now a trend and need beyond open banking to support open finance frameworks. This new trend broadens the range of financial players that can share data and includes companies such as insurance companies, pensions, as well as large payment providers or e-money issuers, e-commerce providers, and even utility companies.
                            </p>

                            <Spacing lg="30" md="20" />
                            <p>
                                It may be too soon to draw conclusions, however, as many open banking and now open finance regulations only became effective since 2018. While there is no single model or solution to open banking and open finance frameworks, several standards are now developing that can assist policy makers and regulators in developing a roadmap. These new standards can help create a facilitative ecosystem of secure exchange of data between banks and other financial service providers and third parties.

                            </p>

                            <Spacing lg="30" md="20" />
                            <p>
                                A viable pricing model and acceptance fees low
                                enough to incentivize use are needed to achieve
                                scale and thus encourage merchants and other
                                SMEs to use digital payments. This is particularly important for smaller SMEs and informal retailers wishing to accept digital payments, whether face-to-face or online. To foster this, regulators could consider encouraging fintech, especially payment service providers, to cap or limit Merchant transaction fees.
                            </p>

                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/digital-country19.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />


                    </Div>

                </Div>

                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="REGULATION AND PROMOTING FINTECH FINANCIAL SERVICES "
                            subtitle=""
                        >



                            <Spacing lg="30" md="20" />
                            <p>
                                While models may differ on the approach and scope, common standards and principles are now being developed and utilized in several markets. Regulators are increasingly co-opting industry working groups to ensure responsible governing bodies, often jointly overseen by financial regulators, are in place. These early regulatory efforts have concentrated on defining standardized API frameworks, creating governance bodies and rules, enhancing security, developing infrastructure, establishing authentication methods, ensuring liability arrangements and developing data privacy and consumer protection standards.
                            </p>

                            <Spacing lg="30" md="20" />
                            <p>
                                Analyzing current frameworks and adapting to the local context and maturity of a country might be a first approach toward defining an open banking or finance framework. Note that even where the approach to open banking or finance is regulator driven, enhanced collaboration with financial institutions and fintechs is relevant to ensure general acceptance and adoption of standards. Also, moving toward international open finance standards is expected to result in better regional and ultimately international interoperability.

                            </p>

                            <Spacing lg="30" md="20" />
                            <p>
                                Finally, regulators might consider opening up customers’ data access to other sectors beyond the financial sector, moving from open banking toward open economies, that increase data-driven ecosystems and create a level playing field.
                            </p>

                            <Spacing lg="30" md="20" />
                            <p>Among the incentives for higher acceptance
                                levels is disallowing surcharges on transactions
                                that could eventually be passed on to the
                                merchant, or even subsidizing the cost of
                                acceptance in the early stages of development.
                                Additionally, enhancing market transparency
                                through disclosure of exchange fees, rates, and
                                other commissions would result in more efficient
                                markets and encourage adoption of digital
                                payments.
                            </p>

                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/digital-country20.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />


                    </Div>

                </Div>

                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="REGULATION AND PROMOTING FINTECH FINANCIAL SERVICES "
                            subtitle=""
                        >



                            <Spacing lg="30" md="20" />
                            <p>
                                Some countries have also had success in using
                                upper-limit thresholds on single-payment cash
                                transactions by consumers. However, challenges
                                remain even for the e-commerce sector, where a
                                large percentage of transactions are still cash on
                                delivery. Advances and the experiences of digital
                                payments in the PRC demonstrate potential for
                                other countries in Asia.
                            </p>

                            <Spacing lg="30" md="20" />
                            <p>
                                Financial literacy tools, especially those that promote awareness and use of digital financial services, can enhance adoption. Effective tools should provide simple instructions on how digital financial services work, with a clear understanding of the benefits and risks. Some countries are using chatbots.

                            </p>

                            <Spacing lg="30" md="20" />
                            <p>
                                Related to financial literacy, as noted, is the need for an enhanced consumer protection framework that covers the unique aspects of digital financial services and establishes regulations that protect consumer funds that are clear and uniform. Also needed are redress mechanisms and access to information to better support trust and adoption of digital financial services and help promote a fintech ecosystem.
                            </p>

                            <Spacing lg="30" md="20" />
                            <p>
                                Finally, consumer fees should not deter use, but should be affordable, even for the financially underserved. Measures such as discounts, cash rebates, rewards, or loyalty programs could be considered to support this, especially for government-sponsored initiatives.

                            </p>

                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/digital-country21.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />


                    </Div>

                </Div>



                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="REGULATION AND PROMOTING FINTECH FINANCIAL SERVICES "
                            subtitle=""
                        >



                            <Spacing lg="30" md="20" />
                            <h5>
                                FINTECH AND THE SUSTAINABLE DEVELOPMENT GOALS
                            </h5>

                            <Spacing lg="30" md="20" />
                            <p>
                                Financial inclusion is central to the 2030 Sustainable Development Goals (SDGs) and is included in the targets of 8 of the 17 SDGs. SDG 1 calls for eradicating poverty and SDG 2 aims to end hunger, achieve food security, and promote sustainable agriculture. Financial inclusion is also factor in the following SDG goals: SDG 3 on protecting health; SDG 5 on gender equality and economic empowerment of women; SDG 8 on economic growth and jobs; SDG 9 on supporting industry, innovation, and infrastructure; and SDG 10 on reducing inequality. In SDG 17, on strengthening the means of implementation, there is hope that greater financial inclusion can fuel economic growth.

                            </p>

                            <Spacing lg="30" md="20" />
                            <p>
                                Academic evidence suggests that broadening financial inclusion can help fuel economic growth even as it contributes to efforts to reach the SDGs (ADB 2018). A recent report from McKinsey Global Institute, for example, points to digital finance benefiting billions of people in the likely impact it would have on inclusive growth, which would contribute $3.7 trillion to gross domestic product among developing economies in a period of 10 years (McKinsey Global Institute 2016). The potential impact of mobile financial services on poverty is also strong. Kenyan mobile money provider, M-Pesa, by increasing access and use of mobile e-money services, has helped lead many households out of poverty.
                            </p>

                            <Spacing lg="30" md="20" />
                            <p>
                                Financial inclusion is also helping to stabilize financial systems and economies, mobilizing domestic resources through national savings and raising government revenues via better tax collection.

                            </p>

                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/digital-country22.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />


                    </Div>

                </Div>

                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="FOUNDATIONAL INFRASTRUCTURE AND POLICY ENVIRONMENT "
                            subtitle=""
                        >



                            <Spacing lg="30" md="20" />
                            <h5>
                                IDENTIFICATION INFRASTRUCTURE

                            </h5>

                            <Spacing lg="30" md="20" />
                            <p>
                                Access to financial services is severely hampered in many countries by the lack of adequate identification (ID) infrastructure. Adequate access by financial service providers to ID infrastructure is important in supporting financial inclusion and minimizing risks of fraud and encouraging compliance with Anti-Money Laundering and Counter-Terrorism Financing (AML-CTF) regulations. Digital and biometric identification technologies can also help financial service providers make better decisions. Some promising developments in several key markets include integrating payment infrastructure with ID infrastructure to support efficiency gains, and the creation of digital payment histories for credit scoring.

                            </p>

                            <Spacing lg="30" md="20" />
                            <p>
                                Notably, the World Bank and other institutions have been working on digital ID standards that governments and policy makers should consider as they develop national digital ID systems (World Bank 2017a). The World Bank provides guidance to advance the promotion of robust and inclusive digital identification systems, and these principles encourage the facilitation of customer identification for digital financial services (World Bank 2017b). In developing national digital ID systems, governments should help ensure universal coverage and accessibility; remove barriers to access and usage; and ensure that identification databases are robust, secure, responsible (collecting and using only the information necessary for the system’s explicit purpose), and sustainable.
                            </p>

                            <Spacing lg="30" md="20" />
                            <p>
                                The biometric ID allows people to verify personal attributes such as name, gender, and biometrics to access financial services safely and securely. This will aid considerably in establishing know-your-customer information, which is critical to accessing financial services.
                                The tool uses smart cards on standard Android mobile phones.
                                This smart card-based digital access tool works without power and internet, which is important for reaching unbanked people in the remote locations.
                            </p>

                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/digital-country23.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />


                    </Div>

                </Div>


                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="FOUNDATIONAL INFRASTRUCTURE AND POLICY ENVIRONMENT "
                            subtitle=""
                        >

                            <Spacing lg="30" md="20" />
                            <h5>
                                PAYMENT INFRASTRUCTURE

                            </h5>

                            <Spacing lg="30" md="20" />
                            <p>
                                Frequently, firms find their way into digital financial services through digital payments. And digital payment infrastructure is a key initial enabler for advancing the financial inclusion agenda. Inclusive payment infrastructure should result in payment services that can reach any individual or SME. The G20 Policy Guide on Digitalization and informality established some policy guidelines, especially in the context of SMEs operating in the informal economy (Global Partnership for Financial Inclusion and G20 Argentina 2018).

                            </p>

                            <Spacing lg="30" md="20" />
                            <p>
                                Key parts of the digital payment infrastructure include automated clearing houses, payment switches, and large value and retail payment settlement systems. It also includes certain data sharing and information systems such as credit reporting bureaus and collateral registry systems. In addition, financial institution-level infrastructure, such as core banking systems that could take advantage of cloud technologies, can improve access to digital financial services. Outsourcing services can help payment service providers that face challenges participating directly in key payment infrastructure. In addition, joint payment switches used by banks and nonbanks have been established in several countries (e.g., Malaysia, the Philippines, and Thailand). Infrastructure interoperability between different payment service providers and financial institutions can provide solutions in markets where there is no single joint platform.
                            </p>

                            <Spacing lg="30" md="20" />
                            <p>
                                Such standardization will facilitate the interoperability of payment systems, especially cross-border, and allow more effective fintech solutions. This will also offer the benefit of greater standard KYC and anti-money laundering approaches, which can be easily applied to domestic as well as cross-border payments.
                            </p>

                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/digital-country24.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />


                    </Div>

                </Div>



                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="FOUNDATIONAL INFRASTRUCTURE AND POLICY ENVIRONMENT "
                            subtitle=""
                        >


                            <Spacing lg="30" md="20" />
                            <p>
                                Ultimately, all retail payment systems need to settle their balances in the respective large value payment systems that operate in central bank money (e.g., real-time gross settlement).

                            </p>

                            <Spacing lg="30" md="20" />
                            <p>
                                These systems are integral in allowing the connection of retail payment systems (including mobile systems), domestically and across borders. In this regard, international standardization of payments and settlement messages should be looked at closely, including ISO 20022 being established and adapted widely in Asia and most parts of the world.
                            </p>

                            <Spacing lg="30" md="20" />
                            <p>
                                Increasingly, regulators are seeing the potential of interoperable payment systems to support market competition and achieve greater and more efficient economies of scale using existing infrastructure.
                            </p>

                            <Spacing lg="30" md="20" />
                            <p>
                                Distributed ledger technology (DLT) also offers potential applications that can support greater access to trade and supply chain finance, asset registry systems, and other types of document tracking.
                            </p>

                            <Spacing lg="30" md="20" />
                            <p>
                                New developments and standards for interoperable payments around the use of QR codes have helped increase access to payments for customers and SMEs (Riley 2019).
                            </p>

                            <Spacing lg="30" md="20" />
                            <p>
                                QR codes in particular have helped several countries leapfrog challenges associated with traditional POS terminals, as only a printed QR code is required, and customers just need a smartphone.
                            </p>

                            <Spacing lg="30" md="20" />
                            <p>
                                Additionally, systems that enable fast payments—able to transmit payment messages and grant that the availability of funds to the payee occur in real-time or near real-time on a 24/7 basis—may be able to reach substantially higher adoption of digital payments. A fast payments system requires certain activities associated with clearing to occur in real-time or near real-time continuously
                            </p>

                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/digital-country25.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />


                    </Div>

                </Div>


                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="FOUNDATIONAL INFRASTRUCTURE AND POLICY ENVIRONMENT "
                            subtitle=""
                        >


                            <Spacing lg="30" md="20" />
                            <p>
                                According to the BIS Committee on Payment and Settlement Systems, central banks have a variety of roles, responsibilities, and interests in fostering the safety and efficiency of national payment systems, especially rules governing retail payment systems, services, and payment instruments. More recently, many central banks are also considering accessibility and coverage, effective protection of customers, and existence of a competitive environment as important objectives.

                            </p>

                            <Spacing lg="30" md="20" />
                            <p>
                                Central banks can adopt one of several roles in retail payments to meet these goals: (i) operational, (ii) as catalyst, and (iii) as overseer or regulator.
                            </p>

                            <Spacing lg="30" md="20" />
                            <p>
                                In the first role, the central bank generally provides settlement services for one or more retail payment systems. In some countries, it may operate a retail payment system, thus taking a more direct operational role.
                            </p>

                            <Spacing lg="30" md="20" />
                            <p>
                                In order to discuss priorities for improving payment systems or developing new services and to facilitate such projects, meanwhile, central banks typically maintain close relationships with commercial banks and other payment service providers. In some countries, the monetary authority has established, and usually chairs, a national payment council as a forum for multistakeholder consultations.
                            </p>

                            <Spacing lg="30" md="20" />
                            <p>
                                As an overseer of payment systems, the central bank’s role is focused on the efficiency and safety of the payment system. While this varies from country to country, increasingly, governments have been adopting new national payment systems acts and laws that strengthen their legal and regulatory oversight.
                            </p>



                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/digital-country26.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />


                    </Div>

                </Div>


                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="FOUNDATIONAL INFRASTRUCTURE AND POLICY ENVIRONMENT "
                            subtitle=""
                        >
                            <Spacing lg="30" md="20" />
                            <h5>
                                DATA-SHARING INFRASTRUCTURE
                            </h5>

                            <Spacing lg="30" md="20" />
                            <p>
                                As more individuals and SMEs go online, they create digital footprints with vast amounts of data. Access to this is seen as essential to promote fintech products and services. Policy makers and regulators are increasingly seeing the importance of providing the right policy-and regulatory-enabling environment to help empower citizens and support use of fintech services increasingly built on the wealth of that data.

                            </p>

                            <Spacing lg="30" md="20" />
                            <p>
                                While appropriate ID infrastructure is needed to bring the unbanked into the formal financial system, and payment infrastructure can facilitate usage of services, it is data-sharing infrastructure that empowers consumers, businesses, and the broader fintech ecosystem. This key foundational infrastructure requires appropriate data privacy and protection rules to build trust and ensure responsible use of personal and business data.
                            </p>

                            <Spacing lg="30" md="20" />
                            <p>
                                Countries have taken various approaches to data-sharing policies and regulations. This ranges from market-oriented approaches with little or no regulation to tightly controlled data protection laws such as the GDPR in the European Union. GDPR focuses on preventing harm by shifting the burden for privacy and security to service providers.
                            </p>

                            <Spacing lg="30" md="20" />
                            <p>
                                Data policy in some countries, however, focuses on making sure that people can best reap the benefits of their data, as in India (BIS 2019b). India’s approach has taken a different track from the other approaches to data-sharing infrastructure (such as in the PRC or the EU).
                            </p>

                            <Spacing lg="30" md="20" />
                            <p>
                                Rather than allow service providers to make use of data without the consent of customers or under strict data privacy rules that focus on penalties for providers, India’s approach has been to allow consumers to readily access their data and empower them to decide how to share their data to obtain services, including digital financial services such as credit or insurance (Nilekani 2018).                            </p>



                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/digital-country27.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />


                    </Div>

                </Div>


                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="ENSURING RESPONSIBLE FINTECH SERVICES"
                            subtitle=""
                        >
                            <Spacing lg="30" md="20" />
                            <h5>
                                PRUDENTIAL AND MARKET CONDUCT REGULATIONS

                            </h5>

                            <Spacing lg="30" md="20" />
                            <p>
                                Stringent prudential regulations, especially on banks, can ensure a robust and resilient financial system better able to withstand financial instability and systemic risk. To deal with these risks, prudential regulation is targeted at banks well as deposit insurance.

                            </p>

                            <Spacing lg="30" md="20" />
                            <p>
                                Capital requirements. This refers to how much equity a financial regulator requires of a bank or financial institution, typically in the form of a capital adequacy ratio of equity over risk-weighted assets.
                            </p>

                            <Spacing lg="30" md="20" />
                            <p>
                                Reserve requirements. This refers to the percentage of assets required to be held as cash, and highly liquid assets that can be used to meet deposit withdrawals and other obligations.
                            </p>

                            <Spacing lg="30" md="20" />
                            <p>
                                Governance requirements. These are designed to manage financial risks in maturities of deposits and loans, currencies, and other factors relating to the nature of investment.
                            </p>

                            <Spacing lg="30" md="20" />
                            <p>
                                Reporting and disclosure requirements. These requirements are designed to improve internal governance and external supervision.

                            </p>


                            <Spacing lg="30" md="20" />
                            <p>
                                However, regulation needs to vary according to the type of financial service and its risk level. Nonetheless, if services are diverse or if only lower-risk financial services are offered, it may not be necessary to provide full banking regulation.  Greater reliance on decentralized digital solutions amid financial innovation is also helping to increase operations risk. These include risks of cybersecurity, as well as Anti-Money Laundering and Counter-Terrorism Financing (AML-CTF). Fintech could also encourage financial contagion arising out of new types of financial flows across borders. These would include relatively recent advancements in tokenized securities, blockchain bonds, or cross-border crowdfunding.
                            </p>


                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/digital-country28.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />


                    </Div>

                </Div>

                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="ENSURING RESPONSIBLE FINTECH SERVICES"
                            subtitle=""
                        >


                            <Spacing lg="30" md="20" />
                            <p>
                                In alternative nonbank lenders—which have dramatically increased credit to unbanked or underbanked individuals and SMEs, especially when an economy is in an upswing—policy makers and regulators need to be aware that these new lenders and their business models have not faced any stress testing in economic downturns. That is to say, the risks to financial stability during recessions, such as the downturn widely anticipated in the wake of the COVID-19 pandemic, could raise individual and SME delinquency rates.

                            </p>

                            <Spacing lg="30" md="20" />
                            <p>
                                This is particularly true of the fast-evolving fintech market. For example, some basic financial services do not entail significant systemic risk, and only require certain key protections:
                            </p>

                            <Spacing lg="30" md="20" />
                            <p>
                                Conversion of cash to electronic money (cash-in) depends on proper authentication of the cash, identification of the customer, and a reliable bookkeeping system.
                            </p>

                            <Spacing lg="30" md="20" />
                            <p>
                                Storage of money for safe keeping depends primarily on the same things, as well as control over access to the funds, making governance, audits, and KYC procedures key to ensuring the integrity of the system.
                            </p>

                            <Spacing lg="30" md="20" />
                            <p>
                                Transfers and payment services require documentation of the delivery to and transfer by the network, and authentication of the recipient, and so rely on internal messaging and control protocols to protect against fraud and system failure. Prevention of terrorism finance and money laundering may also justify limiting the amounts that may be transferred, requiring certain record keeping and identification of the sender.

                            </p>

                            <Spacing lg="30" md="20" />
                            <p>
                                Although these electronic money services may channel money into banking systems, they often only offer money transfer and payment services, meaning that the credit, market, or liquidity risks of full “banking” services are not an issue. Without such risks, there is no need for the prudential regulations that banks face. Instead, market conduct regulation might be enough to provide consumers the protection they need to engender trust.                             </p>


                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/digital-country29.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />


                    </Div>

                </Div>


                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="ENSURING RESPONSIBLE FINTECH SERVICES"
                            subtitle=""
                        >


                            <Spacing lg="30" md="20" />
                            <p>
                                In providing these protections, however, authorities should be careful not to place onerous restrictions on the providers, on the financial products they offer, or on the services and delivery channels. These issues become more important when low-income or disadvantaged groups are involved, who may be new to formal financial services and thus vulnerable to unfair practices.
                            </p>

                            <Spacing lg="30" md="20" />
                            <p>
                                In addition, regulators and policy makers should adopt principles for responsible innovation and encourage nondiscriminatory access to information to market participants. At the same time, authorities need to stay on top of issues of data fragmentation and the potential for a few large firms to gain monopolistic control over digital data, and thus lock clients into a single service provider.
                            </p>

                            <Spacing lg="30" md="20" />
                            <p>
                                Consumer demand and adoption of fintech enabled services is driven largely by the level of trust and the value proposition of these services. Having effective consumer protection guidelines that also cover the unique issues around fintech can boost trust among consumers and encourage them to use new fintech services. It has become clear in recent years that through greater consumer protection in financial services, consumers are gaining the ability to make informed financial decisions, exercise rights, and meet obligations. Such safeguards are providing consumer with adequate, timely, and efficient redress for complaints. Regulations in this area thus tend to focus on several related objectives:
                            </p>

                            <Spacing lg="30" md="20" />
                            <p>
                                ensure consumers have information sufficient for more informed financial decisions,
                            </p>

                            <Spacing lg="30" md="20" />
                            <p>
                                prevent service providers from using unfair practices, and provide recourse mechanisms to resolve disputes.

                            </p>



                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/digital-country30.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />


                    </Div>

                </Div>

                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="ENSURING RESPONSIBLE FINTECH SERVICES"
                            subtitle=""
                        >


                            <Spacing lg="30" md="20" />
                            <p>
                                The Law on Electronic Commerce, establishing several important rules that also apply to fintech-enabled services and electronic payments. The law strengthens the ability to use e-signatures and data privacy. And it features important consumer protection measures, with criminal liability for online harassment and unauthorized uses of personal data privacy, electronic payments and electronic funds transfers, rules for payment service providers, and electronic fraud.
                            </p>

                            <Spacing lg="30" md="20" />
                            <p>
                                As noted, however, this balancing act places regulators in a dilemma: protecting consumers
                            </p>

                            <Spacing lg="30" md="20" />
                            <p>
                                even as they seek to avoid high compliance costs on fintech providers. High costs risk limiting access to financial services for the target populations and undermining the providing firms’ business models. Achieving the right balance will thus mean clearly identifying both the risks and constraints that consumers grapple with, and the language barriers, culture, and general knowledge and attitudes about technology present in the population. Likewise, they will need to understand all issues fintech providers themselves face as they manage the risks and associated extra costs.
                            </p>

                            <Spacing lg="30" md="20" />
                            <p>
                                In some jurisdictions, regulators have worked closely with the fintech industry to introduce an industry-led code of ethics for responsible fintech services, especially digital credit, that can effectively complement regulatory oversight of the sector.
                            </p>

                            <Spacing lg="30" md="20" />
                            <p>
                                Regulators thus need to assess fintech services to identify the vulnerabilities and risks for consumers and SMEs, including direct risks emerging through a mobile phone or other digital channel, or arising through use of a third-party agent or online. Identifying the risks requires measuring impact and its likelihood, which will depend on the environment, types of technology, consumer demand, and factors unique to each market.

                            </p>



                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/digital-country31.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />


                    </Div>

                </Div>


                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="ENSURING RESPONSIBLE FINTECH SERVICES"
                            subtitle=""
                        >
                            <Spacing lg="30" md="20" />
                            <h5>
                                IMPORTANCE OF ADEQUATE AND COMPLETE INFORMATION
                            </h5>

                            <Spacing lg="30" md="20" />
                            <p>
                                As regulators set up consumer protection policies and regulations for fintech providers, the following minimum considerations are needed:
                                A regulatory framework for consumer protection under a proportionate risk-based approach to prudential standards that also allows innovation and aims overall for financial inclusion.
                                Fintech providers are, to the extent possible, licensed to operate under clear rules to guard against misappropriation of consumer funds by the fintech provider, insolvency, fraud, or any operational risk.
                            </p>

                            <ul>
                                <li>risks of new technology</li>
                                <li>third-party agent risks</li>
                                <li>challenges of new services or new fintech providers</li>
                                <li>consumer and data privacy concerns, and</li>
                                <li>outsourcing to third-party service providers</li>
                            </ul>

                            <Spacing lg="30" md="20" />
                            <h5>
                                Fintech providers operate on a level and competitive playing field that increases efficiency and consumer choice.
                            </h5>

                            <Spacing lg="30" md="20" />
                            <p>Appropriate and accurate standards for disclosure and transparency of information. Simplified consumer protection rules for low value transactions guided by the principle of proportionate risk-based policies.
                            </p>

                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/digital-country32.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />


                    </Div>

                </Div>


                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="ENSURING RESPONSIBLE FINTECH SERVICES"
                            subtitle=""
                        >
                            <Spacing lg="30" md="20" />
                            <h5>
                                DATA PRIVACY AND PROTECTION
                            </h5>

                            <Spacing lg="30" md="20" />
                            <p>
                                Increasing digitalization is deepening the digital footprints that individuals and SMEs leave behind. While not all countries have defined data privacy laws, several countries are either developing laws or, in the interim, are adopting data privacy regulations under e-commerce or other laws.

                            </p>

                            <Spacing lg="30" md="20" />

                            <p>
                                Many policy makers have introduced regulatory frameworks based on the EU’s GDPR of 2016 and enforced in 2018.

                            </p>

                            <Spacing lg="30" md="20" />

                            <p>
                                The main objective of the GDPR is to give people control of their own personal data and, by unifying regulation in the EU, to create a simpler regulatory environment for international players. The regulation highlights a few important areas:
                            </p>


                            <Spacing lg="30" md="20" />
                            <p>
                                The use of cloud-based services, financial transactions, making or accepting payments, browsing the internet, the use of mobile apps, interactions in social media, buying or selling online, getting ratings from customers, shipping packages, or online record-keeping are just examples of activities that generate an unprecedented volume of data points that provide increasing opportunities, including access to finance thanks to the use of alternative credit scoring models using advanced analytics.
                            </p>





                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/digital-country33.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />


                    </Div>

                </Div>

                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="ENSURING RESPONSIBLE FINTECH SERVICES"
                            subtitle=""
                        >
                            <Spacing lg="30" md="20" />
                            <h5>
                                CYBERSECURITY
                            </h5>

                            <Spacing lg="30" md="20" />
                            <p>
                                Risk to cybersecurity risk is increasing with the expansion of fintech; the growing number of digital transactions; the storage of vast amounts of personal data, and the proliferation of products and services that use or transmit personal identification information or sensitive information (e.g., financial records, account information, tax filling, etc.); and the interconnectivity of more players in the financial system.

                            </p>

                            <Spacing lg="30" md="20" />

                            <p>
                                New and smaller fintech providers are especially vulnerable to cybersecurity threats, because their security is limited compared to larger financial institutions. In addition, SMEs and individual consumers are especially vulnerable because they may be unaware that their data is valuable to cybercriminals.

                            </p>

                            <Spacing lg="30" md="20" />

                            <p>
                                In particular, various specific barriers exist for the adoption of cybersecurity by smaller fintechs and SMEs. These barriers generally have to do with limited awareness, the lack of capabilities and resources, the shortage of standards in specific areas, and the lack of clear implementation guidelines. Consideration could be given to publicly sponsored capacity-building initiatives— or co-sponsored by the private sector—aimed at overcoming lack of awareness or insufficient capacity among smaller fintech providers and SMEs for implementing measures to lower and even prevent cybersecurity risks.
                            </p>


                            <Spacing lg="30" md="20" />
                            <p>
                                These efforts could aim to raise workforce skill levels or otherwise avoid skills becoming obsolete or give workforces new skills to switch expertise to cybersecurity. They could also involve structural measures that aim to re-educate the upcoming workforce in these areas.
                            </p>





                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/digital-country34.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />


                    </Div>

                </Div>


                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="ENSURING RESPONSIBLE FINTECH SERVICES"
                            subtitle=""
                        >

                            <Spacing lg="30" md="20" />
                            <p>
                                Additionally, authorities could look at efforts to create affordable cybersecurity, particularly programs for smaller fintech providers and SMEs . Cooperation and information sharing with national and international agencies, meanwhile, could significantly raise abilities in smaller fintech providers and SMEs to thwart cybersecurity threats.
                            </p>

                            <Spacing lg="30" md="20" />

                            <p>
                                From a regulatory perspective, a wide range of cybersecurity frameworks exists, yet most are focused nationally or cover a particular sector of the economy. In 2019, the Alliance for Financial Inclusion (AFI) published a report providing key principles and guidelines that help regulatory and supervisory officials create tools for financial sector players to counter cybersecurity risks related to fintech firms and financial institutions.

                            </p>

                            <Spacing lg="30" md="20" />

                            <p>
                                The report outlines the following key principles and guidelines for regulators (AFI 2019):
                            </p>


                            <Spacing lg="30" md="20" />
                            <p>
                                Establishing and maintaining a cybersecurity framework to guides fintech firms, financial institutions, and digital solution providers. The framework needs to be of appropriate size for the company in question and the risks its customers face.
                            </p>

                            <Spacing lg="30" md="20" />
                            <p>
                                Developing cybersecurity awareness programs for financial service providers, fintech firms, and firms offering digital solutions.

                            </p>

                            <Spacing lg="30" md="20" />
                            <p>
                                Ensuring cooperation with the many national agencies that operate in the cybersecurity area and bringing threats and incidents to their attention.

                            </p>

                            <Spacing lg="30" md="20" />
                            <p>
                                Where a financial service provider or fintech  firm is subject to a cybersecurity failure that causes a data breach or supervisory authorities receive reports of fraud, regulators need to review the cyber threat and bring it to the attention of others also vulnerable to attack.
                            </p>





                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/digital-country35.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />


                    </Div>

                </Div>


                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="ENSURING RESPONSIBLE FINTECH SERVICES"
                            subtitle=""
                        >

                            <Spacing lg="30" md="20" />
                            <p>
                                Digital financial services (fintech) are increasingly beneficial to individual users, governments, and businesses and can be especially useful in broadening inclusive economic growth. Indeed, the COVID-19 pandemic has raised the urgency of their use as economic lockdowns have threatened to undo recent progress in this latter area. However, responsible and inclusive digital finance ecosystems require the right mix of enabling policies and regulations that ensure safety and soundness principles. Such an environment can also help countries in their efforts to meet the Sustainable Development Goals.  These trends and tools can help policy makers and regulators harness the emerging services while ensuring safety and soundness.
                            </p>

                            <Spacing lg="30" md="20" />

                            <ul>
                                <li>LEGAL AND REGULATORY FRAMEWORK</li>
                                <li>BUILD REGULATORY/SUPERVISORY CAPACITY</li>
                                <li>FOUNDATIONAL INFRASTRUCTURE</li>
                                <li>MAIN APPROACHES TO REGULATING FINTECH</li>
                                <li>PRUDENTIAL AND MARKET CONDUCT</li>
                                <li>DIGITAL FINANCIAL LITERACY</li>
                                <li>COMPETITION AND COORDINATION</li>
                                <li>SAFEGUARD INTEGRITY</li>

                            </ul>





                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/digital-country37.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />


                    </Div>

                </Div>

                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="CONCLUSION AND RECOMMENDATIONS"
                            subtitle=""
                        >
                            <Spacing lg="30" md="20" />
                            <h5>LEGAL AND REGULATORY FRAMEWORK</h5>

                            <Spacing lg="30" md="20" />
                            <p>
                                Ensuring an appropriate and enabling legal and regulatory framework is crucial for an environment that supports development and use of responsible fintech services. The framework must both identify obstacles to innovation and adapt to new risks. Legal frameworks should provide legal predictability to support investment and ensure that consumers' rights are protected, as well as a legal basis for smart contracts and electronic signatures.
                            </p>


                            <Spacing lg="30" md="20" />
                            <h5>BUILD REGULATORY/SUPERVISORY CAPACITY</h5>

                            <Spacing lg="30" md="20" />
                            <p>
                                Given the challenges of new technologies and digital financial service providers, regulatory and supervisory capacity must be continually enhanced. Many regulators have developed innovative approaches to better understand and monitor fintech development. These include innovation offices, test-and-learn approaches to regulation, and regulatory sandboxes.
                                A suitable framework also ensures appropriate legal authorization to supervise fintech service providers and take corrective actions to address safety and soundness concerns. Legal frameworks should also provide legal protection for supervisors. Supervisory departments should regularly evaluate staffing requirements and existing staff skills and take measures to bridge gaps. Supervising fintech providers also requires new approaches and tools, including regulatory technologies (regtech) and supervisory technologies (suptech).

                            </p>







                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/digital-country38.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />


                    </Div>

                </Div>


                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="CONCLUSION AND RECOMMENDATIONS"
                            subtitle=""
                        >
                            <Spacing lg="30" md="20" />
                            <h5>FOUNDATIONAL INFRASTRUCTURE</h5>

                            <Spacing lg="30" md="20" />
                            <p>
                                Policy makers need to understand the role and level of development of the foundational infrastructure in their own markets and address the gaps. The three essential foundational elements include identification, digital payments, and data-sharing infrastructure.
                            </p>

                            <Spacing lg="30" md="20" />
                            <p>
                                Identification infrastructure focuses on developing safe, secure, and accessible national digital identification systems that support biometric and e-KYC. International standards for digital national ID systems should be considered as countries develop this critical infrastructure.                            </p>

                            <Spacing lg="30" md="20" />
                            <p>
                                Digital payments are often the entry point for digital financial services. Inclusive payment infrastructure should result in payment services that can reach any individual or SME. Digital payment infrastructure includes automated clearinghouses, payment switches, and large value and retail payment settlement systems. It also contains certain data sharing and information systems, such as credit reporting bureaus and collateral registry systems. Also, financial-institution level infrastructure, such as core-banking systems that could take advantage of cloud technologies, can broaden access to digital financial services.                            </p>


                            <Spacing lg="30" md="20" />
                            <p>
                                As individuals and businesses expand the use of digital channels, they create substantial amounts of data that can be used to provide access to fintech products and services. Data-sharing infrastructure can broaden the range of financial services as well as empower consumers and businesses, with appropriate data privacy and protection rules to build trust and the responsible use of data.                            </p>


                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/digital-country39.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />


                    </Div>

                </Div>



                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="CONCLUSION AND RECOMMENDATIONS"
                            subtitle=""
                        >
                            <Spacing lg="30" md="20" />
                            <h5>MAIN APPROACHES TO REGULATING FINTECH</h5>

                            <Spacing lg="30" md="20" />
                            <p>
                                Regulators need to follow the latest developments that promote fintech-enabled financial services, including understanding the three main regulatory approaches: fintech enabling laws and policies, regulating enabling technologies, and regulating specific fintech activities
                            </p>

                            <Spacing lg="30" md="20" />
                            <h5>PRUDENTIAL AND MARKET CONDUCT</h5>


                            <Spacing lg="30" md="20" />
                            <p>
                                Understand the critical aspects of prudential and market conduct regulatory approaches for digital finance. To protect the stability of the financial system, regulators need to treat similar risks equally; apply laws and regulations proportionately; avoid market concentration and unfair practices; and foster standardization, interoperability, and interconnectivity in a safe manner.
                            </p>

                            <Spacing lg="30" md="20" />
                            <h5>DIGITAL FINANCIAL LITERACY</h5>


                            <Spacing lg="30" md="20" />
                            <p>
                                Support digital financial awareness and literacy by embedding digital finance in national financial inclusion and literacy strategies. Establishing trust, safety, and security is crucial if clients are to adopt fintech-enabled financial services.
                            </p>

                            <Spacing lg="30" md="20" />
                            <h5>COMPETITION AND COORDINATION</h5>

                            <Spacing lg="30" md="20" />
                            <p>
                                Policy makers and regulators need to support competition, inter-regulatory coordination, and public and private dialogue. Cooperation and coordination among regulators and supervisors are also essential in supporting an effective regulatory and supervisory framework for all financial institutions, including new fintech providers or third-party service providers. Regulatory and supervisory responsibilities must be delineated and coordinated to avoid regulatory arbitrage. Coordination and consultation with other regulators can help ease providers' regulatory burdens and thus keep compliance costs down. Close coordination and regular dialogue with fintech sector players will also allow regulators to better support regulatory responses.
                            </p>



                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/digital-country40.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />


                    </Div>

                </Div>

                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="CONCLUSION AND RECOMMENDATIONS"
                            subtitle=""
                        >
                            <Spacing lg="30" md="20" />
                            <h5>
                                SAFEGUARD INTEGRITY
                            </h5>

                            <Spacing lg="30" md="20" />
                            <p>
                                Effective consumer protection guidelines covering the unique issues around fintech can help build consumer trust and confidence, which can improve uptake and usage. These include ensuring that providers are fully transparent with consumers, that market conduct rules are in place to prevent unfair practices, and that appropriate redress mechanisms exist. Consumer protection regulations tend to pursue the following broad objectives:
                            </p>


                            <Spacing lg="30" md="20" />
                            <p>
                                Ensure consumers have enough information to make informed financial decisions.
                            </p>



                            <Spacing lg="30" md="20" />
                            <p>
                                Prevent unfair practices by service providers.
                            </p>



                            <Spacing lg="30" md="20" />
                            <p>
                                Ensure consumers have access to recourse mechanisms to resolve disputes.
                            </p>

                            <Spacing lg="30" md="20" />
                            <p>
                                Safeguarding consumers in the digital age also requires specific measures to ensure cybersecurity, data privacy, and protection. This includes mitigating the risks of money laundering and financing of terrorism that new fintech products and services, especially crypto assets, may pose.

                            </p>


                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/digital-country41.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />


                    </Div>

                </Div>



            </Div>
        </>
    )
} 