import React from 'react'
import Portfolio from '../Portfolio'
import Div from '../Div'
import Slider from 'react-slick';

export default function PortfolioSlider() {
  const portfolioData = [
    {
      title: 'Tmico',
      subtitle: 'www.tmico.com',
      src: '/images/new/1.png',
      href: 'https://www.tmico.com'
    },
    {
      title: 'Laugfs',
      subtitle: 'www.laugfsgas.lk',
      src: '/images/new/2.png',
      href: 'https://www.laugfsgas.lk'
    },
    {
      title: 'My Petroleum',
      subtitle: 'www.mypetroleum.com',
      src: '/images/new/3.png',
      href: 'https://www.mypetroleum.com'
    },
    {
      title: 'Loak',
      subtitle: 'aklawplc.com',
      src: '/images/new/4.png',
      href: 'https://aklawplc.com'
    },

    {
      title: 'Empd',
      subtitle: 'empdtrading.com',
      src: '/images/new/5.png',
      href: 'https://empdtrading.com'
    },

    {
      title: 'GandG Global',
      subtitle: 'gand.global',
      src: '/images/new/6.png',
      href: 'https://gand.global'
    },

    {
      title: 'Axex',
      subtitle: 'axex.com.tr',
      src: '/images/new/7.png',
      href: 'https://axex.com.tr/'
    },
     {
      title: 'Solution Trading',
      subtitle: 'solutions.vpstrong.com',
      src: '/images/new/8.png',
      href: 'http://solutions.vpstrong.com/'
    },

    {
      title: 'Ey',
      subtitle: 'www.ey.com/en_us',
      src: '/images/new/9.png',
      href: 'https://www.ey.com/en_us'

    },

    {
      title:'Baker Mckenzie',
      subtitle:'www.bakermckenzie.com',
      src:'/images/new/10.png',
      href:'https://www.bakermckenzie.com/'
    },

    {
      title:'Dla Piper',
      subtitle:'www.dlapiper.com/en',
      src:'/images/new/11.png',
      href:'https://www.dlapiper.com/en/'
    },

    {
      title:'Shri Swastik',
      subtitle:'ssgtradinguae.com',
      src:'/images/new/12.png',
      href:'https://ssgtradinguae.com/'
    },

    {
      title:'Ims Certification',
      subtitle:'imscertification.ae',
      src:'/images/new/13.png',
      href:'http://imscertification.ae/'
    },

    {
      title:'Eagle Cargo',
      subtitle:'eaglecargoservices.com',
      src:'/images/new/14.png',
      href:'https://eaglecargoservices.com/'
    },

    {
      title:'Reno Finans',
      subtitle:'www.renofinans.com.tr',
      src:'/images/new/15.png',
      href:'https://www.renofinans.com.tr/'
    },


    {
      title:'Trustfinco',
      subtitle:'trustfinco.com',
      src:'/images/new/16.png',
      href:'https://trustfinco.com/'
    },


    {
      title:'AcDijital',
      subtitle:'acdijitalteknoloji.com.tr',
      src:'/images/new/17.png',
      href:'https://www.acdijitalteknoloji.com.tr/'
    },


    {
      title:'Sakafinance',
      subtitle:'sakafinance.com',
      src:'/images/new/18.png',
      href:'https://sakafinance.com'
    },


    {
      title: 'Aye Carr',
      subtitle:'ayecarrltd.com',
      src:'/images/new/19.png',
      href:'https://www.ayecarrltd.com'
    },


    {
      title:'World Economic Forum',
      subtitle:'www.weforum.org',
      src:'/images/new/20.png',
      href:'https://www.weforum.org'
    },


    {
      title:'System Defence',
      subtitle:'systemdefence.com',
      src:'/images/new/21.png',
      href:'https://www.systemdefence.com '
    },


    {
      title:'Sakazone',
      subtitle:'sakazone.com',
      src:'/images/new/22.png',
      href:'https://www.sakazone.com'
    },


    {
      title:'Foundation Vatican',
      subtitle:'foundationvaticanagpi.va',
      src:'/images/new/23.png',
      href:'http://www.fondazionevaticanagpi.va/'
    },


    {
      title:'Sakapetrol',
      subtitle:'sakapetrol.com',
      src:'/images/new/24.png',
      href:'https://sakapetrol.com/'
    },

  
  ]

  /** Slider Settings **/
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "0",
    slidesToShow: 3,
    speed: 500,
    dots: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };

  return (
    <Slider {...settings} className='cs-slider cs-style3 cs-gap-24'>
      {portfolioData.map((item, index) => (
        <Div key={index}>
          <Portfolio
            title={item.title}
            subtitle={item.subtitle}
            href={item.href}
            src={item.src}
          />
        </Div>
      ))}
    </Slider>
  )
}
