import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { pageTitle } from '../../helper'
import Accordion from '../Accordion'
import Button from '../Button'
import Cta from '../Cta'
import IconBox from '../IconBox'
import PageHeading from '../PageHeading'
import Div from '../Div'
import SectionHeading from '../SectionHeading'
import TestimonialSlider from '../Slider/TestimonialSlider'
import Spacing from '../Spacing'

export default function ProductService() {
    pageTitle('RENO FINANCE');
    const params = useParams();
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <PageHeading
                title={'RENO FINANCE'}
                bgSrc='/images/service_hero_bg.jpeg'
                pageLinkText={"RENO FINANCE"}
            />
            <Spacing lg='145' md='80' />
            <Div className="container">

            </Div>


            <Div className="container">
                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="ABOUT US"
                            subtitle=""
                        >

                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                Reno Financial Consulting JSC has started to operate in Turkey on 2015. It has been providing consultancy service related to financial structuring, business development, solution partnership, asset management, project management, energy, infrastructure and mining issues with its extensive staff which is an international field career and also in the management of multinational corporations. RENO FINANCE, which serves four continents in the world on a sectoral basis, has taken the principle that it is the most valuable vehicle of the time and aimed to measure the services that it has provided with customer satisfaction in a timely manner.
                            </p>



                            <Spacing lg="30" md="20" />
                            <ul>
                                <li>Financial Planning</li>
                                <li>Investment Management</li>
                                <li>Business Development</li>
                                <li>Mergers Acquisitions</li>
                                <li>Energy Infrastructure Services & Mining</li>
                                <li>Asset and Wealth Management</li>
                                <li>Project Financing</li>
                                <li>Sector and Field Investigations</li>
                                <li>Intermediation for Precious Mine and Stone Purchase</li>

                            </ul>




                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/renofinance1.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />
                    </Div>

                </Div>




                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="FINANCIAL PLANNING"
                            subtitle=""
                        >

                            <Spacing lg="30" md="20" />
                            <p>
                                Analysing the financing investment options of the operator, Estimating the future results of current decisions, Deciding on alternatives to be selected, The financial plan is a process consisting of measuring the performance achieved according to the determined targets.
                            </p>

                            <Spacing lg="30" md="20" />

                            <p>
                                The results you will find with our professional team are as follows:

                            </p>

                            <Spacing lg="30" md="20" />

                            <ul>
                                <li>Provide the funds necessary to conduct business operations.</li>
                                <li>Providing funds on the most favourable terms to minimize the cost of financing.</li>
                                <li>Balance costs and risks without losing control over business owners.</li>
                                <li>To adapt the financial structure of the business to variable conditions.</li>
                                <li>To make expected cash surpluses timely, to look for financial deficits beforehand and to maintain financial balance</li>

                            </ul>


                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                            </p>

                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/renofinance2.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />
                    </Div>

                </Div>


                <Spacing lg="25" md="40" />


                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="OUR SERVICES "
                            subtitle=""
                        >
                            <Spacing lg="30" md="20" />
                            <h5>SURETY BOND</h5>

                            <Spacing lg="30" md="20" />
                            <p>
                                A surety bond is a guarantee that a company will live up to a specific obligation. The bond is issued by the insurance company (the Surety) after they have underwritten the company requesting the bond (the Principal). Underwriting involves scrutinizing a company’s financial wherewithal to determine whether or not they are able to meet their obligation. The bond is then issued to the party (the Oblige) for whom the work is being completed or the obligation is being made. With this bond, the Surety is now a guarantor of the Principal. Depending on the obligation, there are many types of surety bonds. Some of them are: 
                            
</p>

                            <Spacing lg="30" md="20" />
                            <p>
                            Advance Payment Bond: Surety against the risk of principal’s failure to fulfill its obligations to the obligee, within a scope of a contract, project or commercial activity of goods and services, and failure to return the advance payment.
                                    Warranty Bond/Maintenance Bond: Surety against the occurrence of a damage after a specified time upon delivery due to faulty workmanship, in cases where the performance is measured after the delivery such as construction, engineering or machine production.
                                Bid Bond: Surety against the risk of the bidder withdrawing from the bid before the tendering process is completed, not signing the contract if the tender is granted, failure to provide the necessary guarantees within the scope of the tender.
                                    Payment Bond: Surety against the failure to make payment to all the subcontractors and the labor.
                                ormance Bond: Surety against the failure of the project owner to fulfill its obligations in compliance with the terms specified in the contract.
                                    This product is fairly new in Turkey, it became available only in February of 2014 with the publishing of its General Conditions but has been used around the world in many circumstances for many, many years.
                            
</p>



                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                            </p>

                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/renofinance3.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />
                    </Div>

                </Div>


                <Spacing lg="25" md="40" />


                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="INVESTMENT MANAGEMENT"
                            subtitle=""
                        >
                            
                            <Spacing lg="30" md="20" />
                            <p>
                            If the legislation changes rapidly, financial values differ, and financial products for capital markets are constantly improving, Developed by differentiating their products, customer-oriented service providers, well-managed risks and costs, managed institutions in line with laws and regulations will continue to be successful institutions in the future.

                            </p>

                          
                            <Spacing lg="30" md="20" />
                            <p>
                                Reno Finance is responsible for the preparation of projects for the investment processes of the institutions, completion of recruitment and placement processes of human resources, institutional system integrations, compliance with risk and cost management legislation and creation of analysis, reporting processes, strategic planning and management Services.

                            </p>





                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                            </p>

                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/renofinance4.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />
                    </Div>

                </Div>


                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="BUSINESS DEVELOPMENT "
                            subtitle=""
                        >
                           
                           <Spacing lg="30" md="20" />
                           <p>	Business Development, in another widespread phrase, Business Development is the creation of long-term added value from a company's customers, the markets it serves, its employees and their relationships.                      Business Development, in its outline, involves making analytical preparations so that potential business opportunities can be evaluated, sequencing, implementation, support and follow-up of transactions. Business Development actually takes the approach of entrepreneurship, strategic management, sales management, marketing management and strategic partnerships. Targets include maximizing firm revenues, developing existing products, driving new products in line with the market, finding and penetrating new markets, reaching new users in existing markets, maximizing profitability with vertical and horizontal integrations. </p>

                           <Spacing lg="30" md="20" />

                            <p>    Reno Finance, with the aim of reaching the above-mentioned goals, supports the firm with highly experienced teams in starting business, growth and strategic positioning. In critical activities such as the development of products and services, the creation and implementation of marketing strategies, pricing, negotiation management and termination of agreements, Reno Finance teams are constantly involved with the client firm and in the field. The Reno Finance teams consist of experienced people who have worked in previous professional lives, as regional managers and have a sense of strategic management.</p>



                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                            </p>

                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/renofinance5.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />
                    </Div>

                </Div>


                <Spacing lg="25" md="40" />


                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="MERGERS & ACQUISITIONS  "
                            subtitle=""
                        >
                          

                            <Spacing lg="30" md="20" />
                            <p>
                            In today's rapidly changing world, the only constant agenda of business life is for company mergers and acquisitions for companies that are in a race ahead of us. 

                            </p>

                          

                            <Spacing lg="30" md="20" />
                            <p>
                            Over the past few years, the number of mergers and acquisitions has rapidly increased both in Turkey and in the world, making mergers and acquisitions an important worldwide issue. The most important factor behind this development is the increase in interest in the interest of both corporate companies and private equity funds in mergers and acquisitions. Although there has been a significant increase in the number and volume of corporate mergers and acquisitions worldwide, the vast majority of these transactions have not been achieved. Even though the purchase price is appropriate, it has been observed that the expected value cannot be obtained in many purchasing processes. 
                            </p>

                            <Spacing lg="30" md="20" />
                            <p>
                            The upward trend in cross-border mergers and acquisitions has resulted in the search for integrated consulting services covering financial, operational, commercial and market research work of corporate companies and private equity funds. Based on our experience at Reno Finance, we offer consulting services that can respond to any need during the buying process to prevent many of our customers from achieving their objectives in mergers and acquisitions for a variety of reasons.   We therefore provide operational, commercial and market review services to our customers in order to meet these needs and to help maximize profits for mergers and acquisitions. We work with our expert advisors in a single team in an integrated manner, helping our clients make a sound assessment of the target company by preparing integrated findings and reports.

                            </p>

                
                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                            </p>

                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/renofinance6.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />
                    </Div>

                </Div>


                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="ENERGY,INFRASTRUCTURE SERVICES & MINING "
                            subtitle=""
                        >
                            

                            <Spacing lg="30" md="20" />
                            <p>
                             Global development and change have created ever more intense pressure on existing limited energy and water resources. While the companies, governments and consumers are concerned with procurement security, environmental impacts and debt payment, the Energy, Infrastructure Services and Mining sectors have begun to attract attention.

                            </p>

                            <Spacing lg="30" md="20" />
                            <p>
                              The sector is on a great change journey. Beyond the existing technologies, it has entered into a world-wide anticipation of a wider variety of technologies and a completely changing industry pattern. Companies are looking for opportunities to expand their value chains in various countries and regions in both upper and lower segments to secure their resources and end-user markets. With its strategic location and ongoing liberalization processes, Turkey is a very important energy market for international players. 
                            </p>


                          

                            <Spacing lg="30" md="20" />
                            <p>
                            Reno Finance can help energy, infrastructure services and mining companies assess their priorities, identify risks and gain the trust of their stakeholders. We aim to provide professional services to companies of all sizes, from all sectors of the industry, including energy, infrastructure services and mining applications. We provide sector-specific audit, tax, financial services, performance improvement, human resources and crisis management services to help companies assess their business life problems.
                            </p>



                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                            </p>

                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/renofinance7.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />
                    </Div>

                </Div>


                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="ASSET & WEALTH MANAGEMENT "
                            
                            subtitle=""
                        >


                            <Spacing lg="30" md="20" />
                            <p>
                             Competition in capital markets has increased significantly due to today's global economic conditions, the rapid change in legislation, the growth in financial product diversity and the emerging risk. In this framework, the development of new financial products and services for capital market institutions, the rapid change of legislation, increasing reporting and legislative compliance needs will come to the forefront.  In this competitive environment, winners will be able to diversify their products, provide customer-focused services, manage their risks and costs at the same time, and act accordingly. On the other hand, it is clear that there is significant opportunities in the ever-growing financial markets if the legislation is meticulously compliant and risk management is implemented effectively. 
                            With a global network of specialists, Reno Finance is working to turn the challenges faced by capital market institutions into opportunities in product diversification, risk and cost management, regulatory compliance and reporting.

                            </p>






                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                            </p>

                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/renofinance8.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />
                    </Div>

                </Div>


                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="PROJECT FINANCE"
                            subtitle=""
                        >


                            <Spacing lg="30" md="20" />

                            <p>
                            Project Finance is the process of project analysis taking into consideration such factors as the cash flow of an investment project, the sufficiency of the project sponsor, the credibility of the partners to realize the project, the developments expected in the sector in which the project belongs to the project, the buyers, the references of the firm that is committed to physically completing the project, Financing necessary for completion.   RENO FINANCE is closely related to debt and financing resources thanks to its prestige in the sector. In RENO FINANCE, specialist staff helps your company determine the most appropriate loan capital structure in the project process and offers financing alternatives considering their needs. It also prepares detailed reports on the financial feasibility and modelling of the project. Introducing the loan to the creditors and requesting the loan, the letter of intent, the protocol and the support and coordination of the loan contract phases and finalizing the process are also included in the services.
                            </p>

                           

                            <Spacing lg="30" md="20" />
                           <h5>PRECIOUS MINE & STONE BUYING AND SELLING</h5>


                           <Spacing lg="30" md="20" />
<p>
Gold, silver, and other valuable products are instruments that investors often choose to diversify their portfolios. There is no need to have a lot of knowledge to invest in these instruments. Because they are products that we already have in daily life, and we have a preliminary idea about their prices. Therefore, the expected changes in time can be predicted correctly. As a result, investments can be profitably separated as a result.
  RENO FINANCE is one of the leading precious metals intermediary institutions of Turkey, with ALTIN CUZDAN brand ,RENO FINANCE provides the opportunity for all domestic and foreign investors to physically purchase and sell gold, silver, platinum and palladium products on the Stock Exchange Istanbul Precious Metals and Precious Stones Market.

</p>


                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                            </p>

                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/renofinance9.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />
                    </Div>

                </Div>

                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="CASH MANAGEMENT"
                            subtitle=""
                        >


                            <Spacing lg="30" md="20" />

                            <p>
                                SAKA FINANCE knows treasuries. Working with some of the very best treasuries in the world has given us a solid knowledge and understanding of advanced treasury solutions. This knowledge we want to share with you.
                            </p>

                            <Spacing lg="30" md="20" />
                            <p>
                                SAKA FINANCE offers full-scale cash management services and tailored solutions to match our clients' complex requirements. Our “One bank, one system” concept, a single technology banking platform, allows you to view and manage financial positions – across international borders, in real time.

                            </p>

                            <Spacing lg="30" md="20" />

                            <p>
                                At SAKA FINANCE  , we work with a client centric approach and engage in long-term strategic partnerships with clients. We use an advisory methodology to run through the different processes of your treasury organization. We work with you to establish the key aspects of your financial value chain, evaluating how they work today and looking at how they will work in the future. As a result, our advisory concept provides you with the implications and return on investments of strategic initiatives. We support you in making the right decisions and enable you to benchmark your financial processes against the best-in-class.
                            </p>

                            <Spacing lg="30" md="20" />

                            <p>
                                You want to optimize your company's cash management in Europa , Asia & America  and know your precise current cash positions at SAKA FINANCE Correspondences  and your third-party banks. A customized cash management and payment transactions solution allows you to do this.
                                SAKA FINANCE offers flexible and transparent total solutions to support your business activities in throughout the world.
                            </p>


                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                            </p>

                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/renofinance10.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />
                    </Div>

                </Div>

                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="LIQUIDITY MANAGEMENT"
                            subtitle=""
                        >


                            <Spacing lg="30" md="20" />

                            <p>
                                SAKA FINANCE  liquidity management systems provide you with the following benefits:
                            </p>

                            <Spacing lg="30" md="20" />

                            <p>
                                Real-time visibility, access and control of group liquidity through our Business Systems
                                Cross country, cross company and cross currency solutions, all in real-time
                                Overlay and pooling structures create a truly automated and centralized set-up, making it easy to optimize and control your liquidity, all in real-time
                                Support of intra-company limits and interest makes it easy to outsource internal risk and interest management
                                Overlay and pooling structures to be built on top of current account structure, all in real-time
                                Optimized interest income and cost reduction
                                Flexible range of products which can be combined and tailored to your needs.

                            </p>

                            <Spacing lg="30" md="20" />
                            <p>
                                SAKA FINANCE offers several flexible solutions to help you make your liquidity management more transparent. Our unique IT platform makes it possible to deliver real-time information to our clients, taking treasury to the next level by maximizing decision-making ability and capital efficiency.
                                Our solutions are accurate and reliable and provide you with increased visibility and control of your funds.
                            </p>


                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                            </p>

                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/renofinance11.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />
                    </Div>

                </Div>


                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="INTEGRATION  INFRASTRUCTURE"
                            subtitle=""
                        >


                            <Spacing lg="30" md="20" />

                            <p>
                                SAKA FINANCE help clients become the best in their industry by leveraging our technology to provide cost efficient and high-quality solutions to solve a problem or realize business opportunities.
                            </p>

                            <Spacing lg="30" md="20" />

                            <p>
                                At SAKA FINANCE , clients shape the future of technology developments, and our single IT platform enables us to be flexible and rapidly respond to our clients’ new identified needs. We fully support the on-going industry move towards standardization.
                                Our integration and infrastructure solutions provide you with the following benefits:


                            </p>

                            <Spacing lg="30" md="20" />
                            <p>
                                A single platform for all online banking activities
                                Multiple integration possibilities with clients’ systems
                                Faster, secure and more efficient transaction processing
                                Communication channels and file format options to fit your specific need and setup.

                            </p>

                            <Spacing lg="30" md="20" />
                            <p>
                                The importance of high-performing electronic systems is well known by our clients. A growing number of corporates exchange data electronically when they order goods and services, confirm orders, send invoices or make payment transfers.
                                Consequently, the requirements towards straight through processing, security, standardization and communication have escalated and become crucial factors for corporates all over the world.

                            </p>


                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                            </p>

                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/renofinance12.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />
                    </Div>

                </Div>

                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="CORPORATE REPORTING"
                            subtitle=""
                        >


                            <Spacing lg="30" md="20" />

                            <p>
                                SAKA FINANCE provides reports in a large variety of formats and channels to help you get transparency of your transactions, volumes and fees.                  								           Our solutions will, among other things, provide you with the below benefits:
                            </p>

                            <Spacing lg="30" md="20" />

                            <p>
                                Fast and easy access to a transparent overview of transactions, volumes, fees and other banking charges
                                Receive reports from the bank or create them yourself
                                Simple to export information to Excel through our CRM
                                Customization - different types and multiple different options for tailoring reports to fit your needs.


                            </p>

                            <Spacing lg="30" md="20" />
                            <p>
                                Offering a strong portfolio of Corporate Reporting services, SAKA FINANCE ensures to strengthen and improve your daily overview of your business.
                                Reports can be tailored to your specific needs and thus provide you with precise information helping you to become better.                                Faster, secure and more efficient transaction processing

                            </p>




                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                            </p>

                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/renofinance13.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />
                    </Div>

                </Div>


                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="MULTINATIONALS"
                            subtitle=""
                        >


                            <Spacing lg="30" md="20" />

                            <p>
                                SAKA FINANCE offers major corporations and multinationals an integrated advisory service and individual, tailor-made product solutions.
                            </p>

                            <Spacing lg="30" md="20" />
                            <h5>SYNDICATED FINANCING</h5>

                            <Spacing lg="30" md="20" />

                            <p>
                                SAKA FINANCE  Capital Markets’ syndication teams, each led by a senior banker, coordinate with our industry experts to deliver rapid decision-making; we understand that our clients need answers to respond to opportunities. When we commit, we execute. provides reports in a large variety of formats and channels to help you get transparency of your transactions, volumes and fees.

                            </p>

                            <Spacing lg="30" md="20" />
                            <p>
                                We offer you personalized solutions – tailored to your needs and the requirements of the banking market. In so doing, we support you in the following areas:

                            </p>

                            <Spacing lg="30" md="20" />
                            <ul>
                                <li>Syndicated Financing</li>
                                <li>Acquisition financing</li>
                                <li>Management and Leveraged Buyouts</li>
                                <li>Structured Trade Finance</li>

                            </ul>




                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                            </p>

                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/renofinance14.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />
                    </Div>

                </Div>

                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="ACQUISITION FINANCING"
                            subtitle=""
                        >


                            <Spacing lg="30" md="20" />

                            <p>
                                SAKA FINANCE's Leveraged and Acquisition Finance team (LAF) develops solutions in all areas of debt financing, including the origination, arrangement, underwriting and syndication of buyouts and institutionally-led corporate acquisitions. 														             LAF provides and coordinates event-driven, large-scale financing solutions across the credit spectrum in the form of:
                            </p>



                            <Spacing lg="30" md="20" />
                            <ul>
                                <li>Corporate acquisition financing</li>
                                <li>Leveraged buyouts</li>
                                <li>Infrastructure financing</li>
                                <li>Emerging market financing</li>
                                <li>Project finance</li>
                                <li>Management buyouts and buy-ins</li>

                            </ul>

                            <Spacing lg="30" md="20" />
                            <p>
                                We offer you personalized solutions – tailored to your needs and the requirements of the banking market. In so doing, we support you in the following areas:
                            </p>


                            <Spacing lg="30" md="20" />
                            <ul>
                                <li>Syndicated Financing</li>
                                <li>Acquisition financing</li>
                                <li>Management and Leveraged Buyouts</li>
                                <li>Structured Trade Finance</li>

                            </ul>


                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/renofinance15.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />
                    </Div>

                </Div>

                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="MANAGEMENT & LEVERAGE BUYOUTS"
                            subtitle=""
                        >


                            <Spacing lg="30" md="20" />

                            <p>
                                SAKA FINANCE a supports management buyouts management buy ins and leveraged buyouts in Europe and Asia  in connection, for example, with succession planning or the sale of a company.
                                SAKA FINANCE  uses  its wealth of experience and proven track record of advising on mergers and acquisitions (M&A) that use leveraged finance and management buy-outs to assist customers in raising funds.
                                Structure of a Typical MBO While the management and/or financial investors bear the entrepreneurial risk in the form of equity capital, SAKA FINANCE may arrange the debt capital required.
                            </p>






                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/renofinance16.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />
                    </Div>

                </Div>


                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="MANAGEMENT & LEVERAGE BUYOUTS"
                            subtitle=""
                        >


                            <Spacing lg="30" md="20" />

                            <p>
                                Structured Trade Finance (STF) is a specialized activity dedicated to the financing of high value commodity flows. STF transactions are structured around the supply chain and commercial terms of customers, usually involving large bilateral strategic relationships.
                            </p>




                            <Spacing lg="30" md="20" />
                            <p>
                                SAKA FINANCE `S STF team focuses primarily on “upstream” financing of cross-border commodity flows and limited recourse trade finance in order to:
                            </p>


                            <Spacing lg="30" md="20" />
                            <ul>
                                <li>enhance the credit rating of the facility beyond that of the borrower</li>
                                <li>mitigate the cross border and country risk</li>
                                <li>produce a transaction where the whole proposition is more resilient than the sum of its parts.</li>

                            </ul>

                            <Spacing lg="30" md="20" />
                            <p>
                                Benefits for the customer include:
                            </p>


                            <Spacing lg="30" md="20" />
                            <ul>
                                <li>securing strategic procurement</li>
                                <li>diversification of funding</li>
                                <li>greater access to finance for clients</li>
                                <li>enhanced management of transport costs and/or delivery timeframes.</li>

                            </ul>


                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/renofinance17.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />
                    </Div>

                </Div>






            </Div>
        </>
    )
}
