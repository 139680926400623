import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { pageTitle } from '../../helper'
import Accordion from '../Accordion'
import Button from '../Button'
import Cta from '../Cta'
import IconBox from '../IconBox'
import PageHeading from '../PageHeading'
import Div from '../Div'
import SectionHeading from '../SectionHeading'
import TestimonialSlider from '../Slider/TestimonialSlider'
import Spacing from '../Spacing'

export default function ProductService() {
    pageTitle('EMPD TRADING');
    const params = useParams();
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <PageHeading
                title={'EMPD TRADING'}
                bgSrc='/images/service_hero_bg.jpeg'
                pageLinkText={"EMPD TRADING"}
            />
            <Spacing lg='145' md='80' />
            <Div className="container">

            </Div>

            <Div className="container">
                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="ABOUT US"
                            subtitle=""
                        >
                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                EMPD TRADING is a financial advisory company based in the UK focused on delivering bespoke financial products to clients worldwide, bringing together a team of highly skilled professionals specializing in financial trade and investment solutions, it can offer a truly individual and innovative approach to its' client financial needs. It will not only work tirelessly to understand their needs but will offer a transparent, clear, concise, and importantly a jargon free explanation of the methods employed to bring their requirements to fruition.

                            </p>

                            <Spacing lg="30" md="20" />

                            <h5>EMPD VISION</h5>

                            <p className="cs-m0">
                                EMPD Ltd, was founded with a vision to deliver finance advisory solutions
                                that were both bespoke and transparent offering customer-specific finance services. We strive to innovate within the finance industry and always try to supersede the expectations of our customers.
                                EMPD Ltd, are a well-recognised provider of finance consultancy services. Our experts work hard to understand your specific requirements and ensure that you get professional bespoke services delivered on a timely basis.
                            </p>

                            <Spacing lg="30" md="20" />

                            <h5>EMPD EXPERTS</h5>

                            <p className="cs-m0">
                                EMPD APPROACH
                                honest and transparent approach to financial services, understanding client’s requirement’s is at the heart of EMPD’s commitment to success supplying high levels of professionalism, integrity and ethics through EMPD highly qualified team.
                            </p>




                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/new/empd1.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />
                    </Div>

                </Div>

                <Spacing lg="25" md="40" />


                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="EMPD SERVICES"
                            subtitle=""
                        >

                            <Spacing lg="30" md="20" />
                            <h5> TRADE FINANCE
                            </h5>

                            <p className="cs-m0">
                                Trade finance is a general term used in relation to the financing of goods or
                                services used in international trade.
                            </p>

                            <Spacing lg="30" md="20" />
                            <h5>LOCKBOX / ESCROW
                            </h5>

                            <p className="cs-m0">
                                Lock Box is a service provided by by EMPD Trading via our associates Trade and Merchant Solutions Limited to companies for their secure receipt of payment from senders of money and the subsequent secure payment to those beneficiaries designated by the payor under specific and supervised method.
                            </p>

                            <Spacing lg="30" md="20" />
                            <h5>PROJECT FINANCE
                            </h5>

                            <p className="cs-m0">
                                EMPD offers its clients advice in the areas of project finance, proposing timely solutions from concept to implementation, building on and going beyond the traditional balance sheet basics.
                            </p>



                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/new/empd2.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />
                        <Spacing lg="30" md="20" />
                        <p className="cs-m0">

                        </p>
                    </Div>

                </Div>

                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title=""
                            subtitle=""
                        >
                            <Spacing lg="30" md="30" />
                            <h5>FINANCIAL ADVISORY</h5>

                            <p className="cs-m0">
                                EMPD provides bespoke financial advisory service to its clients with a quality
                                of service which is built on trust and transparency.

                            </p>

                            <Spacing lg="30" md="30" />
                            <h5>LEGAL STRUCTURING</h5>

                            <p className="cs-m0">
                                At setup any new business is required to choose a legal structure under which the company will operate. This structure will determine not only the type of Company but more importantly the liabilities of ownership and tax, our specialist legal associates can offer advice and planning during this period assisting in limiting any risk exposure.


                            </p>



                            <Spacing lg="30" md="30" />
                            <h5>M&A</h5>

                            <p className="cs-m0">
                                Differentiating the two terms, Mergers is the combination of two companies
                                to form one, while Acquisitions is one company taken over by the other.
                                
                            </p>






                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/new/empd3.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />
                    </Div>

                </Div>

                <Spacing lg="25" md="40" />







            </Div>
        </>
    )
}
