import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { pageTitle } from '../../../helper'
import Accordion from '../../Accordion'
import Button from '../../Button'
import Cta from '../../Cta'
import IconBox from '../../IconBox'
import PageHeading from '../../PageHeading'
import Div from '../../Div'
import SectionHeading from '../../SectionHeading'
import TestimonialSlider from '../../Slider/TestimonialSlider'
import Spacing from '../../Spacing'

export default function ProductService() {
    pageTitle('Fintech-Products');
    const params = useParams();
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <PageHeading
                title={'Fintech Products'}
                bgSrc='/images/service_hero_bg.jpeg'
                pageLinkText={"Fintech-Products"}
            />
            <Spacing lg='145' md='80' />
            <Div className="container">

            </Div>


            <Div className="container">
                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="FinTech PRODUCTS "
                            subtitle=""
                        >
                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                The Financial Technology establishment of  SAKA HOLDING has developed rapidly in the last 10 years, with the aim of developing digital economy tools and global integration.
                            </p>

                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                The GOLD IN WALLET Protocol which belongs to SAKA HOLDING, a web-based financial technology product that enables users to shop with gold in national and 	international markets.
                            </p>


                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                At the same time, it is the rightful owner of the SaaS Platform, which combines the software and hardware suite and provides solutions for data access, design and facilitating business and social life processes.
                            </p>



                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/FintechProducts1.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />
                    </Div>

                </Div>

                <Spacing lg="25" md="40" />


                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="GOLD IN WALLET "
                            subtitle=""
                        >
                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                Gold in  Wallet is web based fintech product, which enables the client to trade with gold, by using the "brokerage services" obtained from licenced brokerage house.
                            </p>

                            <Spacing lg="30" md="30" />
                            <h5> GOLD IN WALLET BASIC FUNCTIONS</h5>

                            <ul>
                                <li> GOLD IN WALLET  is used to invest in gold or to make gold-based expenditures.</li>
                                <li> GOLD IN WALLET is a fast and reliable transfer method among wallet owners.</li>
                                <li> GOLD IN WALLET can be used for physical gold purchase, storage and transfer. Gold Wallet is relatively less sensitive to currency risk due to its physical counterpart. Gold Wallet users access the interface using their own user account number, username and password. User interface is easy to understand and planned effectively.</li>
                                <li> The first option for the GOLD IN WALLET users is the purchase of gold.</li>
                                <li> User decides the amount he wants to buy at livestock prices.</li>
                                <li> As a payment method, you can pay by wire transfer or prepaid card.</li>
                                <li> The amount requested by Gold in Wallet user is physically covered by Brokerage House.</li>
                                <li> The relevant amount is transferred to gold saving account of the wallet holder and taken to storage.</li>
                                <li> This amount can be seen as balance and weight in the user's wallet.</li>
                                <li> Gold in Wallet users can sell/buy gold in desired amount, anytime they wish.</li>
                                <li> Users complete sales process by entering the bank account details that the amount wants to be deposited.</li>
                                <li> The most important part of the application; it is an option to spend your gold as gold amount or money.</li>
                                <li> GOLD IN WALLET users create a shopping balance by entering the amount they want to spend.</li>
                                <li> Customers can shop on all sites that accept gold wallet payment by using the code they have created.</li>
                                <li> They can even make online transactions via API without leaving the shopping site.</li>
                                <li> GOLD IN WALLET users can send gold between accounts.</li>
                                <li> The important point is that the submissions must be made in gold.</li>
                                <li> Transfer between the two accounts can be made in gold, not money.</li>
                                <li> This transfer can be made as a spending balance or directly in gold.</li>
                            </ul>

                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/FintechProducts2.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />
                    </Div>

                </Div>

                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="GOLD CARD "
                            subtitle=""
                        >
                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                Prepaid cards meet your needs as the type of card you limit by pre-loading money that you can use when shopping or paying. With these cards, you can spend as much as the amount loaded on your card. Prepaid cards are easy to use in daily life. Thanks to a wide network of merchants that share the campaigns of the banks, it provides an easier and safer payment opportunity without paying cash. In order to experience this ease, all you need to do is to identify the businesses in your area where you can use this card where you often shop and pay.
                            </p>

                            <Spacing lg="30" md="30" />
                            <h5> But prepaid card does not save money while you spend.</h5>


                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                As GOLD IN WALLET users store their savings as gold, they have the opportunity to spend only as much as they need to save money. Your savings are always stored as equivalent amount of physical gold. Thus, GOLD IN WALLET is less sensitive to currency risk due to its physical counterpart.
                            </p>

                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/FintechProducts3.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />
                    </Div>

                </Div>

                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="GOLD SPENDING CARD  "
                            subtitle=""
                        >
                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                If you like shopping on the internet but afraid to enter credit card information, it is time to meet the Gold Spending Card!
                            </p>
                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                The Gold Spending Card is the payment system that allows you to spend with the existing physical gold in your Gold Wallet, on the internet or wallet-based businesses.

                            </p>

                            <Spacing lg="30" md="30" />
                            <h5>  Have you bought a bike with GOLD on the internet!</h5>

                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                You can easily buy gold-priced products without making any FX (exchange) from our merchants through the spending card you created in your Gold Wallet account.
                            </p>



                            <Spacing lg="30" md="30" />

                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/FintechProducts4.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="30" md="20" />

                    </Div>

                </Div>


                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="GOLDEN POSS   "
                            subtitle=""
                        >
                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                GOLD IN WALLET will make a very serious contribution to the sector with a POS that can be converted into pure gold balance, which is an important gap in the jewelry sector, and will have a serious network through the jewelers’ network. All transactions made by the customer with a credit card will be instantly converted to gold. The jeweler who owns a POS will be able to instantly follow the gold balance he owns.
                            </p>
                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                In addition, jewelers will be able to track and physically receive the gold from the system in return for their accumulated balances via GOLD IN WALLET. This will meet an important need of the industry
                            </p>

                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/FintechProducts5.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />
                    </Div>

                </Div>


                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="PAY BACK BY GOLD "
                            subtitle=""
                        >
                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                GOLD IN WALLET will make a very serious contribution to the sector with a POS that can be converted into pure gold balance, which is an important gap in the jewelry sector, and will have a serious network through the jewelers’ network. All transactions made by the customer with a credit card will be instantly converted to gold. The jeweler who owns a POS will be able to instantly follow the gold balance he owns.
                            </p>


                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                In addition, jewelers will be able to track and physically receive the gold from the system in return for their accumulated balances via GOLD IN WALLET. This will meet an important need of the industry
                            </p>



                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/FintechProducts6.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />
                    </Div>

                </Div>


                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="ALL IN ONE"
                            subtitle=""
                        >
                            <ul>
                                <li>BUSINESS DEVELOPMENT PLATFORM</li>
                                <li>INTELLECTUAL PROPERTY DIGITAL ASSET PLATFORM</li>
                                <li>INTERNATIONAL BASED ON PHYSICAL GOLD DIGITAL ASSET PLATFORM</li>
                                <li>MESSENGER AGGREGATOR WITH AI ASSISTANCE</li>
                                <li>ACTIVITY AND CONTENT MANAGEMENT </li>
                                <li>SKILL FACTORY </li>
                                <li>INTERNATIONAL MARKETPLACE SERVICE</li>
                            </ul>

                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                Creating a business development platform with the integration of digital open systems of public bodies so that individual and corporate customer transactions can be carried out safely, quickly and under a zero-issue triad.
                            </p>

                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                Providing direct services on tax and customs issues through digital open channel with relevant public bodies in order to effectively use the process related to the problems encountered by commercial banking customers in their import and export transactions.
                            </p>

                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                Centre for product and technological capabilities within the next generation banking department.
                            </p>



                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/FintechProducts7.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />
                    </Div>

                </Div>



                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="BUSINESS DEVELOPMENT PLATFORM DETAIL CHART"
                            subtitle=""
                        >


                            <Spacing lg="30" md="30" />
                            <h5> INTELLECTUAL PROPERTY DIGITAL ASSET PLATFORM
                            </h5>

                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                National Treasury: The project, called the National Treasury, is conceptually a crowdfunding platform for businesses of all sizes, from the smallest to large enterprises.
                            </p>
                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                Thanks to these resources, every business that registers on the platform has the opportunity to present their legally recognized digital assets, Security status to a wide audience and give the owner the right to take a stake in the company. In addition, the fund opens an account for businesses from which investors' funds will be received, with the support of banks and educational institutions.
                            </p>

                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                Our task is to create the most attractive conditions for all participants in the crowdfunding chain and provide them with appropriate motivation. The mechanism on which the motivation system will be built will undoubtedly be a win-win, because it is based on the support of high-quality and interesting business ideas created within educational institutions.
                            </p>

                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                We offer businesses a technology that will enable them to interact with influence agents (bloggers, watchdogs, journalists), mentors and most importantly, investors. The essence of the interaction is based on the fact that the enterprise encourages support and contributions to the company, allocating part of the funds collected and digital assets created from their own intellectual property rights in favor                                                                        of the participants in the process.
                            </p>



                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/FintechProducts8.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />
                    </Div>

                </Div>



                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title=" AREAS OF RESPONSIBILITY "
                            subtitle=""
                        >


                            <Spacing lg="30" md="30" />
                            <h5> BUSINESS
                            </h5>

                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                The business is concerned with raising capital in exchange for the company's stock. The Intellectual Property Digital Asset Platform for business is a powerful channel to attract an investor base that pays for results.

                            </p>

                            <Spacing lg="30" md="30" />
                            <h5>  WISE EXPERTS
                            </h5>

                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                Wise Experts provide a stream of investors. Even with a low conversion rate, total audience reach drives business awareness using the best response rate effect – recommendations. Opinion leaders act as expert and authoritative “advisors” and gather a significant audience around them, even in very “narrow” segments. Their job is to convey to potential investors the value of a particular business and to arouse in them the desire to become co-owners of a profitable Company.
                            </p>




                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/FintechProducts9.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />
                    </Div>

                </Div>

                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title=" AREAS OF RESPONSIBILITY "
                            subtitle=""
                        >

                            <Spacing lg="30" md="30" />
                            <h5> MENTOR
                            </h5>

                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                Mentors already have an established reputation in society. The competency areas should be close to the niche in which the Business operates. Through his expertise, the mentor publicly endorses the perspective and relevance of the particular enterprise's project. The task of the mentors is to push the already "warmed up" investor to buy the company's digital assets. The activity of the mentor is somewhat similar to that of an influence representative, but the difference is that the mentor is a direct or indirect participant in the market to which the Business belongs. This is an insider confirming that the potential profitability of the Business is real.
                            </p>


                            <Spacing lg="30" md="30" />
                            <h5> INVESTOR
                            </h5>

                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                The investor is a key participant because he or she has the final say - to give or not to give money. The investor should be interested in interacting with other participants on the platform in order to keep the platform operational. For this reason, the most attractive scenario for him will be the scenario where he meets İş, receives information from Wise Expert and decides to invest in moments of doubt thanks to the reputation and result of the Mentor. This scenario will be profitable for all other members of the System because the conversion rate tends to be as high as possible.
                            </p>

                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/FintechProducts10.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />
                    </Div>

                </Div>


                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="Messenger Functionality & opportunities "
                            subtitle=""
                        >

                            <Spacing lg="30" md="30" />
                            <h5> AI messenger
                            </h5>

                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                The All in One messenger provides instant messaging, as well as video and audio calls, has end-to-end encryption functionality and supports the maximum degree of privacy. The messenger functionality also includes the ability to create group chats.
                            </p>

                            <Spacing lg="30" md="30" />
                            <h5> One application for all types of communication worldwide
                            </h5>

                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                The protocol has a full-fledged implementation of the federation, which allows you to seamlessly communicate, organize common rooms for communication to users of different servers, make video and audio calls. In April 2018, the French government announced plans to create its own tool for instant messaging and the choice of Matrix as the fundamental platform for this.
                            </p>


                            <Spacing lg="30" md="30" />
                            <h5> Independent non politic solutions uniting people & business
                            </h5>

                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                When developing the messenger, we put the safety of users, as well as the reliability and functionality of the product, at the forefront. We will not hide that the main goal was the development of high security standards in the approach of this messenger to the protection of user information. It is for this reason that we have paid maximum attention to privacy issues.
                            </p>


                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/FintechProducts11.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />
                    </Div>

                </Div>

                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="SKILL FACTORY "
                            subtitle=""
                        >

                            <ul>
                                <li> Secure Transactions Function</li>
                                <li> COVID 19 Remote Work Control</li>
                                <li> Freelance Services Store</li>
                                <li> Purpose Discussion and Arbitrage Service</li>
                                <li> Billing Service</li>
                                <li> Searching for Project Creator and Personnel</li>
                            </ul>

                            <ul>
                                <li> Spend just one hour and receive:</li>
                                <li> Full payment system with accountl</li>
                                <li> Orders management interface</li>
                                <li> Personal online site and CRM</li>

                            </ul>


                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                OPTION ONE:
                                Find  services you need in comfortable you language and geolocation

                            </p>

                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                OPTION TWO:
                                Explore offers prices reputation and masters portfolios work
                            </p>

                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                OPTION THREE:
                                Make and order and start the save deal by smart contract
                            </p>

                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                OPTION FOUR:
                                When work is finished approve it and only at that moment the executor will receive money for job from escroll account in favour of beneficiary
                            </p>



                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/FintechProducts12.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />
                    </Div>

                </Div>


                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="AIM"
                            subtitle=""
                        >


                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                Digital platforms have already significantly changed the interaction model between service providers and consumers. By excluding intermediaries from this model, it allows businesses to reach end consumers directly, and on the other hand, it allows customers to contact the manufacturer or seller whenever and wherever they want and make their payments directly.
                            </p>

                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                The payment system developed by us has a user-friendly interface and understandable procedures that enable fast and secure information exchange and can work with all currencies and currency substitutes demanded by the market. A bet on the ease of use of payment system services will significantly reduce costs, especially for transactions of small and large amounts and when following international trends such as:
                            </p>

                            <ul>
                                <li>De-dollarization of international payments and transition to gold payment system,</li>
                                <li>Creating ecosystems to support and develop small and medium-sized businesses,</li>
                                <li>International cooperation in the field of education and digitalization of the economy.</li>

                            </ul>

                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                The most important elements of the architecture of the digital platform we have developed are the distributed ledger combined with blockchain technology. The encryption used by the registry cannot be broken. A blockchain transaction involves many independent computers connected across a network, processing the algorithm and verifying the calculations made by other computers, mathematically calculating the transaction using artificial intelligence with minimal cost and time.
                            </p>


                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/FintechProducts13.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />
                    </Div>

                </Div>



                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="METHOD"
                            subtitle=""
                        >


                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                SAKA  FinTech team has effectively implemented the fixed payment system based on physical gold since 2015 and has carried out its work regionally and worldwide. One of the advantages of SAKA FinTech is its network of relationships and the corporate strategic partnerships it has strategically established with large-scale financial companies. Their participation in the project will allow them to gain a significant share from intermediary banks in the payment market, especially in the international payments segment.
                            </p>

                            <Spacing lg="30" md="30" />
                            <h5>DIGITAL PLATFORM</h5>

                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                Payment gateway is a digital platform within the ALL IN ONE ecosystem - a SaaS platform that combines a complex of software and hardware and a set of solutions (frameworks) to simplify data access, business design and development, and various aspects of social life. It consists of projects that meet the characteristics of high-load social networks, withstand all kinds of loads and can develop quickly, with the help of publicly available solutions. The basic functions it contains are as follows.
                            </p>

                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                Cross Ecosystem opportunities that take digital platforms to a new level:
                            </p>

                            <ul>
                                <li> Internal billing system based on gold or national currency</li>
                                <li> Artificial intelligence</li>
                                <li> Collection of new high capacity database</li>
                                <li> Contract systems and "secure transactions"</li>
                                <li> Cyber security at the highest level:</li>
                                <li> Protection systems against DDOS and other types of attacks that provide automatic notification to providers</li>
                            </ul>

                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                Account protection system against “brute force” and “floggers”, selection, registration permissions, GLD blockchain codes used for safe and secure crypto  market operations worldwide
                            </p>


                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/FintechProducts14.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />
                    </Div>

                </Div>


                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="TOKEN INFORMATION "
                            subtitle=""
                        >

                            <ul>
                                <li> TOKEN NAME :  Gold Token </li>
                                <li> TOKEN SYMBOL : GLD</li>
                                <li> TOKEN STANDARD : TRC20</li>
                                <li> CONTRACT ADDRESS : TXVoyBWdyKTTtrxXRFYa7QmG9UFLRpiS4j</li>
                            </ul>

                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                Welcome to Gold Token (GLD), the revolutionary gold-backed token on the Tron Network. We are transforming the world of payments by introducing a digital asset that is securely pegged to the price of one ounce of gold. With GLD, you can now experience the stability and value of physical gold while benefiting from the speed and convenience of blockchain technology.
                            </p>

                            <Spacing lg="30" md="30" />
                            <h5>Gold-Backed
                                Stability</h5>

                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                Each GLD token is backed by 1 ounce of physical gold, ensuring that its value remains stable and reliable. This unique feature makes GLD an ideal investment and a reliable medium of exchange.
                            </p>




                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/FintechProducts15.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />
                    </Div>

                </Div>

                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="TOKEN INFORMATION "
                            subtitle=""
                        >


                            <Spacing lg="30" md="30" />
                            <h5>Transparent and Trustworthy</h5>

                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                Our smart contract on the TRC20 network guarantees the transparency and security of GLD transactions. The gold holdings are regularly audited by reputable third-party auditors, providing you with complete peace of mind.                            </p>


                            <Spacing lg="30" md="30" />
                            <h5>Instantaneous Transactions</h5>

                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                With GLD, you can enjoy lightning-fast transactions on the Tron Network. Say goodbye to lengthy processing times and high transaction fees. GLD empowers you to transfer and receive value instantly, anytime, anywhere.
                            </p>


                            <Spacing lg="30" md="30" />
                            <h5>Global
                                Accessibility</h5>

                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                Gold Token is designed to be accessible to everyone around the globe. Whether you are an individual, a merchant, or an investor, GLD provides a universal medium of exchange that transcends borders, allowing you to participate in the gold market effortlessly.
                            </p>

                            <Spacing lg="30" md="30" />
                            <h5>SECURITY AND PRIVACY</h5>


                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                Security and Privacy: Built on the Tron blockchain, GLD leverages the highest level of security and encryption protocols. Your transactions and personal information remain confidential, ensuring a secure and private experience.
                            </p>

                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/FintechProducts16.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />
                    </Div>

                </Div>

                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="HOW TO GET STARTED"
                            subtitle=""
                        >


                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                Visit our official website www.goldinwallet.com and create an account.
                            </p>

                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                Complete the necessary verification process to comply with regulatory requirements and ensure a secure trading environment.
                            </p>

                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                Once your account is verified, you can deposit USDT/TRX into your wallet and exchange.
                            </p>

                            <Spacing lg="30" md="30" />
                            <h5>JOIN THE GOLD REVOLUTION TODAY!</h5>


                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                Purchase GLD tokens directly from the platform using the USDT or other options in platform. The tokens will be instantly credited to your account.
                            </p>

                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                Use your GLD tokens for various purposes, including payments, investments, and hedging against market volatility.
                            </p>

                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/FintechProducts17.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />
                    </Div>

                </Div>



                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="JOIN THE GOLD REVOLUTION TODAY!"
                            subtitle=""
                        >


                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                Don't miss out on the opportunity to be part of the future of gold-backed digital assets. Experience the stability, security, and convenience of Gold Token (GLD) on the Tron Network. Join our vibrant community of gold enthusiasts and start transforming the way you transact and store value.
                            </p>

                            <Spacing lg="30" md="30" />
                            <h5>SUPPORT</h5>

                            <Spacing lg="30" md="30" />
                            <ul>
                                <li>MAIL : support@goldinwallet.com
                                    For any inquiries or support, please reach out to our team at support@goldinwallet.com
                                    We are here to assist you every step of the way.

                                </li>
                            </ul>

                            <Spacing lg="30" md="30" />
                            <h5>  Gold Token (GLD)</h5>

                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                is an innovative digital asset pegged to the price of gold. While every effort is made to maintain the stability of the token, market conditions may affect its value.
                                Please conduct your own research and seek professional advice before engaging in any financial transactions.
                            </p>


                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/FintechProducts18.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />
                    </Div>

                </Div>





            </Div>
        </>
    )
}
