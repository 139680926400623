import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { pageTitle } from '../../helper'
import Accordion from '../Accordion'
import Button from '../Button'
import Cta from '../Cta'
import IconBox from '../IconBox'
import PageHeading from '../PageHeading'
import Div from '../Div'
import SectionHeading from '../SectionHeading'
import TestimonialSlider from '../Slider/TestimonialSlider'
import Spacing from '../Spacing'

export default function ProductService() {
    pageTitle('SOLUTION TRADING');
    const params = useParams();
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <PageHeading
                title={'SOLUTION TRADING'}
                bgSrc='/images/service_hero_bg.jpeg'
                pageLinkText={"SOLUTION TRADING"}
            />
            <Spacing lg='145' md='80' />
            <Div className="container">

            </Div>

            <Div className="container">
                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="ABOUT"
                            subtitle=""
                        >
                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">

                                Solution Trading Development Pte Ltd. is a Singapore based company with a subsidiary in Sri Lanka that provides professional solutions to bridge and link various stakeholders in the Commodity trading supply chain.
                                We have offices situated in Canada, India, Australia, Indonesia, Middle East, China, Malaysia, Bangladesh, and Africa covering the global market.
                                Our core team comes with 30+ years of experience in Engineering, Mining, Business, Marketing, Finance & Innovation.
                            </p>

                            <Spacing lg="30" md="20" />
                            <h5>Mithiran Kirupairajah
                                Chief Executive Officer</h5>

                            <p className="cs-m0">
                                A qualified Metallurgical Engineer from highly regarded IIT university, Chennai. Mithiran is well versed with more than 35 years of experience in public and private executive corporate leadership and management in Australia.
                            </p>


                            <Spacing lg="30" md="20" />
                            <h5>Ming Susilo
                                Finance Director
                            </h5>

                            <p className="cs-m0">
                                A qualified accountant with more than 30 years of experience in audit and corporate finance. Ming held a number of senior executive positions in businesses with headquarters in Singapore and Indonesia, notably those in the sugar and coal-related industries.

                            </p>


                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/new/SOLUTION1.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />
                    </Div>

                </Div>

                <Spacing lg="25" md="40" />


                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title=""
                            subtitle=""
                        >

                            <Spacing lg="30" md="20" />
                            <h5>Dr. Raja Joseph Cheng D’Silva
                                Director Operations
                            </h5>

                            <p className="cs-m0">
                                Joseph come with an extensive knowledge of the petroleum business, expertise in gold trading, and influence within the vast commodity network. He possesses a doctorate degree in Business.
                            </p>

                            <Spacing lg="30" md="20" />
                            <h5>Rajeev Bonney
                                CEO of Regional Subsidiaries & Head of Revenue for South Asia
                            </h5>

                            <p className="cs-m0">
                                More than 30 years of experience in leadership roles in Sri Lankan Government Ministries & private enterprises. Rajeev is well experienced, influential and respected in the trade finance industry, as well as business, administrative and bureaucratic circles.
                            </p>

                            <Spacing lg="30" md="20" />
                            <h5>Dr. Aziz Saka
                                President
                            </h5>

                            <p className="cs-m0">
                                Solution Trading Turkey
                            </p>

                            <Spacing lg="30" md="20" />
                            <h5>
                                Redzuwan ABD
                                Head
                            </h5>

                            <p className="cs-m0">
                                Solution Trading Malaysia
                            </p>

                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/new/SOLUTION2.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />
                        <Spacing lg="30" md="20" />
                        <p className="cs-m0">

                        </p>
                    </Div>

                </Div>

                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="Operational Divisions"
                            subtitle=""
                        >
                            <Spacing lg="30" md="30" />
                            <h5>Coal</h5>


                            <ul>
                                <li>Product: Thermal Coal GAR 3500 to GAR 6000</li>
                                <li>Capacity: 1 Million Metric Tons per month with additional
                                    capacity to expand.</li>
                                <li>Together with our 5 partner mines, we get involved in the step-by-step process of mining & transport of quality with standard verification, certification, logistics, documentation, and financing.</li>
                                <li>Other Products: Metallurgical Coal & Petroleum Coke.</li>

                            </ul>

                            <Spacing lg="30" md="30" />
                            <h5>Petroleum</h5>


                            <ul>
                                <li>Product : Crude, Light Crude, Refined Petroleum, Jet Fuels & Oil from the Middle East and Venezuela.</li>
                                <li>Capacity: Varying storage tank capacity on demand in Singapore, Malaysia, and Rotterdam Netherlands.</li>
                                <li>Our current partnerships reside with Vitol Asia Pte Ltd, Chevron Singapore, K I Energy Pte Ltd, & Ceil SDN BHD (Shell & Petron Malaysia licenses)</li>

                            </ul>

                            <Spacing lg="30" md="30" />
                            <h5>Natural Gas</h5>


                            <ul>
                                <li>   Currently we have established networks to supply LPG and LNG to Sri Lanka and Malaysia</li>

                            </ul>



                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/new/SOLUTION3.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />
                    </Div>

                </Div>

                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title=""
                            subtitle=""
                        >
                            <Spacing lg="30" md="30" />
                            <h5>Clinker</h5>


                            <ul>
                                <li> Product : Clinker for cement production from Egypt & Middle East|</li>
                                <li> Capacity: 300,000 Metric Tons per month with additional capacity expansion</li>
                                <li> We are currently facilitated with 2 supply mines.</li>

                            </ul>

                            <Spacing lg="30" md="30" />
                            <h5>Silica Quartz</h5>


                            <ul>
                                <li> Product : Quartz Rock & Micro silica from India & Sri Lanka</li>
                                <li> Capacity: 100,000 Metric Tons per month with additional
                                    capacity expansion
                                </li>
                                <li> We are currently facilitated with 3 supply mines</li>

                            </ul>
                            <Spacing lg="30" md="30" />

                            <h5>Medical Products</h5>


                            <ul>
                                <li> Product : Rapid Covid Test Kits &amp; Other personal protective equipment from Chinaa</li>
                                <li> Capacity: 5 million kits per month with an additional capacity to expansion
                                </li>
                                <li> Currently working in collaboration with an approved supplier to the Government of Canada</li>

                            </ul>


                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/new/SOLUTION4.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="30" md="20" />
                    </Div>

                </Div>

                <Spacing lg="25" md="40" />


                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title=""
                            subtitle=""
                        >
                            <Spacing lg="30" md="20" />
                            <h5>Metals</h5>


                            <ul>
                                <li>The team has extensive experience in Gold Trading. </li>
                                <li>We have established supply chains for our products from Indonesia, Africa, Malaysia, and Australia. </li>
                                <li>Our metals division is currently working with the following commodities. </li>
                                <li>Gold</li>
                                <li>Iron Ore </li>
                                <li>Copper </li>
                                <li>Lithium </li>
                                <li>Ilmenite </li>
                                <li>Steel </li>
                                <li>Steel Scrap </li>

                            </ul>
                            <Spacing lg="30" md="30" />
                            <h5>Livestock & Meats</h5>


                            <ul>

                                <li>Our division is currently working on future markets in live exports and dressed meats. </li>
                                <li>The sources of suppliers are from Australia, Vietnam, Philippines, India & Malaysia. </li>
                                <li>Chicken Legs </li>
                                <li>Chicken Feet </li>
                                <li>Chicken </li>
                                <li>Eggs </li>
                                <li>Beef </li>
                                <li>Poultry </li>
                                <li>Goat </li>
                                <li>Seafood </li>

                            </ul>

                            <Spacing lg="30" md="20" />


                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/new/SOLUTION5.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="30" md="20" />
                    </Div>

                </Div>


                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title=""
                            subtitle=""
                        >
                            <Spacing lg="30" md="20" />



                            <h5>Agricultural Products</h5>
                            <Spacing lg="30" md="20" />

                            <ul>
                                <li>Our agricultural products division is a specialized service offered to only a selected customer base.</li>
                                <li>We have established supply chain partnerships with Indonesia, Malaysia, India, Sri Lanka, New Zealand, and Australia currently. </li>
                                <li>We are currently working with the following commodities with our customers based in China. </li>
                                <li>Soybeans</li>
                                <li>Palm Oil </li>
                                <li>Wheat including flour and Maida </li>
                                <li>Tea </li>
                                <li>Milk </li>
                                <li>Wool </li>
                                <li>Sunflower seeds & Oil </li>
                                <li>Rice </li>
                                <li>Canola & Canola Oil </li>
                                <li>Sugar </li>
                                <li>Corn </li>
                                <li>Legumes </li>

                            </ul>


                            <Spacing lg="30" md="20" />


                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/new/SOLUTION6.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="30" md="20" />
                    </Div>

                </Div>

                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="Our Commitments"
                            subtitle=""
                        >
                            <Spacing lg="30" md="20" />

                            <h5>Company Policies</h5>


                            <ul>

                                <li>Equal opportunity commitment and non- discriminatory business policy based on race, religion or sex </li>
                                <li>No workplace bullying or any sort of harassments </li>
                                <li>Committed to the elimination of modern day slavery </li>
                                <li>Committed to prevention and elimination of child labor </li>
                                <li>Committed to humane animal treatments & farming </li>
                                <li>Committed to Sustainable resource management </li>
                                <li>Implementation of Environment management and climate change management </li>
                                <li>Committed to Safe work environments </li>
                                <li>Committed to the fair distribution of profits to stakeholders </li>
                                <li>Support Wildlife conservation </li>

                            </ul>

                            <Spacing lg="30" md="30" />
                            <h5>Green Future & Climate Commitments</h5>

                            <p className="cs-m0">
                                The global markets are currently in the transitional state from fossil fuels to renewables and we are fully committed to move with the markets. We are actively seeking to expand in commodities related to;
                            </p>



                            <ul>
                                <li>Solar panels</li>
                                <li>Battery Storage and Fuel cells</li>
                                <li>Wind Energy</li>
                            </ul>

                            <p className="cs-m0">
                                Our long term focus is to be involved in Hydrogen gas generation, distribution and trading as an alternative source for energy gas.

                            </p>



                            <Spacing lg="30" md="20" />


                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/new/SOLUTION7.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="30" md="20" />
                    </Div>

                </Div>

                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="Solution Foundation"
                            subtitle=""
                        >
                            <Spacing lg="30" md="20" />


                            <p className="cs-m0">
                                Our board of directors and shareholders have approved the formation of “Solution Foundation” before the end of the year 2022.
                                10% of our company profits will be distributed to this foundation.
                                The core service area of the foundation is to provide educational pathways in basic schooling and skill improvement to socio-economically underprivileged communities around our operational countries.

                            </p>



                            <Spacing lg="30" md="20" />


                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/new/SOLUTION8.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="30" md="20" />
                    </Div>

                </Div>



                <Spacing lg="25" md="40" />


            </Div>
        </>
    )
}
