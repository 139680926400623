import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { pageTitle } from '../../helper'
import Accordion from '../Accordion'
import Button from '../Button'
import Cta from '../Cta'
import IconBox from '../IconBox'
import PageHeading from '../PageHeading'
import Div from '../Div'
import SectionHeading from '../SectionHeading'
import TestimonialSlider from '../Slider/TestimonialSlider'
import Spacing from '../Spacing'

export default function ProductService() {
    pageTitle('Digital Banking Ecosystem')
    const params = useParams();
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <PageHeading
                title={'DIGITAL BANKING ECOSYSTEM'}
                bgSrc='/images/service_hero_bg.jpeg'
                pageLinkText={"Digital Banking Ecosystem"}
            />
            <Spacing lg='145' md='80' />
            <Div className="container">

            </Div>


            <Div className="container">
                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="DIGITAL BANKING OVERVIEW "
                            subtitle=""
                        >


                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                The functionality of the global economy is shaped by the structure of human-oriented “needing” (needs) according to the supply-demand balance. In the process from the first industrial revolution to the present, technological revolutions have been built on the same balance. With the realization of the Internet and web-based communication, technological applications have changed our needs and brought a constantly innovative perspective. Consumer (B2C) and industrial (B2B) customers want to buy products and services at the most convenient time, place and condition, leading to the rapid digitalization of the economy and the creation of global ecosystems platforms. In the new conditions, operators of the global industrial ecosystem platforms have started to be consumers who use their preferences without any restrictions. Consumers, who are ecosystem operators, prefer social business networks and e-commerce systems that provide secure, fast and innovative payment services in product-money exchange, apart from the product methodology in choosing the supplier. It has become a basic necessity to follow new technologies in order to meet the expectations of consumers. The financial sector is no exception and must respond to this call by utilizing the Fundamental Principles of the Digital Economy. Banking has to be among the sectors that adopt and adapt to new technologies the fastest.
                            </p>


                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/Digital-Banking4.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />
                    </Div>

                </Div>

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="DIGITAL BANKING OVERVIEW "
                            subtitle=""
                        >

                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                In this context, the digitalization of banking can be achieved with a complex of high technology platforms. The final closed-loop ecosystem should be made:

                            </p>

                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                E Uniting government services, experts, customers, investment

                            </p>


                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                Remote work, education and consumption caused by current epidemiological conditions complying with their solutions,

                            </p>

                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                To comply with the principles and regulations of joint ventures in accordance with international law,

                            </p>

                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">

                                Intuitive with support for communities and education programs
                                to improve digital  literacy and provide a user-friendly user and
                                business interface.
                            </p>

                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/Digital-Banking3.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />
                    </Div>

                </Div>



                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="DIGITALIZATION TREND AND PROCESS IN BANKING"
                            subtitle=""
                        >
                            <ul>
                                <li> Modern architectural IT solutions for banks and evolution 	of legacy solutions,</li>
                                <li> Main trends in the field of bank payment protection</li>
                                <li> Data management,</li>
                                <li> Penetration of robotization technologies of artificial intelligence processes and elements,</li>
                                <li> Digitization of customer service channels,</li>
                                <li> Protection against cyber threats,</li>
                                <li> Open-source platforms, web-based solutions for bank internal processes,</li>
                                <li> Regulatory technologies, </li>
                                <li> New business models,</li>
                                <li> Development of outsourcing in banking</li>


                            </ul>


                            <Spacing lg="30" md="30" />
                            <h5> 2021 PROCESS</h5>

                            <ul>
                                <li> Optimization of remote work of bank employees,</li>
                                <li> Growth of online operations,</li>
                                <li> Simplifying access to bank services,</li>
                                <li> Development of distance sales channels,</li>
                                <li> Developing security systems against cyber attacks,</li>
                                <li> Broad consensus of artificial intelligence technologies,</li>
                                <li> Transition to data-based management,</li>
                                <li> Total customization programs,</li>
                                <li> Import substitution,</li>
                                <li> Ecosystem development,</li>
                                <li> Developing your own software using Union Business construction standards,</li>
                                <li> Increasing need for IT professionals.</li>

                            </ul>

                            <Spacing lg="30" md="30" />

                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/Digital-Banking2.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="30" md="20" />
                    </Div>

                </Div>


                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title=" REFLECTIONS "
                            subtitle=""
                        >

                            <ul>
                                <li>• Growth with outsourcing in banking, </li>
                                <li>• Providing services for the banking sector to integrate businesses directly into the ERP1 system, </li>
                                <li>• The target of 88% of banks to create common ecosystems and marketplaces, </li>
                                <li>• National Central Banks piloting open APIs with approximately 200 banks,</li>
                                <li>• Understanding that European banks' APIs are not ready to meet the requirements of PSD 22, </li>
                                <li>• Despite the fact that banks invested 1 trillion dollars in Information Technology for 3 years, there was no increase in their income.  </li>
                       
                            </ul>


                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/Digital-Banking1.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />
                    </Div>

                </Div>


                <Spacing lg="25" md="40" />


            </Div>
        </>
    )
}
