import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { pageTitle } from '../../helper'
import Accordion from '../Accordion'
import Button from '../Button'
import Cta from '../Cta'
import IconBox from '../IconBox'
import PageHeading from '../PageHeading'
import Div from '../Div'
import SectionHeading from '../SectionHeading'
import TestimonialSlider from '../Slider/TestimonialSlider'
import Spacing from '../Spacing'

export default function ProductService() {
    pageTitle('Hr Policy');
    const params = useParams();
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <PageHeading
                title={'Hr Policy'}
                bgSrc='/images/service_hero_bg.jpeg'
                pageLinkText={"Hr Policy"}
            />
            <Spacing lg='145' md='80' />
            <Div className="container">

            </Div>


            <Div className="container">
                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="SAKA FINANCIAL TECHNOLOGY"
                            subtitle=""
                        >
                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                We, as part of the SAKA HOLDING , which has investments in 13  countries and employs more than 450 people, we believe that our most important asset is our employees.
                            </p>
                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                As a passionate, entrepreneurial, initiative taking, respectful and supportive company, our core principle is to live our values of honesty, transparency and inclusiveness in every aspect of our lives.
                            </p>
                      

                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/hr-policy1.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />
                    </Div>

                </Div>

                <Spacing lg="25" md="40" />


                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="OUR COMMITMENTS"
                            subtitle=""
                        >
                                <Spacing lg="30" md="20" />
                           <h5> Based on these values:</h5>

                           <ul>
                            <li>We create employee awareness of occupational health and safety and environmental issues.</li>
                            <li>We create a management approach that is based on respect for people and is free from discrimination, and we apply ethical values.</li>
                            <li>We establish systems to ensure effective communication, and demonstrate a transparent and open management approach to employees.</li>
                            <li>We do not allow the employment of child and young workers, discrimination or forced labor.</li>
                            <li>Our employees are treated without any discrimination; we uphold their right to participate in trade union activities of their own choosing.</li>
                            <li>We attach importance to the professional and personal development of our employees, and create an environment in which they can develop their careers.</li>
                            <li>We aim to create a sustainable organizational structure with effective talent management and career succession plans.</li>
                            <li>We strive to satisfy our demand for skilled employees primarily from within our own ranks.</li>
                            <li>We provide our employees with appropriate systems and environments that will encourage continuous improvement with a sense of responsibility.</li>
                            <li>We encourage entrepreneurship and taking initiative through employee recognition and reward systems.</li>
                            <li>We monitor and appreciate the way in which our employees’ dynamism and high performance is reflected in business results.</li>
                            <li>Employee motivation is important to us, and we take actions that will ensure its sustainability.</li>
                            <li>We commit to managing our policy in an integrated manner with the other management systems we implement.</li>
                            <li>GROW. CREATE. INSPIRE. TOGETHER.</li>


                           </ul>

                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/hr-policy2.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />
                       
                    </Div>

                </Div>

            </Div>
        </>
    )
}
