import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { pageTitle } from '../../helper'
import Accordion from '../Accordion'
import Button from '../Button'
import Cta from '../Cta'
import IconBox from '../IconBox'
import PageHeading from '../PageHeading'
import Div from '../Div'
import SectionHeading from '../SectionHeading'
import TestimonialSlider from '../Slider/TestimonialSlider'
import Spacing from '../Spacing'

export default function ProductService() {
    pageTitle('LEGAL SERVICES');
    const params = useParams();
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <PageHeading
                title={'LEGAL SERVICES'}
                bgSrc='/images/service_hero_bg.jpeg'
                pageLinkText={"LEGAL SERVICES"}
            />
            <Spacing lg='145' md='80' />
            <Div className="container">

            </Div>

            <Div className="container">
                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="LOAK"
                            subtitle=""
                        >
                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                Anna Krimshtein, Esq. is an experienced attorney, with over twenty years of experience. With no geographical boundaries confining her practice, Anna works on trust and estates, corporate, securities and transactions around the globe.
                            </p>
                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                Anna brings extensive big firm experience, garnered as an associate in the Miami office of the world’s largest law firm, Baker and McKenzie, and the Miami office of the international law firm Kilpatrick Townsend.
                                Her areas of expertise include trust and estates, representing family offices, mergers and acquisitions, private placements, corporate finance, commercial real estate transaction and acting as a concierge counsel for family offices, and general corporate counsel.
                            </p>
                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                Anna has been leading counsel on what amounts to billions of dollars of transactions. She has navigated a labyrinth of complex legal and jurisdiction matters.

                            </p>

                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/legal-services1.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />
                    </Div>

                </Div>

                <Spacing lg="25" md="40" />


                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="MISSION  STATEMENT"
                            subtitle=""
                        >
                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                Our mission is to provide you with personalized, concierge level legal services.  We represent clients from across the globe.
                                When you retain our services, you will work directly with Anna Krimshtein, Esq. We specialize in transactional matters.
                                Reasons to Choose Concierge Legal Services
                                You work directly with a partner;
                                You receive concierge legal service at no additional fee;
                                You are always informed on the status of your transaction and/or services;
                            </p>

                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                You can contact us, as needed, even outside normal business hours;
                                We provide services in a timely and cost-effective fashion;
                                We will work closely with your CPAs on complicated issues;
                                Our experience covers a broad spectrum of industries, including securities, real estate, construction, restaurants, health care, and telecommunications.
                            </p>




                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/legal-services2.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />
                    </Div>

                </Div>

                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="SERVICES"
                            subtitle=""
                        >
                            <Spacing lg="30" md="20" />
                            <h5>TRUST & ESTATE LAW</h5>

                            <Spacing lg="30" md="20" />
                            <ul>
                                <li>We represent family offices in all aspects of operations, including, but not limited to:</li>
                                <li>Wealth planning and preservation</li>
                                <li>Wealth management</li>
                                <li>Tax planning, working closely with the CPA firms</li>
                                <li>Trusts and corporate services</li>
                                <li>Family governance</li>
                                <li>Charity and philanthropy</li>
                                <li>Estate Planning: living trusts, marital deduction trusts, irrevocable trusts, life insurance trusts, charitable trusts and foundations, and grantor retained income trusts</li>
                                <li>Succession planning: recapitalizations, buy/sell agreements, split interest acquisitions, partnerships, estate freezes, private annuities, self-cancelling installment notes and gifting</li>
                                <li>Asset protection matters: analysis of favorable jurisdictions, utilization of trusts, buy/sell agreements, limited liability companies, corporations and limited partnerships</li>

                            </ul>

                            <Spacing lg="30" md="30" />
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/legal-services3.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25" />
                    </Div>

                </Div>

                <Spacing lg="25" md="40" />

                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="CORPORATE BUSINESS LAW"
                            subtitle=""
                        >
                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                We counsel entrepreneurs and start-up, small and midsized companies in general business and corporate matters including mergers and acquisitions, joint ventures, licenses, strategic alliances, corporate finance, technology law and commercial transactions.

                            </p>
                            <Spacing lg="30" md="20" />
                            <p className="cs-m0">
                                We have been counseling small, mid-sized and large companies in various aspects of corporate and business law.  If you do not already have a legal department, then we can help with your company’s day-to-day legal needs on a cost-effective basis.  If you already have a legal department, we can help to alleviate the burden when the legal department becomes inundated with your company’s important matters.

                            </p>
                            <Spacing lg="30" md="20" />
                            <h5>Outside General Counsel Services</h5>

                            <Spacing lg="30" md="20" />
                            <p>We offer a turn-key solution for the equivalent function of in-house general counsel.  This can be helpful for companies that do not wish to employ a full-time legal department or are not prepared to expand their legal department.  We can offer these services remotely or in person at your office.</p>

                            <Spacing lg="30" md="20" />

                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/legal-services4.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="30" md="20" />
                    </Div>

                </Div>

                <Spacing lg="25" md="40" />


                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="SECURITIES LAW"
                            subtitle=""
                        >

                            <p className="cs-m0">
                                Our Firm provides advice and consultation with respect to all Federal and State securities laws, with special emphasis on the Securities Act of 1933, the Securities Exchange Act of 1934, and the Investment Company Act of 1940.
                                Venture Capital – negotiation and structuring of Venture Capital transactions with Investment Bankers, Institutions, Private Investors, and Offshore Capital Sources.
                                Private Placements – we provide advice on raising capital privately through limited private and intrastate offerings, as well as offshore offerings.

                            </p>


                            <Spacing lg="30" md="20" />


                            <p className="cs-m0">
                                Public Offerings – we assist companies in raising money publicly through Initial Public Offerings, as well as secondary offerings of Equity Debt and Unit Offerings. Our services are available in all industries and markets, for clients of all size.
                                Reverse Mergers and Public Shells – we can help you create a Public Company utilizing a reverse merger of private operating company into an existing public shell.
                                Broker Dealer Regulation – we are available to advise parties desiring to form securities Broker Dealers and Investment Advisers, assisting them in their organization and compliance with SEC,
                                State and NASD Rules and Regulations.
                            </p>


                            <Spacing lg="30" md="20" />


                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/legal-services5.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="30" md="20" />
                    </Div>

                </Div>

                <Spacing lg="25" md="40" />


                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="ESCROW AND SPECIALIZED AGENCY SERVICES"
                            subtitle=""
                        >

                            <p className="cs-m0">
                                We have been providing Escrow and Specialized Agency services for commercial transactions. We provide a wide breadth of Escrow and Specialized Agency services, including:

                            </p>


                            <Spacing lg="30" md="20" />

                            <ul>
                                <li>Collateral agent.</li>
                                <li>Commercial real estate transactions.</li>
                                <li>Escrow agent for M&A holdbacks, indemnification escrows, subscription offerings, 363 bankruptcy sales, intellectual property, and other bespoke escrows.</li>
                                <li>Paying and disbursing agent for merger consideration, reorganizations and liquidations.</li>
                                <li>Qualified custodian.</li>
                                <li>Exchange and tender agent</li>
                               

                            </ul>


                            <Spacing lg="30" md="20" />

                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/legal-services6.webp"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="30" md="20" />
                    </Div>

                </Div>


                <Spacing lg="25" md="40" />


            </Div>
        </>
    )
}
