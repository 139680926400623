import React, { useEffect } from 'react';
import { pageTitle } from '../../helper';
import Cta from '../Cta';
import FunFact from '../FunFact';
import PageHeading from '../PageHeading';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import TeamSlider from '../Slider/TeamSlider';
import Spacing from '../Spacing';

export default function AboutPage() {
  pageTitle('Financial Technology');

  const funfaceData = [
    {
      title: 'Global Happy Clients',
      factNumber: '40K',
    },
    {
      title: 'Project Completed',
      factNumber: '50K',
    },
    {
      title: 'Team Members',
      factNumber: '245',
    },
    {
      title: 'Digital products',
      factNumber: '550',
    },
  ];
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {/* Start Page Heading Section */}
      <PageHeading
        title="FINANCIAL TECHNOLOGY"
        bgSrc="images/about_hero_bg.jpeg"
        pageLinkText="Financial Technology"
      />
      {/* End Page Heading Section */}

      {/* Start About Section */}
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-xl-5 col-lg-7">
            <SectionHeading
              title="What is Financial Technology (FinTech)? "
              subtitle=""
            >
              <Spacing lg="30" md="20" />

              <Spacing lg="30" md="20" />
              <h5>
              </h5>

              <p className="cs-m0">
                FinTech (financial technology) is a catch-all term referring to software, mobile applications, and other technologies created to improve and automate traditional forms of finance for businesses and consumers alike. FinTech can include everything from straightforward mobile payment apps to complex blockchain networks housing encrypted transactions.
              </p>

              <Spacing lg="30" md="20" />
              <p className="cs-m0">
                In this guide, we’ll discuss the various types of fintech, the skills needed to work in the field, and the job outlook for several fintech careers. We’ll also take a closer look at a few effective ways to learn key industry skills, such as an immersive online fintech bootcamp — a great way for aspiring fintech professionals to get hands-on experience.

              </p>

              <Spacing lg="30" md="20" />
              <h5>
                Simple Definition of FinTech
              </h5>

              <Spacing lg="30" md="30" />
              <p className="cs-m0">
                The term “fintech company” describes any business that uses technology to modify, enhance, or automate financial services for businesses or consumers. Some examples include mobile banking, peer-to-peer payment services (e.g., Venmo, CashApp), automated portfolio managers (e.g., Wealthfront, Betterment), or trading platforms such as Robinhood. It can also apply to the development and trading of cryptocurrencies (e.g., Bitcoin, Dogecoin, Ether).

              </p>
              <Spacing lg="30" md="30" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="40" />
            </SectionHeading>
          </Div>
          <Div className="col-lg-5 offset-xl-2">
            <img
              src="/images/financial1.webp"
              alt="About"
              className="w-100 cs-radius_15"
            />
            <Spacing lg="25" md="25" />
          </Div>

        </Div>
      </Div>
      <Spacing lg="75" md="55" />

      <Spacing lg="100" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-xl-5 col-lg-6">
            <Div className="cs-image_layer cs-style1">
              <Div className="cs-image_layer_in">
                <img
                  src="/images/financial2.webp"
                  alt="About"
                  className="w-100 cs-radius_15"
                />
              </Div>
            </Div>
            <Spacing lg="0" md="40" />
          </Div>
          <Div className="col-xl-5 offset-xl-1 col-lg-6">
            <SectionHeading
              title=" How Does FinTech Work?"
              subtitle="FinTech "
            >
              <Spacing lg="30" md="20" />
              <p className="cs-m0">
                While fintech is a multifaceted concept, it’s possible to gain a strong understanding. FinTech simplifies financial transactions for consumers or businesses, making them more accessible and generally more affordable. It can also apply to companies and services utilizing AI, big data, and encrypted blockchain technology to facilitate highly secure transactions amongst an internal network.
              </p>


              <Spacing lg="30" md="20" />
              <p className="cs-m0">
                Broadly speaking, fintech strives to streamline the transaction process, eliminating potentially unnecessary steps for all involved parties. For example, a mobile service like Venmo or CashApp allows you to pay other people at any time of day, sending funds directly to their desired bank account. However, if you paid instead with cash or a check, the recipient would have to make a trip to the bank to deposit the money.
              </p>

              <Spacing lg="30" md="20" />
              <h5>
                The Technologies That Power FinTech
              </h5>


              <Spacing lg="30" md="20" />
              <p className="cs-m0">
                Modern fintech is primarily driven by AI, big data, and blockchain technology — all of which have completely redefined how companies transfer, store, and protect digital currency. Specifically, AI can provide valuable insights on consumer behavior and spending habits for businesses, allowing them to better understand their customers. Big data analytics can help companies predict changes in the market and create new, data-driven business strategies
              </p>



              <Spacing lg="30" md="30" />
              <Spacing lg="25" md="0" />
            </SectionHeading>
          </Div>
        </Div>
      </Div>

      <Spacing lg="100" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-xl-5 col-lg-6">
            <Div className="cs-image_layer cs-style1">
              <Div className="cs-image_layer_in">
                <img
                  src="/images/financial3.webp"
                  alt="About"
                  className="w-100 cs-radius_15"
                />
              </Div>
            </Div>
            <Spacing lg="0" md="40" />
          </Div>
          <Div className="col-xl-5 offset-xl-1 col-lg-6">
            <SectionHeading
              title=" FinTech TRENDS"
              subtitle="FinTech "
            >
              <Spacing lg="30" md="20" />
              <p className="cs-m0">
                Over the years, fintech has grown and changed in response to developments within the wider technology sector. In 2022, this growth was defined by several prevailing trends:
              </p>


              <Spacing lg="30" md="20" />
              <p className="cs-m0">
              Digital banking continues to grow: Digital banking is easier to access than ever before. Many consumers already manage their money, request and pay loans, and purchase insurance through digital-first banks. This simplicity and convenience will likely drive additional growth in this sector, with the global digital banking platform market expected to grow at a compound annual growth rate (CAGR) of 11.5 percent by 2026.

              </p>

              <Spacing lg="30" md="20" />
              <h5>
                The Technologies That Power FinTech
              </h5>


              <Spacing lg="30" md="20" />
              <p className="cs-m0">
              Blockchain: Blockchain technology allows for decentralized transactions without a government entity or other third-party organization being involved. Blockchain technology and applications have been growing quickly for years, and this trend is likely to continue as more industries turn to advanced data encryption. Check out our guide to blockchain technology if you’re interested in learning more.

              </p>

              <Spacing lg="30" md="20" />
              <p className="cs-m0">
              Artificial Intelligence (AI) and Machine Learning (ML): AI and ML technologies have changed how fintech companies scale, redefining the services they offer to clients. AI and ML can reduce operational costs, increase the value provided to clients, and detect fraud. As these technologies become more affordable and accessible, expect them to play an increasingly large role in fintech’s continued evolution — especially as more brick-and-mortar banks go digital.
              </p>

              



              <Spacing lg="30" md="30" />
              <Spacing lg="25" md="0" />
            </SectionHeading>
          </Div>
        </Div>
      </Div>

      <Spacing lg="100" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-xl-5 col-lg-6">
            <Div className="cs-image_layer cs-style1">
              <Div className="cs-image_layer_in">
                <img
                  src="/images/financial4.webp"
                  alt="About"
                  className="w-100 cs-radius_15"
                />
              </Div>
            </Div>
            <Spacing lg="0" md="40" />
          </Div>
          <Div className="col-xl-5 offset-xl-1 col-lg-6">
            <SectionHeading
              title="HOW SAFE IS FinTech?"
              subtitle="FinTech "
            >
              <Spacing lg="30" md="20" />
              <p className="cs-m0">
              FinTech companies are generally trusted by consumers — according to Forbes, 68% of people are willing to use financial tools developed by non-traditional (e.g., non-financial,  non-banking) institutions. However, many fintech applications are relatively new, and they’re currently not subject to the same safety regulations as banks. This doesn’t mean that consumers shouldn’t trust fintech companies with their money — it just means that being careful can be beneficial. For most consumers, the benefits of working with a fintech company outweigh the perceived risks.
              </p>

              <Spacing lg="30" md="20" />
              <h5>
              DIFFERENT TYPES of FinTech
              </h5>

              <Spacing lg="30" md="20" />
              <p className="cs-m0">
              FinTech has been used to revolutionize financial institutions for millions of people across the globe, changing how we pay each other, buy stocks and other financial instruments, and access financial advice. There are many different fintech companies offering unique services for their clients. 

              </p>


              <Spacing lg="30" md="30" />
              <Spacing lg="25" md="0" />
            </SectionHeading>
          </Div>
        </Div>
      </Div>
      {/* End Why Choose Section */}

      {/* Start Team Section */}
      <Spacing lg="145" md="80" />

      {/* End Team Section */}

      {/* Start CTA Section */}
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Cta
          title="Let’s disscuse make <br />something <i>cool</i> together"
          btnText="Contact Now"
          btnLink="/contact"
          bgSrc="/images/cta_bg.jpeg"
        />
      </Div>
      {/* End CTA Section */}
    </>
  );
}
