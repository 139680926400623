import { Route, Routes } from 'react-router-dom';
import FinancialPage from './components/Pages/Financial-Technology';
import BlogPage from './components/Pages/BlogPage';
import BlogDetailsPage from './components/Pages/BlogDetailsPage';
import ContactPage from './components/Pages/ContactPage';
import ErrorPage from './components/Pages/ErrorPage';
import Home from './components/Pages/Home';
import PortfolioDetailsPage from './components/Pages/PortfolioDetailsPage';
import ServiceDetailsPage from './components/Pages/ServiceDetailsPage';
import ServicesPage from './components/Pages/ServicesPage';
import TeamPage from './components/Pages/TeamPage';
import PortfolioPage from './components/Pages/PortfolioPage';
import TeamDetails from './components/Pages/TeamDetails';
import PhotographyAgencyHome from './components/Pages/PhotographyAgencyHome';
import CreativePortfolioHome from './components/Pages/CreativePortfolioHome';
import DigitalAgencyHome from './components/Pages/DigitalAgencyHome';
import MarketingAgencyHome from './components/Pages/MarketingAgencyHome';
import ShowcasePortfolioHome from './components/Pages/ShowcasePortfolioHome';
import CaseStudyShowcaseHome from './components/Pages/CaseStudyShowcaseHome';
import Layout from './components/Layout';
import CaseStudyDetailsPage from './components/Pages/CaseStudyDetailsPage';
import FaqPage from './components/Pages/FaqPage';
import ProductService from './components/Pages/services/Fintech-Products';
import DigitalBankingEcosystem from './components/Pages/DigitalBankingEcosystem';
import HrPolicyPage from './components/Pages/hr-policy';
import FintechPolicy from './components/Pages/fıntech-policy-tool-kit';
import BankingFinance from './components/Pages/banking-finance';
import RenoFinancePage from './components/Pages/renofinance';
import CorporationsInstitutions from './components/Pages/corporations-institution';
import TrusfincoPage from './components/Pages/trusfinco';
import LegalServicePage from './components/Pages/legal-services';
import Gandg from './components/Pages/gandg';
import SolutionTrading from './components/Pages/solution-trading';
import Empd from './components/Pages/empd';
import Axex from './components/Pages/axex';
import DigitalCountryProgramPage from './components/Pages/DigitalCountryProgram';

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route
            path="photography-agency"
            element={<PhotographyAgencyHome />}
          />
          <Route path="digital-agency" element={<DigitalAgencyHome />} />
          <Route path="marketing-agency" element={<MarketingAgencyHome />} />
          <Route path="financial-technology" element={<FinancialPage />} />
          <Route path="service" element={<ServicesPage />} />
          <Route path="hr-policy" element={<HrPolicyPage />} />
          <Route path="fıntech-policy-tool-kit" element={<FintechPolicy />} />
          <Route path="Digital-Banking-Ecosystem" element={<DigitalBankingEcosystem />} />
          <Route path="product" element={<ProductService />} />
          <Route path="banking-finance" element={<BankingFinance />} />
          <Route path="renofinance" element={<RenoFinancePage />} />
          <Route path="corporations-institutions" element={<CorporationsInstitutions />} />
          <Route path="trusfinco" element={<TrusfincoPage />} />
          <Route path="legal-services" element={<LegalServicePage />} />
          <Route path="gandg" element={<Gandg />} />
          <Route path="axex" element={<Axex />} />
          <Route path="solution-trading" element={<SolutionTrading />} />
          <Route path="empd" element={<Empd />} />
          <Route path="digital-country-program" element={<DigitalCountryProgramPage />} />

         

          <Route
            path="service/:serviceDetailsId"
            element={<ServiceDetailsPage />}
          />
          <Route path="portfolio" element={<PortfolioPage />} />
          <Route
            path="portfolio/:portfolioDetailsId"
            element={<PortfolioDetailsPage />}
          />
          <Route path="blog" element={<BlogPage />} />
          <Route path="blog/:blogDetailsId" element={<BlogDetailsPage />} />
          <Route path="contact" element={<ContactPage />} />
          <Route path="team" element={<TeamPage />} />
          <Route path="team/:teamDetails" element={<TeamDetails />} />
          <Route
            path="/case-study/:caseStudyDetailsId"
            element={<CaseStudyDetailsPage />}
          />
          <Route path="faq" element={<FaqPage />} />
        </Route>
        <Route
          path="/"
          element={<Layout headerVariant="cs-site_header_full_width" />}
        >
          <Route
            path="creative-portfolio"
            element={<CreativePortfolioHome />}
          />
          <Route
            path="showcase-portfolio"
            element={<ShowcasePortfolioHome />}
          />
          <Route
            path="case-study-showcase"
            element={<CaseStudyShowcaseHome />}
          />
        </Route>
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </>
  );
}

export default App;
